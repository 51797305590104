import Type from './Notification.type';

export const addProjectNotification = ({
  notification,
}: {
  notification: any;
}) => {
  return {
    type: Type.ADD_PROJECT_NOTIFICATION,
    payload: { projectNotification: notification },
  };
};

export const addMemberNotification = ({
  notification,
}: {
  notification: any;
}) => {
  return {
    type: Type.ADD_MEMBER_NOTIFICATION,
    payload: { memberNotification: notification },
  };
};
