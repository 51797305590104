import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MeQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQueryQuery = { __typename?: 'Query', getMe?: { __typename?: 'Member', id: string, phoneNumber: string, givenName: string, familyName: string, email: string, tenantOwnerships?: Array<{ __typename?: 'Tenant', id: string, awsAccountId: string, name: string, status: string, imageUrl?: string | null } | null> | null, tenantMemberRoles?: Array<{ __typename?: 'TenantMemberRole', assignedAt: string, tenant?: { __typename?: 'Tenant', id: string, awsAccountId: string, name: string, status: string, imageUrl?: string | null } | null, role: { __typename?: 'Role', id: string, name: string, seniority: number } } | null> | null, projectMemberRoles?: Array<{ __typename?: 'ProjectMemberRole', assignedAt: string, project?: { __typename?: 'Project', name: string, id: string } | null, member: { __typename?: 'Member', id: string, email: string }, role: { __typename?: 'Role', id: string, name: string, seniority: number } } | null> | null } | null };


export const MeQueryDocument = gql`
    query MeQuery {
  getMe {
    id
    phoneNumber
    givenName
    familyName
    email
    tenantOwnerships {
      id
      awsAccountId
      name
      status
      imageUrl
    }
    tenantMemberRoles {
      assignedAt
      tenant {
        id
        awsAccountId
        name
        status
        imageUrl
      }
      role {
        id
        name
        seniority
      }
    }
    projectMemberRoles {
      assignedAt
      project {
        name
        id
      }
      member {
        id
        email
      }
      role {
        id
        name
        seniority
      }
    }
  }
}
    `;

export function useMeQueryQuery(options?: Omit<Urql.UseQueryArgs<MeQueryQueryVariables>, 'query'>) {
  return Urql.useQuery<MeQueryQuery, MeQueryQueryVariables>({ query: MeQueryDocument, ...options });
};