import { useState } from 'react';
import { Typography, Tooltip } from '@mui/material';
import PowerIcon from '@mui/icons-material/PowerRounded';
import { useRedux } from 'Hook';
import { States, HourClickHandler } from './types';
import {
  HourUI,
  ScheduleXAxis,
  DayUI,
  MassSelectionControl,
  MassSelectionButton,
  IconButton,
} from './styles';

export const hourUI = ({
  state,
  dayName,
  hourIndex,
  eventsEnabled,
  type,
  handleHourClick,
  dayIndex,
  hourHighlight = -1,
}: {
  state: number;
  dayName: string;
  hourIndex: number;
  type: string;
  eventsEnabled: boolean;
  handleHourClick: HourClickHandler;
  dayIndex: number;
  hourHighlight?: number;
}) => {
  const stateMap = {
    '-1': { classModifier: '--force-off', label: 'Overide off' },
    '0': { classModifier: '--off', label: 'Project schedule' },
    '1': { classModifier: '--on', label: 'Overide On' },
  };

  const { classModifier, label } = stateMap[String(state)];

  const scheduleModifier = `--${type}`;

  const now = new Date();

  const today = now.getDay() - 1;

  const dayHighlight = today === -1 ? 6 : today;

  const highlightModifier =
    hourIndex === hourHighlight && dayIndex === dayHighlight
      ? '--highlight'
      : '';

  return type.toLowerCase() === 'project' ? (
    <HourUI
      key={`${dayName}-${hourIndex}`}
      className={`schedule__hour ${classModifier} ${scheduleModifier} ${highlightModifier}`}
      onClick={() =>
        eventsEnabled && handleHourClick({ dayName, hourIndex, state })
      }
    />
  ) : (
    <Tooltip title={label} placement="top" key={`${dayName}-${hourIndex}`}>
      <HourUI
        className={`schedule__hour ${classModifier} ${scheduleModifier}`}
        onClick={() =>
          eventsEnabled && handleHourClick({ dayName, hourIndex, state })
        }
      />
    </Tooltip>
  );
};

export const dayUI = ({
  dayName,
  states,
  type,
  eventsEnabled,
  handleHourClick,
  dayIndex,
  hourHighlight,
}: {
  dayName: string;
  states: Array<number>;
  type: 'project' | 'resource';
  eventsEnabled: boolean;
  handleHourClick: HourClickHandler;
  dayIndex: number;
  hourHighlight?: number;
}) => {
  return (
    states && (
      <ScheduleXAxis key={dayName}>
        <Typography className={`--${type}`}>{dayName.slice(0, 3)}</Typography>
        <DayUI className={`schedule__day --${dayName}`}>
          {states.map((state, hourIndex) =>
            hourUI({
              state,
              dayName,
              hourIndex,
              type,
              eventsEnabled,
              handleHourClick,
              dayIndex,
              hourHighlight,
            })
          )}
        </DayUI>
      </ScheduleXAxis>
    )
  );
};

export const createSchedule = (state: number) => {
  return {
    monday: new Array(24).fill(state),
    tuesday: new Array(24).fill(state),
    wednesday: new Array(24).fill(state),
    thursday: new Array(24).fill(state),
    friday: new Array(24).fill(state),
    saturday: new Array(24).fill(state),
    sunday: new Array(24).fill(state),
  };
};

export const createMatrix = ({
  states,
  type,
  handleClick,
  eventsEnabled = true,
  hourHighlight,
}: {
  states: States;
  type: 'project' | 'resource';
  handleClick: HourClickHandler;
  eventsEnabled: boolean;
  hourHighlight?: number;
}) => {
  return Object.entries(states).map((ps, dayIndex) => {
    const [dayName, statesArray] = ps;
    return dayUI({
      type,
      dayName,
      states: statesArray,
      handleHourClick: handleClick,
      eventsEnabled,
      dayIndex,
      hourHighlight,
    });
  });
};

export const MassSelectionButtonActions = ({ setAlwaysOn, setAlwaysOff }) => {
  const [alwaysOnState, setAlwaysOnState] = useState(true);

  return (
    <MassSelectionControl>
      <MassSelectionButton onClick={() => setAlwaysOn()}>
        <IconButton className="--on">
          <PowerIcon />
        </IconButton>
        <Typography>Always On</Typography>
      </MassSelectionButton>
      <MassSelectionButton onClick={() => setAlwaysOff()}>
        <IconButton className="--off">
          <PowerIcon />
        </IconButton>
        <Typography>Always Off</Typography>
      </MassSelectionButton>
    </MassSelectionControl>
  );
};
