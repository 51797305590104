import { useRedux } from 'Hook';
import { useHistory } from 'react-router-dom';
import { PATH as TENANT_PATH } from 'Views/Tenant';
import TenantsList from 'components/Tenant/TenantsList';
import { IMAGE_PATHS } from '../../../constants';
import { Container, Content, Logo } from './Dashboard.styles';

function WorkspaceDashboard() {
  const history = useHistory();

  const {
    actions: {
      tenantActions: { selectTenant },
    },
    dispatch,
  } = useRedux();

  const handleTenantSwitch = (tenantId: string) => {
    dispatch(selectTenant({ tenantId }));
    history.push(TENANT_PATH.DASHBOARD);
  };

  return (
    <Container>
      <Content>
        <Logo src={IMAGE_PATHS.LOGO_SVG} alt="logo" />
        <h1>Sign in to your workspace</h1>
        <TenantsList handleTenantSwitch={handleTenantSwitch} />
      </Content>
    </Container>
  );
}

export default WorkspaceDashboard;
