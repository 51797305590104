// @ts-nocheck

import {
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { checkCookie } from 'utils/credentials';
import { API_BASE_URL } from '../constants';
import schema from './introspection.json';
import cacheConfig from './cache.config';

const isDevelopment = process.env.NODE_ENV === 'development';

const exchanges = () => {
  const allExchanges = [
    errorExchange({
      onError: (error) => {
        console.error('graphql', error);

        return error;
      },
    }),
    dedupExchange,
    cacheExchange({
      // @ts-ignore
      schema,
      ...cacheConfig,
    }),
    authExchange({
      addAuthToOperation: ({ authState, operation }) => {
        const cookie = checkCookie();

        // @ts-ignore
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              // @ts-ignore
              // Authorization: authState.token,
              Authorization: cookie,
            },
          },
        });
      },
      willAuthError: ({ authState }) => {
        if (!authState) return true;
        // e.g. check for expiration, existence of auth etc
        return false;
      },
      didAuthError: ({ error }) => {
        // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
        return error.graphQLErrors.some(
          (e) =>
            e.message.includes('UnauthorizedException') ||
            e.message.includes('Token has expired')
        );
      },
      getAuth: async ({ authState }) => {
        if (!authState) {
          const token = checkCookie();

          if (token) {
            return Promise.resolve({ token });
          }

          return null;
        }

        return null;
      },
    }),
    fetchExchange,
  ];

  const devExchanges = [devtoolsExchange, ...allExchanges];

  return isDevelopment ? devExchanges : allExchanges;
};

export const client = createClient({
  exchanges: exchanges(),
  requestPolicy: `cache-and-network`,
  // @ts-ignore
  url: API_BASE_URL,
});
