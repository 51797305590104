import ResourceMiddleware from 'Controller/Resource/Resource.middleware';
import DeploymentMiddleware from 'Controller/Deployment/Deployment.middleware';
import ProjectMiddleware from 'Controller/Project/Project.middleware';
import MemberMiddleware from 'Controller/Member/Member.middleware';
import TenantMiddleware from 'Controller/Tenant/Tenant.middleware';
import NotificationMiddleware from 'Controller/Notification/Notification.middleware';
import ProductMiddleware from 'Controller/Product/Product.middleware';
import ProvisionMiddleware from 'Controller/Provision/Provision.middleware';
import OnboardingMiddleware from 'Controller/Onboarding/Onboarding.middleware';
import { client as apiClient } from 'index';
import { ApolloClient } from '@apollo/client';
import { iState } from 'Model';

export type iApiClient = ApolloClient<any>;

export type iReduxStore = {
  dispatch: (any) => any;
  getState: () => iState;
};

export type ReduxMiddlewareFunction = (
  store: iReduxStore,
  next: any,
  action: any,
  apiClient: iApiClient
) => any;

const middlewares = {
  ...ResourceMiddleware,
  ...DeploymentMiddleware,
  ...ProjectMiddleware,
  ...MemberMiddleware,
  ...TenantMiddleware,
  ...NotificationMiddleware,
  ...ProductMiddleware,
  ...ProvisionMiddleware,
  ...OnboardingMiddleware,
};

export default (store: any) => (next: any) => (action: any) => {
  try {
    if (action?.type) {
      const middleware = middlewares[action.type];
      if (middleware) {
        middleware(store, next, action, apiClient);
      }
    }
  } catch (middlewareError) {
    console.error(JSON.stringify(middlewareError));
  }
};
