import { useState } from 'react';
import toast from 'react-hot-toast';
import RoleSelect from 'components/Selectors/RoleSelect';
import { useEditMembersRolesMutation } from 'graphql/editMemberRoles.generated';
import {
  MemberRoleInputEffect,
  Role,
  TargetType,
} from 'graphql/types.generated';

const RoleChanger = ({
  role,
  targetType,
  targetId,
  disabled = false,
  memberId,
}: {
  role: Role;
  targetType: TargetType;
  targetId: string;
  disabled?: boolean;
  memberId: string;
}) => {
  const [, editMemberRoles] = useEditMembersRolesMutation();

  const [selectedRole, setSelectedRole] = useState<string>(role.id);

  const onChangeRole = async (e: any) => {
    try {
      const { value } = e.target;

      if (!targetType || !targetId) return;

      setSelectedRole(value);

      toast.loading('Updating role', {
        duration: 4000,
        position: 'top-center',
      });

      const memberRoles = [
        {
          roleId: value,
          effect: MemberRoleInputEffect.Update,
          memberId,
        },
      ];

      const { error } = await editMemberRoles({
        editMemberRoles: {
          targetType,
          targetId,
          memberRoles,
        },
      });

      if (error) {
        toast.error('Could not change role. Please try again.', {
          duration: 4000,
          position: 'top-center',
        });
        return;
      }

      toast.success('Role updated', {
        duration: 4000,
        position: 'top-center',
      });
      return;
    } catch (err: any) {
      toast.error(err.message, {
        duration: 4000,
        position: 'top-center',
      });
    }
  };

  return (
    <RoleSelect
      onChange={onChangeRole}
      value={selectedRole}
      disabled={disabled}
      inline
    />
  );
};

export default RoleChanger;
