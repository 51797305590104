import { useState } from 'react';
import { useSelector } from 'react-redux';
import { iState } from 'Model';
import ProjectTerradiciTable from 'components/Project/ProjectDeploymentsTables/TerradiciTable';
import Grid from '@material-ui/core/Grid';
import { ProductCategory } from 'graphql/types.generated';
import { Container, DeploymentsContainer } from './Infrastructure.styled';

function Infrastructure() {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Infrastructure</h2>
      </Grid>
      <DeploymentsContainer>
        <ProjectTerradiciTable />
      </DeploymentsContainer>
    </Container>
  );
}

export default Infrastructure;
