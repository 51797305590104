// Makes use of Role.seniority
export const ROLE_SENIORITIES = {
  TYRELL: 100,
  ADMINS: 80,
  OPS: 60,
  ALL_USERS: 40,
};

type Permissions = {
  tenant: {
    members: {
      invite: number;
      update: number;
      remove: number;
    };
    project: {
      create: number;
    };
  };
  project: {
    members: {
      invite: number;
      update: number;
      remove: number;
    };
    settings: {
      update: number;
    };
    resources: {
      add: number;
      stop: number;
      start: number;
      schedule: number;
      remove: number;
    };
    schedule: {
      create: number;
      update: number;
      remove: number;
    };
  };
};

const permissions: Permissions = {
  tenant: {
    members: {
      invite: ROLE_SENIORITIES.ADMINS,
      update: ROLE_SENIORITIES.OPS,
      remove: ROLE_SENIORITIES.ADMINS,
    },
    project: {
      create: ROLE_SENIORITIES.ADMINS,
    },
  },
  project: {
    members: {
      invite: ROLE_SENIORITIES.ADMINS,
      update: ROLE_SENIORITIES.OPS,
      remove: ROLE_SENIORITIES.ADMINS,
    },
    settings: {
      update: ROLE_SENIORITIES.OPS,
    },
    resources: {
      add: ROLE_SENIORITIES.OPS,
      stop: ROLE_SENIORITIES.OPS,
      start: ROLE_SENIORITIES.OPS,
      schedule: ROLE_SENIORITIES.OPS,
      remove: ROLE_SENIORITIES.ADMINS,
    },
    schedule: {
      create: ROLE_SENIORITIES.OPS,
      update: ROLE_SENIORITIES.OPS,
      remove: ROLE_SENIORITIES.ADMINS,
    },
  },
};

export default permissions;
