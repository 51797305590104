import { useState } from 'react';
import * as yup from 'yup';
import isEmpty from 'lodash.isempty';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Box from '@material-ui/core/Box';

import {
  Form,
  FormFields,
  TextField,
  PhoneField,
} from 'components/Common/Forms';

import { useUpdateMemberMutation } from 'graphql/updateMember.generated';

import { Member } from 'graphql/types.generated';

/**
 * Form Validation Schema
 */
type FormInputs = {
  givenName: string;
  familyName: string;
  email: string;
  phoneNumber: string;
};

const schema = yup.object().shape({
  givenName: yup.string().required('You must enter a first name'),
  familyName: yup.string().required('You must enter a last name'),
  email: yup.string().email(),
  phoneNumber: yup.string().required('Please enter a phone number.'),
});

type Props = {
  user: Member;
};

const UpdateAccount = ({ user }: Props) => {
  const [, updateMember] = useUpdateMemberMutation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const defaultValues = {
    givenName: user?.givenName || '',
    familyName: user?.familyName || '',
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
  };

  const { control, formState, handleSubmit, reset } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (values: FormInputs) => {
    reset(defaultValues);

    try {
      setLoading(true);

      if (!user) return;

      const { error: mutationError } = await updateMember({
        member: {
          id: user.id,
          givenName: values.givenName,
          familyName: values.familyName,
          email: values.email,
          phoneNumber: values?.phoneNumber,
        },
      });

      if (mutationError) {
        setLoading(false);
        setError(mutationError.message);
        return;
      }

      reset(defaultValues);
      setLoading(false);
    } catch (e) {
      // @ts-ignore
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <Form name="updateAccountForm" noValidate onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div>
          {error && (
            <span>
              There was a problem updating your account. Please try again.
            </span>
          )}
        </div>
        <FormFields>
          <Box pt={2} mb={1}>
            <TextField
              name="givenName"
              control={control}
              label="First Name"
              type="text"
              error={!!errors.givenName}
              errorMessage={errors?.givenName?.message}
              required
              fullWidth
            />
          </Box>
          <Box pt={2} mb={1}>
            <TextField
              name="familyName"
              control={control}
              label="Last Name"
              type="text"
              error={!!errors.familyName}
              errorMessage={errors?.familyName?.message}
              required
              fullWidth
            />
          </Box>
          <Box pt={2} mb={1}>
            <TextField
              name="email"
              control={control}
              label="Email"
              type="email"
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              required
              fullWidth
              disabled
            />
          </Box>
          <Box pt={2} mb={1}>
            <PhoneField
              name="phoneNumber"
              label="Phone"
              control={control}
              error={!!errors.phoneNumber}
              errorMessage={errors?.phoneNumber?.message}
              fullWidth
            />
          </Box>
        </FormFields>
      </div>
      <Button
        variant="outlined"
        color="primary"
        aria-label="UPDATE ACCOUNT"
        disabled={isEmpty(dirtyFields) || !isValid}
        type="submit"
        disableElevation
      >
        {loading ? 'Saving' : 'Save Changes'}
      </Button>
    </Form>
  );
};

export default UpdateAccount;
