import Type from 'Controller/Provision/Provision.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { createNew, teardown } from 'Controller/Provision/GraphQL';
import { provisionActions, projectActions } from 'Controller/actions';

type ProjectMiddleware = Record<Type, ReduxMiddlewareFunction>;

const provisionMiddleware: ProjectMiddleware = {
  [Type.CREATE_NEW]: async (store, next, action, apiClient) => {
    const { dispatch } = store;

    const { deploymentName, productId, projectId } = action.payload;

    const response = await apiClient.mutate({
      mutation: createNew,
      variables: {
        deploymentName,
        productId,
        projectId,
      },
    });

    dispatch(
      provisionActions.createNew__success({
        deploymentId:
          response.data.createDeploymentViaStateMachine.deploymentId,
        deploymentName,
      })
    );

    next(action);
  },

  [Type.TEAR_DOWN]: async (store, next, action, apiClient) => {
    const { deploymentId } = action.payload;

    const response = await apiClient.mutate({
      mutation: teardown,
      variables: {
        deploymentId,
      },
    });

    action.payload = {
      deploymentId:
        response.data.teardownDeploymentViaStateMachine.deploymentId,
    };

    next(action);
  },

  [Type.HANDLE_SUCCESS]: (store, next, action) => {
    const { dispatch } = store;
    dispatch(projectActions.refetchCurrentProject());
    next(action);
  },

  [Type.HANDLE_FAILURE]: (store, next, action) => {
    next(action);
  },

  [Type.CREATE_NEW__SUCCESS]: (store, next, action) => {
    next(action);
  },
};

export default provisionMiddleware;
