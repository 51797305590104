import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetTenantMembersQueryVariables = Types.Exact<{
  tenantId: Types.Scalars['String'];
}>;


export type GetTenantMembersQuery = { __typename?: 'Query', getTenant?: { __typename?: 'Tenant', id: string, memberRoles?: Array<{ __typename?: 'TenantMemberRole', assignedAt: string, role: { __typename?: 'Role', id: string, name: string, seniority: number }, member: { __typename?: 'Member', email: string, familyName: string, givenName: string, id: string } } | null> | null } | null };


export const GetTenantMembersDocument = gql`
    query GetTenantMembers($tenantId: String!) {
  getTenant(tenantId: $tenantId) {
    id
    memberRoles {
      assignedAt
      role {
        id
        name
        seniority
      }
      member {
        email
        familyName
        givenName
        id
      }
    }
  }
}
    `;

export function useGetTenantMembersQuery(options: Omit<Urql.UseQueryArgs<GetTenantMembersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantMembersQuery, GetTenantMembersQueryVariables>({ query: GetTenantMembersDocument, ...options });
};