import { ProjectNotification } from 'graphql/types.generated';
import Type, {
  ProjectNotificationSourceType,
} from 'Controller/Notification/Notification.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { toast } from 'react-hot-toast';
import { deploymentActions, provisionActions } from 'Controller/actions';

type Middleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: Middleware = {
  [Type.ADD_MEMBER_NOTIFICATION]: (store: any, next: any, action: any) => {
    toast.success('notify member', {
      duration: 4000,
      position: 'top-center',
    });

    next(action);
  },

  [Type.ADD_PROJECT_NOTIFICATION]: (store: any, next: any, action: any) => {
    try {
      const { dispatch } = store;

      const notification = action.payload
        .projectNotification as ProjectNotification;

      const meta = JSON.parse(notification?.json) || {};

      if (
        notification.source ===
        ProjectNotificationSourceType.UPDATE_RESOURCE_STATE
      ) {
        dispatch(
          deploymentActions.updateResourceState({
            resourceId: meta.resourceId,
            state: meta.state,
          })
        );
      }

      if (
        notification.source === ProjectNotificationSourceType.DEPLOYMENT_FAILED
      ) {
        toast.error('Deployment failed', {
          duration: 4000,
          position: 'top-center',
        });
      }

      if (notification.source === 'deploymentReceived') {
        dispatch(
          deploymentActions.removePendingDeployment({
            clientRequestId: meta.clientRequestId,
          })
        );
      }

      /**
       *
       * Provision notifications
       */
      if (notification.source === 'createDeploymentSuccess') {
        dispatch(
          provisionActions.handleProvisionSuccess({
            deploymentId: meta.deploymentInDb.id,
            message: notification.message,
            deployment: meta.deploymentInDb,
          })
        );
      }

      if (notification.source === 'teardownDeploymentSuccess') {
        dispatch(
          provisionActions.handleProvisionSuccess({
            deploymentId: meta.deploymentRecordRemoved[0].id,
            message: notification.message,
            deployment: meta.deploymentRecordRemoved[0],
          })
        );
      }

      if (
        notification.source === 'createDeploymentFailure' ||
        notification.source === 'teardownDeploymentFailure'
      ) {
        dispatch(
          provisionActions.handleProvisionFailure({
            deploymentId: meta.deploymentId,
            message: meta.error.message,
          })
        );
      }

      toast.success(notification.message, {
        duration: 4000,
        position: 'top-center',
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },
};

export default middleware;
