import Button from '@mui/material/Button';
import Image from 'components/Common/Image';
import { useRedux } from 'Hook';
import Loading from 'components/Common/Loading';
import {
  Container,
  WorkspaceContainer,
  WorkspaceContent,
  Workspace,
  WorkspaceDetails,
  WorkspaceName,
  WorkspaceURL,
} from './TenantsList.styled';
import { IMAGE_PATHS } from '../../../constants';

interface Props {
  handleTenantSwitch: (tenant: any) => void;
}

const TenantsList = ({ handleTenantSwitch }: Props) => {
  const {
    store: {
      member: { thisSessionMember },
    },
    actions: {
      tenantActions: { selectTenant },
    },
    dispatch,
  } = useRedux();

  if (!thisSessionMember) {
    return <Loading message="Getting your profile" />;
  }

  const { tenantMemberRoles, tenantOwnerships } = thisSessionMember;

  const handleSelect = (tenantId: string) => {
    handleTenantSwitch({ tenantId });
    dispatch(selectTenant({ tenantId }));
  };

  return (
    <Container>
      <h4>Your available workspaces</h4>
      {tenantMemberRoles && tenantMemberRoles.length >= 1 ? (
        tenantMemberRoles.map(({ tenant, role }: any) => {
          const ownershipIds =
            tenantOwnerships?.map((tenantOwnership: any) =>
              tenantOwnership ? tenantOwnership?.id : null
            ) || [];

          const isOwner = ownershipIds.find((id: string) => id === tenant.id);

          return (
            <WorkspaceContainer key={tenant?.id}>
              <WorkspaceContent>
                <Workspace>
                  <Image
                    imageUrl={null}
                    fallback={IMAGE_PATHS.DEFAULT_IMAGE}
                    styles={{
                      width: '40px',
                      height: '40px',
                    }}
                  />
                  <WorkspaceDetails>
                    <WorkspaceName variant="h4">{tenant?.name}</WorkspaceName>
                    <WorkspaceURL variant="h5">
                      {isOwner ? 'owner' : role.name}
                    </WorkspaceURL>
                  </WorkspaceDetails>
                </Workspace>
                <Button
                  onClick={() => handleSelect(String(tenant.id))}
                  variant="outlined"
                  disableElevation
                >
                  Launch
                </Button>
              </WorkspaceContent>
            </WorkspaceContainer>
          );
        })
      ) : (
        <p>Looks like you have not been added to any workspaces yet.</p>
      )}
    </Container>
  );
};

export default TenantsList;
