import { PATH } from 'Views/Tenant';

export type NavItem = {
  id: string;
  title: string;
  icon: string;
  url: string;
};

const tenantNavigationConfig: NavItem[] = [
  {
    id: 'workstations',
    title: 'Account',
    icon: 'account_circle',
    url: PATH.ACCOUNT,
  },
  {
    id: 'team',
    title: 'Team',
    icon: 'supervisor_account',
    url: PATH.MEMBERS,
  },
];

export { tenantNavigationConfig };
