import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { iState } from 'Model';
import { useSelector, useStore } from 'react-redux';
import { projectActions } from 'Controller/actions';
import { Project } from 'graphql/types.generated';
import { useGetProjectQuery } from 'graphql/getProject.generated';
import { ContextButton, ProjectName } from './ProjectSwitch.styled';

function ProjectSwitch({ tenantId }: { tenantId: string }) {
  const history = useHistory();
  const { dispatch } = useStore();

  const [projectOptions, setProjects] = useState<Project[] | null>(null);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);

  const { allProjects, selectedProject } = useSelector((s: iState) => {
    return {
      allProjects: s.project.projects,
      selectedProject: s.project.project,
    };
  });

  const [userMenu, setUserMenu] = useState(null);

  const [, reexecuteGetProjectQuery] = useGetProjectQuery({
    variables: { projectId: selectedProject.id },
  });

  useEffect(() => {
    setCurrentProject(selectedProject);
    setProjects(allProjects);
  }, [allProjects, selectedProject]);

  const userMenuClick = (event: SyntheticEvent) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const onSelectProject = (selectedProjectId: string) => {
    if (!selectedProjectId) return;

    const _project = allProjects.find((p) => p.id === selectedProjectId);

    if (_project) {
      reexecuteGetProjectQuery();
      dispatch(projectActions.selectProject({ project: _project }));
      history.push(`/project/workstation`);
    }
  };

  return (
    <>
      <ContextButton onClick={userMenuClick}>
        <ProjectName>{currentProject?.name}</ProjectName>
        <Icon color="primary">arrow_drop_down</Icon>
      </ContextButton>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-2',
        }}
      >
        <>
          {projectOptions?.map(({ id, name }: Project) => (
            <MenuItem
              key={id}
              onClick={() => onSelectProject(id)}
              role="button"
            >
              <Box>
                <ListItemText primary={name} />
              </Box>
            </MenuItem>
          ))}
        </>
      </Popover>
    </>
  );
}

export default ProjectSwitch;
