import { Link } from 'react-router-dom';
import { PATH as PROJECT_PATH } from 'Views/Project';

type Props = {
  projectId: string;
  deployment: any;
  disabled?: boolean;
};

const ScheduleLink = ({ projectId, deployment, disabled = false }: Props) => {
  return deployment.resources[0]?.schedule?.startDate ? (
    <Link
      to={PROJECT_PATH.RESOURCE_SCHEDULE}
      style={{ textDecoration: 'none' }}
    >
      Resource Schedule
    </Link>
  ) : (
    <Link to={PROJECT_PATH.PROJECT_SCHEDULE} style={{ textDecoration: 'none' }}>
      Project Schedule
    </Link>
  );
};

export default ScheduleLink;
