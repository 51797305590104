/* eslint-disable no-param-reassign */

import { nanoid } from 'nanoid';

const cacheConfig = {
  resolvers: {
    Tenant: {
      imageUrl: (parent) =>
        `${process.env.REACT_APP_IMGIX}/tenant/${parent.id}/${parent.id}.png`,
    },
    Project: {
      imageUrl: (parent) =>
        `${process.env.REACT_APP_IMGIX}/tenant/${parent?.tenant?.id}/project/${parent.id}/${parent.id}.png`,
    },
  },
  keys: {
    TenantMemberRole: () => nanoid(),
    ProjectMemberRole: () => nanoid(),
  },
};

export default cacheConfig;
