import Grid from '@material-ui/core/Grid';
import ProjectEditshareTable from 'components/Project/ProjectDeploymentsTables/EditShareTable';
import { Container } from './Files.styled';

function ProjectStore() {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Store</h2>
      </Grid>
      <ProjectEditshareTable />
    </Container>
  );
}

export default ProjectStore;
