export const isValidEmail = (email?: string): boolean => {
  if (!email) {
    return false;
  }

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const isMinimumLength = (field: string, length: number): boolean =>
  field.trim().length >= length;

export function getUniqueTimestamp(): number {
  return Date.now() + Math.random();
}

export function getCurrentDate(): string {
  const date = new Date();
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  return dateString;
}

export const getImageOrFallback = (
  path: string,
  fallback: string
): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(path);
    img.onerror = () => resolve(fallback);
  });
};

export const withParam = (
  url: string,
  paramValue: string,
  paramKey: string
) => {
  const [prefix, route] = url.split(paramKey);

  return `${prefix}${paramValue}${route}`;
};

export const STATUS_COLOUR = {
  RUNNING: 'success',
  PENDING: 'warning',
  STOPPING: 'error',
  STOPPED: 'error',
  TERMINATED: 'secondary',
};

export async function sleep(msec: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
