import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteProjectMutationVariables = Types.Exact<{
  projectId: Types.Scalars['Int'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject?: { __typename?: 'Project', id: string } | null };


export const DeleteProjectDocument = gql`
    mutation DeleteProject($projectId: Int!) {
  deleteProject(projectId: $projectId) {
    id
  }
}
    `;

export function useDeleteProjectMutation() {
  return Urql.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument);
};