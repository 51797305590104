import toast from 'react-hot-toast';

export const apiCallWrapper = async ({ dispatch, uiMessage, callback }) => {
  try {
    toast.loading(uiMessage, { duration: 4000, position: 'top-center' });
    const res = await callback();
    toast.success(uiMessage, { duration: 4000, position: 'top-center' });
    return res;
  } catch (e) {
    toast.error(String(e), { duration: 4000, position: 'top-center' });
  }
};
