import { Dispatch, SetStateAction, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { useStore } from 'react-redux';
import { deploymentActions } from 'Controller/actions';
import { Link } from 'react-router-dom';
import ProjectPermissionable from 'components/Common/Permissions/ProjectPermissionable';
import { Deployment } from 'graphql/types.generated';
import { PATH as PROJECT_PATH } from 'Views/Project';
import PERMISSIONS from 'utils/permissions';
import { ContextButton } from './ContextMenu.styled';

function WorkstationContextMenu({
  projectId,
  deployment,
  schedulable,
  connectable,
  stopModalOpen,
  startModalOpen,
  handleSelected,
  connectModalOpen,
}: {
  projectId: string;
  deployment: Deployment;
  schedulable: boolean;
  connectable: boolean;
  stopModalOpen: Dispatch<SetStateAction<boolean>>;
  startModalOpen: Dispatch<SetStateAction<boolean>>;
  connectModalOpen: Dispatch<SetStateAction<boolean>>;
  handleSelected: Dispatch<SetStateAction<Deployment>>;
}) {
  const { dispatch } = useStore();
  const [menu, setMenu] = useState(null);

  const menuClick = (event: any) => {
    setMenu(event.currentTarget);
    handleSelected(deployment);
  };

  const menuClose = () => {
    setMenu(null);
  };

  const onStart = () => startModalOpen(true);
  const onStop = () => stopModalOpen(true);

  const onConnect = () => connectModalOpen(true);

  const schedulableResource = deployment.resources.find(
    (r) => r.productItem.category.toLowerCase() === 'compute'
  );

  const handleSelectDeployment = () => {
    dispatch(
      deploymentActions.selectDeployment({ deploymentId: deployment.id })
    );
  };

  return (
    <>
      <ContextButton onClick={menuClick}>
        <MoreVertIcon />
      </ContextButton>

      <Popover
        onClick={handleSelectDeployment}
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={menuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <>
          {schedulable && (
            <ProjectPermissionable
              minSeniority={PERMISSIONS.project.resources.start}
            >
              <MenuItem onClick={onStart} role="button">
                <ListItemIcon>
                  <Icon>play_arrow</Icon>
                </ListItemIcon>
                <Box>
                  <ListItemText primary="Start" />
                </Box>
              </MenuItem>
            </ProjectPermissionable>
          )}
          {schedulable && (
            <ProjectPermissionable
              minSeniority={PERMISSIONS.project.resources.stop}
            >
              <MenuItem onClick={onStop} role="button">
                <ListItemIcon>
                  <Icon>stop</Icon>
                </ListItemIcon>
                <Box>
                  <ListItemText primary="Stop" />
                </Box>
              </MenuItem>
            </ProjectPermissionable>
          )}
          {schedulable && schedulableResource && (
            <ProjectPermissionable
              minSeniority={PERMISSIONS.project.resources.schedule}
            >
              <MenuItem
                component={Link}
                to={PROJECT_PATH.RESOURCE_SCHEDULE}
                onClick={menuClose}
                role="button"
              >
                <ListItemIcon>
                  <Icon>calendar_today</Icon>
                </ListItemIcon>
                <Box>
                  <ListItemText primary="Schedule" />
                </Box>
              </MenuItem>
            </ProjectPermissionable>
          )}

          {connectable && (
            <MenuItem onClick={onConnect} role="button">
              <ListItemIcon>
                <CastConnectedIcon />
              </ListItemIcon>
              <Box>
                <ListItemText primary="Connect" />
              </Box>
            </MenuItem>
          )}
        </>
      </Popover>
    </>
  );
}

export default WorkstationContextMenu;
