import styled from 'styled-components';

import Button from '@mui/material/Button';

export const ContextButton = styled(Button)`
  height: 100%;
  border: none;
  &:hover {
    border: none;
    background: transparent;
    color: rgb(43, 51, 56);
  }
`;
