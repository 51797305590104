import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export const ContextButton = styled(Button)`
  height: 100%;
  outline: 0;
  border: none;
  padding: 1rem 0;

  &:hover {
    border: none !important;
  }

  > span {
    fill: white;
    color: white;
  }
`;

export const ProjectName = styled(Typography)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem !important;
  color: #fff;
`;
