import styled from 'styled-components';
import { Typography, CircularProgress } from '@mui/material';

const LoadingContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0%;
  justify-content: center;
  padding: 24px;
  width: 100vw;
  height: 100vh;
  background: #ffffffeb;
  z-index: 999;

  > * {
    margin: 1rem 0;
  }
`;

const Loading = ({ message }: { message?: string }) => {
  return (
    <LoadingContainer>
      {message && (
        <Typography variant="h6" color="secondary">
          {message}
        </Typography>
      )}
      <CircularProgress color="secondary" size="4rem" thickness={1} />
    </LoadingContainer>
  );
};

export default Loading;
