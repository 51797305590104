import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { alpha, styled as styledMaterial } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

interface Props {
  id?: string;
  name: string;
  control: any;
  error?: boolean;
  errorMessage?: string;
  type: string;
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const Label = styled<any>('div')`
  margin-bottom: 4px;

  > span {
    color ${({ error }) => (error ? '#db2341' : 'inherit')}
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const ErrorMessage = styled<any>('div')`
  margin-top: 8px;

  > span {
    color: ${({ error }) => (error ? '#db2341' : 'inherit')};
    font-size: 0.9rem;
  }
`;

const StyledInput = styledMaterial(InputBase)(({ theme, error }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: error ? '1px solid #db2341' : '1px solid #bbc2c6',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    margin: '0 0 1rem',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const TextField = ({
  id,
  name,
  control,
  error,
  errorMessage,
  type,
  label,
  required = true,
  fullWidth = true,
  disabled = false,
  placeholder,
}: Props) => {
  return (
    <>
      {label && (
        <Label error={error}>
          <span>{label}</span>
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledInput
            // @ts-ignore
            {...field}
            id={id}
            autoFocus
            type={type}
            // @ts-ignore
            min={1}
            error={error}
            required={required}
            fullWidth={fullWidth}
            disabled={disabled}
            placeholder={placeholder}
          />
        )}
      />
      {errorMessage && (
        <ErrorMessage error={error}>
          <span>{errorMessage}</span>
        </ErrorMessage>
      )}
    </>
  );
};

export default TextField;
