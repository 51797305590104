import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateResourceScheduleMutationVariables = Types.Exact<{
  resourceId: Types.Scalars['String'];
  states: Types.StatesInput;
}>;


export type CreateResourceScheduleMutation = { __typename?: 'Mutation', createResourceSchedule?: { __typename?: 'Schedule', states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null };


export const CreateResourceScheduleDocument = gql`
    mutation CreateResourceSchedule($resourceId: String!, $states: StatesInput!) {
  createResourceSchedule(resourceId: $resourceId, states: $states) {
    states {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
}
    `;

export function useCreateResourceScheduleMutation() {
  return Urql.useMutation<CreateResourceScheduleMutation, CreateResourceScheduleMutationVariables>(CreateResourceScheduleDocument);
};