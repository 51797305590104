import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
`;

export const MainContent = styled.div`
  max-width: 32%;
  margin: 0 auto;
  margin-bottom: 164px;
`;
