import { Link, useHistory } from 'react-router-dom';
import { Button, Breadcrumbs } from '@mui/material';
import { Box, Grid, Typography } from '@material-ui/core';
import CreateProjectWizard from 'components/Project/CreateProjectWizard';
import { useRedux } from 'Hook';
import { PATH as PROJECT_PATH } from 'Views/Project';
import { PATH as TENANT_PATH } from 'Views/Tenant';
import { Container, MainContent } from './CreateNewProject.styled';

function CreateProject() {
  const history = useHistory();

  const {
    store: {
      tenant: { tenants, userSelected },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ textDecoration: 'none' }}
              color="inherit"
              to={PROJECT_PATH.DASHBOARD}
            >
              {thisTenant.name}
            </Link>
            <Typography>Project</Typography>
            <Typography>Create</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #eee',
        }}
      >
        <h2>New Project</h2>
        <Box>
          <Button
            onClick={() => history.push(TENANT_PATH.DASHBOARD)}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </Box>
      </Box>
      <MainContent>
        <CreateProjectWizard tenantId={thisTenant.id} />
      </MainContent>
    </Container>
  );
}

export default CreateProject;
