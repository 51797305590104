import { Link } from 'react-router-dom';
import { Grid, Box, Typography } from '@material-ui/core';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { PATH as PROJECT_PATH } from 'Views/Project';

type Props = {
  pageTitle?: string;
  tenantName: string;
  projectId: string;
};

const ProjectBreadcrumb = ({ tenantName, projectId, pageTitle }: Props) => {
  return (
    <Grid item xs>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={PROJECT_PATH.DASHBOARD}>
          {tenantName}
        </Link>
        <Link color="inherit" to={PROJECT_PATH.WORKSTATION}>
          <Typography>Project</Typography>
        </Link>
        {pageTitle && <Typography>{pageTitle}</Typography>}
      </Breadcrumbs>

      {pageTitle && (
        <Box>
          <h2>{pageTitle}</h2>
        </Box>
      )}
    </Grid>
  );
};

export default ProjectBreadcrumb;
