import Type from 'Controller/Tenant/Tenant.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';

type TenantMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: TenantMiddleware = {
  [Type.SET_ALL_TENANTS]: (store, next, action) => {
    next(action);
  },
  [Type.SELECT_TENANT]: (store, next, action) => {
    next(action);
  },
};

export default middleware;
