import { useState, useEffect } from 'react';
import { iState } from 'Model';
import { useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import jwt from 'njwt';
import { useGetProjectTeradiciConnectorsQuery } from 'graphql/getProjectTeradiciConnectors.generated';

import { DataPoint, HelpLinks } from './workstationConnect.styles';

const WorkstationConnectModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const projectId = useSelector((s: iState) => {
    return parseInt(s.project.project.id, 10);
  });

  const user = useSelector((s: iState) => {
    return s.member.thisSessionMember;
  });

  const [broker, setBroker] = useState('');
  const [usr, setUsr] = useState('');
  const [data, setData] = useState('');

  const [machineOs, setMachineOs] = useState(null);
  const [machineOsType, setMachineOsType] = useState('');

  const [connectorHealth, setConnectorHealth] = useState(null);
  const [connectorStatus, setConnectorStatus] = useState(null);

  useEffect(() => {
    // @ts-ignore
    setMachineOs(navigator.oscpu);
    const isMac = machineOs && machineOs.toLowerCase().includes('mac');
    const isWindows = machineOs && machineOs.toLowerCase().includes('windows');

    if (isMac) {
      setMachineOsType('mac');
    }

    if (isWindows) {
      setMachineOsType('windows');
    }
  }, [broker]);

  const [
    {
      data: _teradiciConnectorData,
      fetching: _isFetchingTeradiciConnectors,
      error: _errorTeradiciConnectors,
    },
    call,
  ] = useGetProjectTeradiciConnectorsQuery({
    variables: { projectId },
    pause: true,
  });

  useEffect(() => {
    if (_teradiciConnectorData) {
      setBroker(
        _teradiciConnectorData.getProjectTeradiciConnectors.loadBalancerUrl
      );

      setUsr(
        `${user?.givenName.toLowerCase()}.${user?.familyName.toLowerCase()}`
      );

      setConnectorHealth(
        _teradiciConnectorData.getProjectTeradiciConnectors.connectors.every(
          (c) => c.healthStatus.toLowerCase() === 'healthy'
        )
      );

      setConnectorStatus(
        _teradiciConnectorData.getProjectTeradiciConnectors.connectors.every(
          (c) => c.status.toLowerCase() === 'active'
        )
      );

      const _jwt = jwt.create({ usr }, 'secure').compact();
      setData(_jwt);
    }
  }, [_teradiciConnectorData]);

  useEffect(() => {
    if (open) {
      call();
    }
  }, [open]);

  const handleConnect = () => {
    const connectionString = `pcoip://${broker}/connect?${data}`;
    window.open(connectionString, '_blank');
    setOpen(false);
  };

  const handleDownloadClient = () => {
    window.open(
      'https://docs.teradici.com/find/product/software-and-mobile-clients',
      '_blank'
    );
  };

  const handleGetHelp = () => {
    if (machineOsType === 'mac') {
      window.open(
        'https://www.teradici.com/web-help/pcoip_client/mac/22.01/using/connecting_to_virtual_machines/',
        '_blank'
      );
    }

    if (machineOsType === 'windows') {
      window.open(
        'https://www.teradici.com/web-help/pcoip_client/windows/22.01/using/connecting_to_virtual_machines/',
        '_blank'
      );
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Connect to workstation</DialogTitle>
      {_isFetchingTeradiciConnectors && <p>please wait ...</p>}
      {!!_teradiciConnectorData && (
        <>
          <DialogContent>
            <HelpLinks>
              <Typography onClick={() => handleDownloadClient()}>
                <FileDownloadIcon />
                Download the PCoIP client
              </Typography>
              <Typography onClick={() => handleGetHelp()}>
                <HelpOutlineIcon />
                {`Help using the PCoIP client for ${machineOsType.toUpperCase()}`}
              </Typography>
            </HelpLinks>
            <hr style={{ margin: '2rem 0 1rem', opacity: '0.1' }} />
            <DataPoint>
              <Typography>Your OS</Typography>
              <Typography>{machineOs}</Typography>
            </DataPoint>
            <DataPoint>
              <Typography>Broker</Typography>
              <Typography>{broker}</Typography>
            </DataPoint>
            <DataPoint>
              <Typography>Username</Typography>
              <Typography>{usr}</Typography>
            </DataPoint>
            <DataPoint>
              <Typography>Connector status</Typography>
              <Typography>{connectorStatus ? 'Running' : 'Stopped'}</Typography>
            </DataPoint>
            <DataPoint>
              <Typography>Connector health</Typography>
              <Typography>
                {connectorHealth ? 'Healthy' : 'Unhealthy'}
              </Typography>
            </DataPoint>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleConnect()}
              variant="outlined"
              color="primary"
            >
              Connect
            </Button>
          </DialogActions>
        </>
      )}
      {_errorTeradiciConnectors && (
        <p>{JSON.stringify(_errorTeradiciConnectors)}</p>
      )}
    </Dialog>
  );
};

export default WorkstationConnectModal;
