import { useSelector } from 'react-redux';
import { iState } from 'Model';
import { Typography } from '@mui/material';
import Table from '../components/Table';

const ProjectEditshareTable = () => {
  const { editShareDeployments, project } = useSelector((store: iState) => {
    return {
      project: store.project.project,
      editShareDeployments: store.deployment.deployments.filter((d) => {
        return store.deployment.editShare.includes(d.id);
      }),
    };
  });

  return editShareDeployments ? (
    <Table
      projectId={project.id}
      deployments={editShareDeployments}
      expandable
      connectable={false}
      schedulable
    />
  ) : (
    <Typography>No edit share deployments exists</Typography>
  );
};

export default ProjectEditshareTable;
