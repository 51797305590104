import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

interface Props {
  children: React.ReactNode;
  id?: string;
  name: string;
  onSubmit: () => void;
  noValidate?: boolean;
}

const Form = ({ children, id, name, onSubmit, noValidate = true }: Props) => {
  return (
    <StyledForm
      id={id || name}
      name={name}
      noValidate={noValidate}
      onSubmit={onSubmit}
    >
      {children}
    </StyledForm>
  );
};

export default Form;
