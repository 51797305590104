import { Link } from 'react-router-dom';
import { PATH as TENANT_PATH } from 'Views/Tenant';
import { useRedux } from 'Hook';
import { TenantName } from './TenantSwitch.styled';

function TenantSwitch() {
  const {
    store: {
      tenant: { userSelected, tenants },
    },
  } = useRedux();

  const tenant = tenants.find((t) => String(t.id) === String(userSelected));

  return (
    <Link to={TENANT_PATH.DASHBOARD} style={{ textDecoration: 'none' }}>
      <TenantName>{tenant?.name || ''}</TenantName>
    </Link>
  );
}

export default TenantSwitch;
