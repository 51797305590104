import { Link } from 'react-router-dom';
import { useRedux } from 'Hook';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { PATH as PROJECT_PATH } from 'Views/Project';
import UpdateAccount from './components/UpdateAccount';
import { Container, FormContainer } from './Account.styled';

function Account() {
  const {
    store: {
      member: { thisSessionMember },
      tenant: { tenants, userSelected },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          style={{ textDecoration: 'none' }}
          color="inherit"
          to={PROJECT_PATH.DASHBOARD}
        >
          {thisTenant.name}
        </Link>
        <Typography>
          {thisSessionMember.givenName} {thisSessionMember.familyName}
        </Typography>
        <Typography>Account</Typography>
      </Breadcrumbs>
      <h1>Account</h1>
      {thisSessionMember && (
        <FormContainer>
          <UpdateAccount user={thisSessionMember} />
        </FormContainer>
      )}
    </Container>
  );
}

export default Account;
