import axios from 'axios';
import ProjectType from 'Controller/Project/Project.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { deploymentActions, projectActions } from 'Controller/actions';
import { apiCallWrapper } from 'utils/api';
import { Project } from 'graphql/types.generated';
import { getProject, setWorkstationEntitlements } from './GraphQL';

type ProjectMiddleware = Record<ProjectType, ReduxMiddlewareFunction>;

const projectMiddleware: ProjectMiddleware = {
  [ProjectType.SET_WORKSTATION_ENTITLEMENTS]: async (
    store,
    next,
    action,
    apiClient
  ) => {
    const { dispatch } = store;

    const {
      project: { selectedProjectId },
    } = store.getState();

    try {
      const response = await apiCallWrapper({
        dispatch,
        uiMessage: `Entitle members to workstations`,
        callback: async () => {
          await apiClient.mutate({
            mutation: setWorkstationEntitlements,
            variables: { projectId: parseInt(selectedProjectId, 10) },
          });
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [ProjectType.SET_PROJECT]: (store, next, action) => {
    const { dispatch } = store;
    const { payload } = action;
    const {
      project: { deployments },
    } = payload;

    dispatch(deploymentActions.setAllDeployments({ deployments }));

    next(action);
  },

  [ProjectType.SET_PROJECTS]: (store, next, action) => {
    next(action);
  },

  [ProjectType.REFETCH]: async (store, next, action, apiClient) => {
    const { dispatch, getState } = store;

    const {
      project: { project },
    } = getState();

    const response = await apiClient.mutate({
      mutation: getProject,
      variables: {
        projectId: String(project.id),
      },
    });

    dispatch(projectActions.setProject({ project: response.data.getProject }));

    next(action);
  },

  [ProjectType.SELECT_PROJECT]: async (store, next, action) => {
    next(action);

    try {
      const { payload } = action;

      const { id } = payload.project as Project;

      const state = store.getState();

      const { userSelected } = state.tenant;

      const selectedTenant = state.tenant.tenants.find(
        (t) => t.id === String(userSelected)
      );

      const [domain, ext] = window.location.host.split('.');

      await axios({
        method: 'PUT',
        url: `https://auth.${domain}.cloud/session`,
        data: {
          selectedProjectId: id,
          selectedProjectAwsAccountId: selectedTenant.awsAccountId,
        },
        withCredentials: true,
      });
    } catch (e) {
      console.error(String(e));
    }
  },
};

export default projectMiddleware;
