import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Project } from 'graphql/types.generated';
import { useStore } from 'react-redux';
import Image from 'components/Common/Image';
import { PATH as PROJECT_PATH } from 'Views/Project';
import { projectActions } from 'Controller/actions';
import {
  Container,
  ProjectContainer,
  ProjectContent,
  ProjectDetails,
  ProjectName,
  ProjectDeployments,
} from './ProjectList.styled';
import { IMAGE_PATHS } from '../../../constants';

const ProjectsList = ({ projects }: { projects: any }) => {
  const { dispatch } = useStore();

  const history = useHistory();

  const onSelectProject = (project: Project) => {
    dispatch(projectActions.selectProject({ project }));
    history.push(PROJECT_PATH.WORKSTATION);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {projects?.map((project: any) => {
          return (
            <Grid item xs={4} key={project?.id}>
              <ProjectContainer onClick={() => onSelectProject(project)}>
                <ProjectContent>
                  <Image
                    imageUrl={null}
                    fallback={IMAGE_PATHS.DEFAULT_IMAGE}
                    styles={{
                      maxWidth: '164px',
                      maxHeight: '80px',
                    }}
                  />
                  <ProjectDetails>
                    <ProjectName variant="h5">{project?.name}</ProjectName>
                    <ProjectDeployments>
                      {project?.deployments?.length} deployments
                    </ProjectDeployments>
                  </ProjectDetails>
                </ProjectContent>
              </ProjectContainer>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default ProjectsList;
