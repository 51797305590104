import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export const ContextButton = styled(Button)`
  height: 100%;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.4rem;
`;

export const UserDisplayName = styled(Typography)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem !important;
  color: #fff;
`;

export const UserRole = styled(Typography)`
  text-transform: capitalize;
  font-size: 0.8rem !important;
  font-weight: 500;
  color: #aab3b8 !important;
`;
