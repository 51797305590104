import { ProductCategory } from 'graphql/types.generated';
import Type from 'Controller/Product/Product.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';

type Middleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: Middleware = {
  [Type.SET_ALL_PRODUCTS]: (store: any, next: any, action: any) => {
    const { products } = action.payload;

    const byId = (p) => p.id;

    const byCategory = (category) => (product) => product.category === category;

    const teradici = products
      .filter(byCategory(ProductCategory.Teradici))
      .map(byId);

    const editShare = products
      .filter(byCategory(ProductCategory.Editshare))
      .map(byId);

    const workstation = products
      .filter(byCategory(ProductCategory.Workstation))
      .map(byId);

    const transfer = products
      .filter(byCategory(ProductCategory.Transfer))
      .map(byId);

    const activeDirectory = products
      .filter(byCategory(ProductCategory.ActiveDirectory))
      .map(byId);

    action.payload = {
      products,
      categorizeById: {
        teradici,
        editShare,
        workstation,
        transfer,
        activeDirectory,
      },
    };

    next(action);
  },
};

export default middleware;
