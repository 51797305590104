import Type from 'Controller/Member/Member.type';
import { tenantActions } from 'Controller/actions';
import { ReduxMiddlewareFunction } from 'Controller/middleware';

type MemberMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: MemberMiddleware = {
  [Type.SET_THIS_SESSION_MEMBER]: (store: any, next: any, action: any) => {
    const { dispatch } = store;

    const { tenantMemberRoles } = action.payload.member;

    const tenants = tenantMemberRoles.map((tmr) => tmr.tenant);

    dispatch(tenantActions.setAllTenants({ tenants }));

    next(action);
  },
};

export default middleware;
