import Type from 'Controller/Onboarding/Onboarding.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';
import { apiCallWrapper } from 'utils/api';
import { list, setSendStatus } from './Onboarding.action';
import {
  listInvitations,
  sendInvitations,
  resendInvitation,
  revokeInvitation,
} from './Api';

type OnboardingMiddleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: OnboardingMiddleware = {
  [Type.SET_SEND_STATUS]: (store, next, action, apiClient) => {
    next(action);
  },

  [Type.INVITE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { projectId, invitations } = action.payload;

    try {
      await apiCallWrapper({
        dispatch,
        uiMessage: `Inviting new members ...`,
        callback: async () => {
          await apiClient.mutate({
            mutation: sendInvitations,
            variables: { projectId, invitations },
          });
          dispatch(list({ projectId }));
          dispatch(setSendStatus({ sendStatus: true }));
        },
      });
    } catch (e) {
      console.error(e);
      dispatch(setSendStatus({ sendStatus: false }));
    } finally {
      next(action);
    }
  },

  [Type.LIST]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { projectId } = action.payload;

    try {
      await apiCallWrapper({
        dispatch,
        uiMessage: `List all invitiations...`,
        callback: async () => {
          const response = await apiClient.query({
            query: listInvitations,
            variables: { projectId },
          });

          action.payload.invitations = response.data.listInvitations;
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.RESEND]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { inviteId, projectId } = action.payload;

    try {
      await apiCallWrapper({
        dispatch,
        uiMessage: `Resending invitation...`,
        callback: async () => {
          const response = await apiClient.mutate({
            mutation: resendInvitation,
            variables: { inviteId, projectId },
          });

          dispatch(list({ projectId }));
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },

  [Type.REVOKE]: async (store, next, action, apiClient) => {
    const { dispatch } = store;
    const { inviteId, projectId } = action.payload;

    try {
      await apiCallWrapper({
        dispatch,
        uiMessage: `Revoking invitation...`,
        callback: async () => {
          const response = await apiClient.mutate({
            mutation: revokeInvitation,
            variables: { inviteId, projectId },
          });

          dispatch(list({ projectId }));
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      next(action);
    }
  },
};

export default middleware;
