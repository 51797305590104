import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import { Grid, Box, Typography } from '@material-ui/core';
import UserManagementTable from 'components/User/UserManagementTable';
import Loading from 'components/Common/Loading';
import { useGetTenantMembersQuery } from 'graphql/getTenantMembers.generated';
import { useRedux } from 'Hook';
import { TargetType } from 'graphql/types.generated';
import { PATH as PROJECT_PATH } from 'Views/Project';
import { Container } from './Manage.styled';

function ManageUsers() {
  const {
    store: {
      tenant: { tenants, userSelected },
      project: { selectedProjectId, projects },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));

  const [users, setUsers] = useState([]);

  const [{ data, error, fetching }] = useGetTenantMembersQuery({
    variables: { tenantId: thisTenant.id },
    pause: !thisTenant,
  });

  useEffect(() => {
    if (data?.getTenant) {
      const { memberRoles } = data?.getTenant;

      if (memberRoles?.length) {
        setUsers(memberRoles);
      }
    }
  }, [data]);

  if (fetching) {
    return <Loading message={`Fetching ${thisTenant.name} members`} />;
  }

  if (error) {
    return <span>There was a problem loading users.</span>;
  }

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ textDecoration: 'none' }}
              color="inherit"
              to={PROJECT_PATH.DASHBOARD}
            >
              {thisTenant.name}
            </Link>
            <Typography>Admin</Typography>
            <Typography>Manage Team</Typography>
          </Breadcrumbs>
          <Box>
            <h2>Manage Team</h2>
          </Box>
        </Grid>
      </Grid>

      {thisTenant.id && (
        <UserManagementTable
          targetId={thisTenant.id}
          targetType={TargetType.Tenant}
          users={users}
          removable={false}
        />
      )}
    </Container>
  );
}

export default ManageUsers;
