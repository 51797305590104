import { Tenant } from 'graphql/types.generated';
import Type from 'Controller/Tenant/Tenant.type';

export interface TenantState {
  tenants: Array<Tenant>;
  userSelected: number | null;
}

const initalState: TenantState = {
  tenants: [],
  userSelected: null,
};

type Action = {
  type: Type;
  payload: {
    tenantId: number;
    tenant: Tenant;
    tenants: Array<Tenant>;
  };
};

export default function TenantReducer(
  state: TenantState = initalState,
  action: Action
): TenantState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL_TENANTS: {
      const { tenants } = payload;
      return { ...state, tenants };
    }

    case Type.SELECT_TENANT: {
      const { tenantId } = payload;
      return { ...state, userSelected: tenantId };
    }

    default:
      return state;
  }
}
