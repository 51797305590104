import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StopResourcesMutationVariables = Types.Exact<{
  resourceIds: Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>;
  hours: Types.Scalars['Int'];
}>;


export type StopResourcesMutation = { __typename?: 'Mutation', stopResources?: Array<{ __typename?: 'Schedule', states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null> | null };


export const StopResourcesDocument = gql`
    mutation StopResources($resourceIds: [String]!, $hours: Int!) {
  stopResources(resourceIds: $resourceIds, hours: $hours) {
    states {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
}
    `;

export function useStopResourcesMutation() {
  return Urql.useMutation<StopResourcesMutation, StopResourcesMutationVariables>(StopResourcesDocument);
};