import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetProjectQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type GetProjectQuery = { __typename?: 'Query', getProject?: { __typename?: 'Project', id: string, name: string, createdAt: string, imageUrl?: string | null, tenant: { __typename?: 'Tenant', id: string, awsAccountId: string, name: string }, projectMemberRoles?: Array<{ __typename?: 'ProjectMemberRole', member: { __typename?: 'Member', id: string, givenName: string, familyName: string, email: string }, role: { __typename?: 'Role', id: string, name: string, seniority: number } } | null> | null, deployments?: Array<{ __typename?: 'Deployment', id: string, name?: string | null, removed: boolean, removedAt?: string | null, deployedAt: string, deployedBy: string, project: { __typename?: 'Project', id: string }, product?: { __typename?: 'Product', id: string, name: string, description: string, category: Types.ProductCategory, publishedAt?: string | null, service: { __typename?: 'Service', id: string, name: string }, vendor: { __typename?: 'Vendor', id: string, name: string } } | null, resources?: Array<{ __typename?: 'Resource', id: string, name: string, physicalId?: string | null, state?: string | null, productItem?: { __typename?: 'Item', category: Types.ItemCategory, name: string, id: string } | null, schedule?: { __typename?: 'Schedule', states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null } | null> | null } | null> | null, schedule?: { __typename?: 'Schedule', utcOffset?: number | null, timezone?: string | null, states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null } | null };


export const GetProjectDocument = gql`
    query GetProject($projectId: String!) {
  getProject(projectId: $projectId) {
    id
    name
    createdAt
    imageUrl
    tenant {
      id
    }
    projectMemberRoles {
      member {
        id
        givenName
        familyName
        email
      }
      role {
        id
        name
        seniority
      }
    }
    tenant {
      awsAccountId
      id
      name
    }
    deployments {
      id
      name
      removed
      removedAt
      deployedAt
      deployedBy
      project {
        id
      }
      product {
        id
        name
        description
        category
        publishedAt
        service {
          id
          name
        }
        vendor {
          id
          name
        }
      }
      resources {
        id
        productItem {
          category
          name
          id
        }
        name
        physicalId
        state
        schedule {
          states {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
    }
    schedule {
      states {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      utcOffset
      timezone
    }
  }
}
    `;

export function useGetProjectQuery(options: Omit<Urql.UseQueryArgs<GetProjectQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProjectQuery, GetProjectQueryVariables>({ query: GetProjectDocument, ...options });
};