import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetProjectTeradiciConnectorsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['Int'];
}>;


export type GetProjectTeradiciConnectorsQuery = { __typename?: 'Query', getProjectTeradiciConnectors?: { __typename?: 'ProjectConnectors', loadBalancerUrl: string, connectors?: Array<{ __typename?: 'TeradiciConnector', status: string, healthStatus: string, publicIp: string } | null> | null } | null };


export const GetProjectTeradiciConnectorsDocument = gql`
    query GetProjectTeradiciConnectors($projectId: Int!) {
  getProjectTeradiciConnectors(projectId: $projectId) {
    loadBalancerUrl
    connectors {
      status
      healthStatus
      publicIp
    }
  }
}
    `;

export function useGetProjectTeradiciConnectorsQuery(options: Omit<Urql.UseQueryArgs<GetProjectTeradiciConnectorsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProjectTeradiciConnectorsQuery, GetProjectTeradiciConnectorsQueryVariables>({ query: GetProjectTeradiciConnectorsDocument, ...options });
};