import { useEffect } from 'react';
import { useSubscription, gql } from '@apollo/client';
import { useStore } from 'react-redux';
import { notificationActions } from 'Controller/actions';

const useProjectNotifications = (projectId: string) => {
  const { dispatch } = useStore();

  const { data, loading, error } = useSubscription(
    gql`
      subscription OnProjectNotifcation($projectId: String!) {
        onProjectNotifcation(projectId: $projectId) {
          projectId
          source
          title
          level
          message
          json
          time
        }
      }
    `,
    {
      variables: { projectId: String(projectId) },
    }
  );

  useEffect(() => {
    if (data && !loading && !error) {
      dispatch(
        notificationActions.addProjectNotification({
          notification: data.onProjectNotifcation,
        })
      );
    }
  }, [data, loading, error]);

  return { data, loading, error };
};

export default useProjectNotifications;
