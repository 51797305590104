import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetResourceQueryVariables = Types.Exact<{
  resourceId: Types.Scalars['String'];
}>;


export type GetResourceQuery = { __typename?: 'Query', getResource?: { __typename?: 'Resource', id: string, name: string, physicalId?: string | null, state?: string | null, deployment: { __typename?: 'Deployment', id: string, name?: string | null, project: { __typename?: 'Project', schedule?: { __typename?: 'Schedule', states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null } }, productItem?: { __typename?: 'Item', category: Types.ItemCategory, name: string, id: string } | null, schedule?: { __typename?: 'Schedule', states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null } | null };


export const GetResourceDocument = gql`
    query GetResource($resourceId: String!) {
  getResource(resourceId: $resourceId) {
    id
    deployment {
      id
      name
      project {
        schedule {
          states {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
    }
    productItem {
      category
      name
      id
    }
    name
    physicalId
    state
    schedule {
      states {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
}
    `;

export function useGetResourceQuery(options: Omit<Urql.UseQueryArgs<GetResourceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetResourceQuery, GetResourceQueryVariables>({ query: GetResourceDocument, ...options });
};