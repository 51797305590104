if (!process.env.REACT_APP_ENV) {
  throw new Error(
    'No environment variable set run with either dev, qa or prod'
  );
}

const awsRegion = 'eu-west-1';

// Ref: https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
const AMPLIFY_AUTH_CONFIG = {
  mandatorySignIn: false,
  region: awsRegion,
  userPoolId: process.env.REACT_APP_USERPOOLID,
  identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOLCLIENTID,
};
// @ts-ignore
export const API_BASE_URL = process.env.REACT_APP_CUSTOM_API_URL;
export const AWS_AMPLIFY_CONFIG = AMPLIFY_AUTH_CONFIG;
export const JWT_LOCALSTORAGE_KEY = 'tyrell_id_token';
export const IDENTITY_LOCALSTORAGE_KEY = 'tyrell_identity_id';
export const TEMP_PWD_LOCALSTORAGE_KEY = 'auto_sign_in';
export const TENANT_LOCALSTORAGE_KEY = 'tyrell_tenant';
export const PROJECT_LOCALSTORAGE_KEY = 'tyrell_project';
export const TENANT_CONTENT_BUCKET_NAME =
  process.env.REACT_APP_USERCONTENTBUCKETNAME;

export enum IMAGE_PATHS {
  LOGO_PNG = '/images/tyrell-cloud-logo.png',
  LOGO_SVG = '/images/tyrell-cloud-logo.svg',
  LOGO_INVESE_SVG = '/images/tyrell-cloud-logo-inverse.svg',
  ABSTRACT_BACKGROUND = '/images/abstract_texture_right_side_1920x1080.png',
  AVATAR_BACKGROUND = '/images/avatar_background.jpeg',
  DEFAULT_IMAGE = '/images/abstract_shards.png',
  PROJECT_IMAGE = '/images/project_image.png',
}

export const ROLE_TYPES = {
  tyrell: 100,
  admin: 80,
  ops: 60,
  user: 40,
  disabled: 0,
};

export const DAYS = [
  { value: 'MON', label: 'Monday' },
  { value: 'TUE', label: 'Tuesday' },
  { value: 'WED', label: 'Wednesday' },
  { value: 'THU', label: 'Thursday' },
  { value: 'FRI', label: 'Friday' },
  { value: 'SAT', label: 'Saturday' },
  { value: 'SUN', label: 'Sunday' },
];

export const TIME_OPTIONS = [
  { value: '01:00', label: '1:00' },
  { value: '02:00', label: '2:00' },
  { value: '03:00', label: '3:00' },
  { value: '04:00', label: '4:00' },
  { value: '05:00', label: '5:00' },
  { value: '06:00', label: '6:00' },
  { value: '07:00', label: '7:00' },
  { value: '08:00', label: '8:00' },
  { value: '09:00', label: '9:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
  { value: '00:00', label: '00:00' },
];
