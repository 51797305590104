import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@material-ui/core/Box';
import { Form, TextField } from 'components/Common/Forms';
import { useStopResourcesMutation } from 'graphql/stopResource.generated';

/**
 *
 * Form Validation Schema
 */
type FormInputs = {
  hours: number;
};

const schema = yup.object().shape({
  hours: yup.number().min(1, 'Must be greater than one hour'),
});

const defaultValues = {
  name: '',
  hours: 1,
};

export default function StopResourceModal({
  open,
  setOpen,
  resourceId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  resourceId?: string;
}) {
  const handleClose = () => {
    reset(defaultValues);
    setOpen(false);
  };

  const [{ fetching }, stopResources] = useStopResourcesMutation();

  const [error, setError] = useState<string | null>(null);

  const [confirmChangesAreUnderstood, setConfirmChangesAreUnderstood] =
    useState(false);

  const { control, formState, handleSubmit, reset } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = formState;

  const onSubmit = async (values: FormInputs) => {
    setError(null);

    if (!resourceId) {
      setError('No schedulable compute resources');
      return;
    }

    if (values.hours > 24) {
      reset(defaultValues);
      setError('Hours cant be greater than 24 hours');
      return;
    }

    const { error: mutationError } = await stopResources({
      resourceIds: [resourceId],
      hours: values.hours,
    });

    if (mutationError) {
      reset(defaultValues);
      setError('Could not stop workstations. Please try again.');
      return;
    }

    reset(defaultValues);
    setOpen(false);
    toast.success('Workstations stopped', {
      duration: 4000,
      position: 'top-center',
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Stop Workstations</DialogTitle>
      <Form
        name="manuallyOverideResourceSchedule"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent>
          <DialogContentText>
            Temporarily override the schedule and stop selected workstations.
            <Typography>{`Resource ID: ${resourceId}`}</Typography>
          </DialogContentText>
          <Box
            pt={2}
            mb={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box>
              <TextField
                type="number"
                name="hours"
                control={control}
                error={!!errors.hours}
                errorMessage={errors?.hours?.message}
                disabled={fetching}
              />
            </Box>
            <Box ml={2}>
              <span>Hour(s)</span>
            </Box>
          </Box>
          {error && <span>{error}</span>}
          <Alert variant="outlined" severity="error">
            <AlertTitle>Overide the project default</AlertTitle>
            Changes made to this schedule will override the default project
            schedule and effect only this computer.
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmChangesAreUnderstood}
                  onChange={(e) =>
                    setConfirmChangesAreUnderstood(e.target.checked)
                  }
                />
              }
              label="I understand this will effect montly billing"
            />
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            disabled={!isValid || fetching || !confirmChangesAreUnderstood}
            variant="outlined"
            color="primary"
            loading={fetching}
          >
            Stop Workstation
          </LoadingButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
