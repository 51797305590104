import React from 'react';

import {
  StyledAvatar,
  Image,
  NoProfilePicture,
  Intials,
} from './Avatar.styled';

interface Props {
  src?: string;
  alt?: string;
  displayName?: string;
  size?: string;
}

const getDisplayName = (displayName: string) => {
  const split = displayName.trim().split(' ');

  if (!split.length) return null;

  return split.map((name) => name[0].toUpperCase() || '').join(' ');
};

const Avatar = ({ src, alt, displayName, size }: Props) => (
  <StyledAvatar size={size}>
    {src ? (
      <Image src={src} alt={alt} />
    ) : (
      <NoProfilePicture>
        <Intials>{displayName && getDisplayName(displayName)}</Intials>
      </NoProfilePicture>
    )}
  </StyledAvatar>
);

export default Avatar;
