import ProjectBreadcrumb from 'components/Project/ProjectBreadcrumb';
import { useRedux } from 'Hook';
import { Container } from './Dashboard.styled';

function ProjectDashboard() {
  const {
    store: {
      tenant: { tenants, userSelected },
      project: { selectedProjectId, projects },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));
  const thisProject = projects.find(
    (p) => String(p.id) === String(selectedProjectId)
  );

  return (
    <Container>
      <ProjectBreadcrumb
        pageTitle="Dashboard"
        tenantName={thisTenant.name}
        projectId={thisProject.id}
      />
      <h1>{thisProject.name} Dashboard</h1>
    </Container>
  );
}

export default ProjectDashboard;
