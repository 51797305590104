import { useSelector } from 'react-redux';
import { iState } from 'Model';
import { Typography } from '@mui/material';
import Table from '../components/Table';

const ProjectTerradiciTable = () => {
  const { teradiciDeployments, project } = useSelector((store: iState) => {
    return {
      project: store.project.project,
      teradiciDeployments: store.deployment.deployments.filter((d) => {
        return store.deployment.teradici.includes(d.id);
      }),
    };
  });

  return teradiciDeployments ? (
    <Table
      projectId={project.id}
      deployments={teradiciDeployments}
      connectable={false}
      expandable
      schedulable
    />
  ) : (
    <Typography>No teradici connectors exists</Typography>
  );
};

export default ProjectTerradiciTable;
