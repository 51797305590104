import { useRedux } from 'Hook';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle, Breadcrumbs } from '@mui/material';
import ResourceSchedule from 'components/v2/Schedule/resource';
import Loading from 'components/Common/Loading';
import toast from 'react-hot-toast';
import { useGetResourceQuery } from 'graphql/getResource.generated';
import { useCreateResourceScheduleMutation } from 'graphql/createResourceSchedule.generated';
import { useUpdateResourceScheduleMutation } from 'graphql/updateResourceSchedule.generated';
import {
  Schedule,
  ItemCategory,
  ProductCategory,
} from 'graphql/types.generated';
import { iState } from 'Model';
import { Container } from './ResourceSchedule.styled';

function ResourceSchedulePage() {
  const {
    store: {
      deployment: { userSelected },
    },
  } = useRedux();

  const deployment = useSelector((state: iState) => {
    return state.deployment.userSelected;
  });

  const computeResources = useSelector((state: iState) => {
    return state.deployment.userSelected.resources.filter((r) => {
      return r.productItem.category === ItemCategory.Compute;
    });
  });

  const resourceId = computeResources[0].id;

  const [resouceSchedule, setResourceSchedule] = useState<Schedule | null>(
    null
  );

  const [isNetworkActive, setIsNetworkActive] = useState('Fetching schedule');

  const [{ data, fetching, error }] = useGetResourceQuery({
    variables: { resourceId },
    requestPolicy: 'network-only',
  });

  const [{ fetching: updateFetching }, updateResourceSchedule] =
    useUpdateResourceScheduleMutation();

  const [{ fetching: createFetching }, createResourceSchedule] =
    useCreateResourceScheduleMutation();

  useEffect(() => {
    if (data?.getResource) {
      setIsNetworkActive(null);
      setResourceSchedule(data?.getResource?.schedule || null);
    }
  }, [data]);

  const resource = data?.getResource;

  const handleResourceScheduleSave = async ({ states }) => {
    /**
     *
     * Update
     */
    if (resource?.schedule?.states) {
      setIsNetworkActive('Saving schedule');

      await Promise.all(
        computeResources.map(async (cr) => {
          const { data: updateData, error: updateError } =
            await updateResourceSchedule({
              resourceId: cr.id,
              states,
            });

          if (updateError)
            toast.error('Schedule not saved', {
              duration: 4000,
              position: 'top-center',
            });

          if (updateData)
            toast.success('Schedule saved', {
              duration: 4000,
              position: 'top-center',
            });
        })
      );
    } else {
      /**
       *
       * Create
       */
      setIsNetworkActive('Creating new schedule');

      await Promise.all(
        computeResources.map(async (cr) => {
          const { data: createData, error: createError } =
            await createResourceSchedule({
              resourceId: cr.id,
              states,
            });

          if (createError)
            toast.error('Schedule not created', {
              duration: 4000,
              position: 'top-center',
            });

          if (createData)
            toast.success('Schedule created', {
              duration: 4000,
              position: 'top-center',
            });
        })
      );
    }
    setIsNetworkActive(null);
  };

  const isTeradici =
    deployment.product.vendor.name.toLowerCase() === 'teradici';

  return (
    <Container>
      <Grid item xs>
        <Box mt={2} mb={2}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography>Settings</Typography>
            <Typography>Schedule</Typography>
          </Breadcrumbs>
        </Box>
        {error && (
          <Alert severity="error">
            There was a problem loading the schedule. Please try again.
          </Alert>
        )}
        {isNetworkActive && <Loading message={isNetworkActive || ''} />}
        <>
          <Box mt={2}>
            <h2>{resource?.name || deployment.name} schedule</h2>
          </Box>
          {isTeradici && (
            <Box sx={{ maxWidth: '762px', margin: '1rem auto 2rem' }}>
              <Alert severity="warning">
                <AlertTitle>Teradici connector schedule</AlertTitle>
                {`This schedule applies to a ${
                  deployment.product.category
                } product and will effect ${
                  computeResources.length
                } CAS Connectors directly, ${computeResources
                  .map((cr) => {
                    return cr.name || cr.physicalId;
                  })
                  .join(' and ')}`}
              </Alert>
            </Box>
          )}
          {resource && (
            <ResourceSchedule
              resourceId={resource.id}
              resourceName={resource?.name || deployment.product.category}
              projectState={resource?.deployment?.project.schedule.states}
              resourceState={resource?.schedule?.states}
              handleSave={handleResourceScheduleSave}
            />
          )}
        </>
      </Grid>
    </Container>
  );
}

export default ResourceSchedulePage;
