import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProjectScheduleMutationVariables = Types.Exact<{
  projectId: Types.Scalars['Int'];
  states: Types.StatesInput;
  utcOffset: Types.Scalars['Int'];
  timezone?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateProjectScheduleMutation = { __typename?: 'Mutation', updateProjectSchedule?: { __typename?: 'Schedule', utcOffset?: number | null, timezone?: string | null, states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null };


export const UpdateProjectScheduleDocument = gql`
    mutation UpdateProjectSchedule($projectId: Int!, $states: StatesInput!, $utcOffset: Int!, $timezone: String) {
  updateProjectSchedule(
    projectId: $projectId
    states: $states
    utcOffset: $utcOffset
    timezone: $timezone
  ) {
    states {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
    utcOffset
    timezone
  }
}
    `;

export function useUpdateProjectScheduleMutation() {
  return Urql.useMutation<UpdateProjectScheduleMutation, UpdateProjectScheduleMutationVariables>(UpdateProjectScheduleDocument);
};