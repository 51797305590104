import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import store from 'Model';
import '@fontsource/saira';
import '@fontsource/saira-extra-condensed';
import { checkCookie } from 'utils/credentials';
import App from './App';

const config = {
  url: process.env.REACT_APP_APIURL,
  region: 'eu-west-1',
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: checkCookie(),
  },
  disableOffline: true,
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    // @ts-ignore
    createAuthLink(config),
    // @ts-ignore
    createSubscriptionHandshakeLink(config),
  ]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

export { client };
