import { useState, useEffect } from 'react';
import * as yup from 'yup';
import isEmpty from 'lodash.isempty';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { Form, TextField, FormFields } from 'components/Common/Forms';
import ProjectSchedule from 'components/v2/Schedule/project';
import { PATH } from 'Views/Project';
import { useCreateProjectMutation } from 'graphql/createProject.generated';
import { useCreateProjectScheduleMutation } from 'graphql/createProjectSchedule.generated';
import { useDeleteProjectMutation } from 'graphql/deleteProject.generated';
import { StatesInput } from 'graphql/types.generated';
import { slugify } from 'utils/slugify';
import { Container, MainContent } from './CreateProjectWizard.styled';

/**
 * Form Validation Schema
 */
type FormInputs = {
  name: string;
  shortName: string;
  slugShortName: string;
};

const schema = yup.object().shape({
  name: yup.string().required('You must enter a project name'),
  shortName: yup.string().required('You must a project short name'),
  slugShortName: yup.string(),
});

const CreateProjectWizard = ({ tenantId }: { tenantId: string }) => {
  const history = useHistory();

  const [, createProject] = useCreateProjectMutation();
  const [, createProjectSchedule] = useCreateProjectScheduleMutation();
  const [, deleteProject] = useDeleteProjectMutation();

  const [projectScheduleMatrix, setProjectScheduleMatrix] =
    useState<StatesInput | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [slugError, setSlugError] = useState<string | null>(null);

  const [projectUtcOffset, setProjectUtcOffset] = useState<{
    utcOffset: number;
    timezone: string;
  }>({
    utcOffset: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const { control, formState, handleSubmit, reset, watch, setValue } =
    useForm<FormInputs>({
      mode: 'onChange',
      defaultValues: {
        name: '',
      },
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  /**
   *
   * Auto create short name slug
   */

  const shortName = watch('shortName');

  useEffect(() => {
    if (shortName) {
      const maxSlugLength = 15;
      const slug = slugify(shortName);
      if (slug.length > maxSlugLength) {
        setSlugError(
          `Short name must be less than ${maxSlugLength} characters`
        );
      } else {
        setSlugError(null);
        setValue('slugShortName', slug);
      }
    }
  }, [shortName, setValue]);

  const onSubmit = async (values: FormInputs) => {
    try {
      setLoading(true);

      if (!tenantId) return;

      /**
       *
       * Create project
       */

      const { data, error: createProjectMutationError } = await createProject({
        project: {
          tenantId,
          name: values.name,
          shortName: values.slugShortName,
        },
      });

      if (createProjectMutationError) {
        setLoading(false);
        toast.error('Failed to create project', {
          duration: 4000,
          position: 'top-center',
        });
        return;
      }

      const createdProject = data.createProject;

      /**
       *
       * Create schedule
       */

      const { error: scheduleMutationError } = await createProjectSchedule({
        projectId: Number(createdProject.id),
        states: projectScheduleMatrix,
        utcOffset: projectUtcOffset.utcOffset,
        timezone: projectUtcOffset.timezone,
      });

      if (scheduleMutationError) {
        await deleteProject({
          projectId: Number(createdProject.id),
        });

        setLoading(false);
        reset({ name: '' });
        toast.error(
          'Failed to create the default project schedule. Please try again',
          {
            duration: 4000,
            position: 'top-center',
          }
        );

        return;
      }

      setLoading(false);
      reset({ name: '' });

      toast.success('Project created', {
        duration: 4000,
        position: 'top-center',
      });

      return history.push(PATH.DASHBOARD);
    } catch (e) {
      // @ts-ignore
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form
        name="createProjectForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <MainContent>
          <h3>Create a new project</h3>
          <FormFields>
            <Box pt={2} mb={1}>
              <TextField
                name="name"
                control={control}
                label="Project Name"
                type="text"
                error={!!errors.name}
                errorMessage={errors?.name?.message}
                required
                fullWidth
              />
              <TextField
                name="shortName"
                control={control}
                label="Project short name"
                type="text"
                error={!!errors.shortName}
                errorMessage={errors?.shortName?.message}
                required
                fullWidth
              />
              <TextField
                id="slug-short-name"
                name="slugShortName"
                control={control}
                label="Displayed as"
                type="text"
                error={!!errors.slugShortName}
                errorMessage={errors?.slugShortName?.message}
                disabled
                fullWidth
              />
            </Box>
          </FormFields>
          <Box>
            <h3>Schedule</h3>
            <ProjectSchedule
              handleScheduleChange={setProjectScheduleMatrix}
              showSaveButton={false}
              handleUtcOffsetChange={setProjectUtcOffset}
              utcOffset={projectUtcOffset.utcOffset}
              timezone={projectUtcOffset.timezone}
            />
          </Box>
          <div>{error && <span>{error}</span>}</div>
          <Box
            mt={4}
            pt={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid #eee',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              aria-label="CREATE PROJECT"
              disabled={isEmpty(dirtyFields) || !isValid || loading}
              type="submit"
            >
              {loading ? 'Creating... ' : 'Create Project'}
            </Button>
          </Box>
        </MainContent>
      </Form>
    </Container>
  );
};

export default CreateProjectWizard;
