import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateMemberMutationVariables = Types.Exact<{
  member: Types.MemberInput;
}>;


export type UpdateMemberMutation = { __typename?: 'Mutation', updateMember?: { __typename?: 'Member', id: string, email: string, givenName: string, familyName: string, phoneNumber: string } | null };


export const UpdateMemberDocument = gql`
    mutation UpdateMember($member: MemberInput!) {
  updateMember(member: $member) {
    id
    email
    givenName
    familyName
    phoneNumber
  }
}
    `;

export function useUpdateMemberMutation() {
  return Urql.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument);
};