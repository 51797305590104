import { createTheme } from '@mui/material/styles';

const colorPalette = {
  info: {
    main: '#fff',
  },
  primary: {
    main: '#2b3338',
  },
  secondary: {
    main: '#d70a2c',
  },
  error: {
    main: '#d70a2c',
  },
  success: {
    main: 'rgb(2, 200, 20)',
    contrastText: '#fff',
  },
  information: {
    main: '#0a2cd7',
    contrastText: '#fff',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: [
      'Saira',
      'Saira Extra Condensed',
      'Roboto',
      '"Helvetica"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: colorPalette,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0px',
          border: '1px solid #2b3338',
          color: '#2b3338',
          '&:hover': {
            border: '1px solid #2b3338',
            background: '#2b3338',
            color: 'white',
          },
        },
        textPrimary: {
          color: '#2b3338',
          background: 'transparent',
          border: '1px solid transparent',
          '&:hover': {
            border: '1px solid transparent',
            background: 'transparent',
          },
        },
        contained: {
          color: 'white',
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
        outlinedSecondary: {
          border: `1px solid ${colorPalette.error.main}`,
          color: `${colorPalette.error.main}`,
          '&:hover': {
            border: `1px solid ${colorPalette.error.main}`,
            background: `${colorPalette.error.main}`,
            color: 'white',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          padding: '2rem 1rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #eee',
          borderRadius: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
        },
        rounded: {
          borderRadius: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:before, &:after': {
            display: 'none',
          },
        },
      },
    },
  },
});

export default theme;
