import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ProjectPermissionable from 'components/Common/Permissions/ProjectPermissionable';
import { ROLE_SENIORITIES } from 'utils/permissions';
import { projectSettingsNavigationConfig, NavItem } from './config';
import { ContextButton } from './ProjectSettingsMenu.styled';

function ProjectSettingsUserMenu({ projectId }: { projectId: string }) {
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event: any) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <ContextButton onClick={userMenuClick}>
        <Icon style={{ color: '#fff' }}>settings</Icon>
      </ContextButton>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <>
          {projectSettingsNavigationConfig.map((navItem: NavItem) => (
            <ProjectPermissionable
              key={navItem.id}
              minSeniority={ROLE_SENIORITIES.ALL_USERS}
            >
              <MenuItem
                component={Link}
                to={navItem.url}
                onClick={userMenuClose}
                role="button"
              >
                <ListItemIcon>
                  <Icon>{navItem.icon}</Icon>
                </ListItemIcon>
                <Box>
                  <ListItemText primary={navItem.title} />
                </Box>
              </MenuItem>
            </ProjectPermissionable>
          ))}
        </>
      </Popover>
    </>
  );
}

export default ProjectSettingsUserMenu;
