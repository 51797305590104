import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  border: 1px solid #ccd1d4;
  cursor: pointer;
`;

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    transition: box-shadow 0.25s ease;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
  }
`;

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
`;

export const ProjectImage = styled.img`
  max-width: 164px;
  max-height: 80px;
`;

export const ProjectName = styled(Typography)`
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 8px !important;
`;

export const ProjectDeployments = styled(Typography)`
  font-size: 16px !important;
  margin: 0;
  opacity: 0.64;
  font-weight: 600;
`;
