import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 762px;
  margin: 0 auto;
`;

export const MainContent = styled.div`
  margin: 0 auto;
  max-width: 100%;
`;
