import { Product } from 'graphql/types.generated';
import Type from './Product.type';

export interface ProductState {
  products: Array<Product>;
  categorizeById: {
    teradici: Array<string>;
    workstation: Array<string>;
    editshare: Array<string>;
    transfer: Array<string>;
    activeDirectory: Array<string>;
  };
  userSelected: Product;
}

const initalState: ProductState = {
  products: [],
  categorizeById: {
    teradici: [],
    workstation: [],
    transfer: [],
    editshare: [],
    activeDirectory: [],
  },
  userSelected: null,
};

type Action = {
  type: Type;
  payload: {
    products: Array<Product>;
    categorizeById: {
      teradici: Array<string>;
      workstation: Array<string>;
      transfer: Array<string>;
      editshare: Array<string>;
      activeDirectory: Array<string>;
      userSelected: Product;
    };
  };
};

export default function ProductReducer(
  state: ProductState = initalState,
  action: Action
): ProductState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_ALL_PRODUCTS: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
}
