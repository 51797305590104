import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ListProjectsQueryVariables = Types.Exact<{
  tenantId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ListProjectsQuery = { __typename?: 'Query', listProjects?: Array<{ __typename?: 'Project', id: string, name: string, imageUrl?: string | null, tenant: { __typename?: 'Tenant', id: string, name: string }, deployments?: Array<{ __typename?: 'Deployment', id: string } | null> | null } | null> | null };


export const ListProjectsDocument = gql`
    query ListProjects($tenantId: String) {
  listProjects(tenantId: $tenantId) {
    id
    name
    imageUrl
    tenant {
      id
      name
    }
    deployments {
      id
    }
  }
}
    `;

export function useListProjectsQuery(options?: Omit<Urql.UseQueryArgs<ListProjectsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListProjectsQuery, ListProjectsQueryVariables>({ query: ListProjectsDocument, ...options });
};