import Type from './Provision.type';

export const createNew = ({
  productId,
  projectId,
  deploymentName,
}: {
  productId: number;
  projectId: number;
  deploymentName: string;
}) => {
  return {
    type: Type.CREATE_NEW,
    payload: { productId, deploymentName, projectId },
  };
};

export const createNew__success = ({
  deploymentId,
  deploymentName,
}: {
  deploymentId: string;
  deploymentName: string;
}) => {
  return {
    type: Type.CREATE_NEW__SUCCESS,
    payload: { deploymentId, deploymentName },
  };
};

export const teardown = ({ deploymentId }: { deploymentId: string }) => {
  return {
    type: Type.TEAR_DOWN,
    payload: { deploymentId },
  };
};

export const handleProvisionSuccess = ({
  deploymentId,
  message,
  deployment,
}: {
  deploymentId: string;
  message: string;
  deployment: any;
}) => {
  return {
    type: Type.HANDLE_SUCCESS,
    payload: { deploymentId, message, deployment },
  };
};

export const handleProvisionFailure = ({
  deploymentId,
  message,
}: {
  deploymentId: string;
  message: string;
}) => {
  return {
    type: Type.HANDLE_FAILURE,
    payload: { deploymentId, message },
  };
};
