import { useState, useEffect } from 'react';
import { Select } from 'components/Common/Forms';
import { useListProjectsQuery } from 'graphql/listProjects.generated';
import { Project } from 'graphql/types.generated';

interface Props {
  tenantId: string;
  control: any;
  error: boolean;
  errorMessage: string;
}

type SelectOption = {
  label: string;
  value: string | number;
};

const ProjectSelect = ({ tenantId, control, error, errorMessage }: Props) => {
  const [projectOptions, setProjectOptions] = useState<SelectOption[]>([]);

  const [{ data, error: queryError }] = useListProjectsQuery({
    variables: { tenantId },
  });

  useEffect(() => {
    if (data && data.listProjects) {
      const options = data?.listProjects?.map((project: Project) => ({
        label: project?.name,
        value: project?.id,
      }));

      setProjectOptions(options);
    }
  }, [data]);

  return (
    <Select
      options={projectOptions}
      name="project"
      control={control}
      error={error || !queryError}
      errorMessage={errorMessage}
      label="Project"
      native
    />
  );
};

export default ProjectSelect;
