import {
  ProjectNotification,
  MemberNotification,
} from 'graphql/types.generated';
import Type from 'Controller/Notification/Notification.type';

export interface NotificationState {
  projectNotifications: Array<ProjectNotification>;
  memberNotifications: Array<MemberNotification>;
}

const initalState: NotificationState = {
  projectNotifications: [],
  memberNotifications: [],
};

type Action = {
  type: Type;
  payload: {
    projectNotification: ProjectNotification;
    memberNotification: MemberNotification;
  };
};

export default function NotificationReducer(
  state: NotificationState = initalState,
  action: Action
): NotificationState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.ADD_PROJECT_NOTIFICATION: {
      const { projectNotification } = payload;
      return {
        ...state,
        projectNotifications: [
          ...state.projectNotifications,
          projectNotification,
        ],
      };
    }

    case Type.ADD_MEMBER_NOTIFICATION: {
      const { memberNotification } = payload;
      return {
        ...state,
        memberNotifications: [...state.memberNotifications, memberNotification],
      };
    }

    default:
      return state;
  }
}
