import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  id?: string;
  name: string;
  control: any;
  error?: boolean;
  errorMessage?: string;
  label: string;
  value: string;
  defaultChecked?: boolean;
}

const Label = styled<any>('div')`
  margin-bottom: 4px;

  > span {
    color ${({ error }) => (error ? 'red' : 'inherit')}
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const ErrorMessage = styled<any>('div')`
  margin-top: 8px;

  span {
    color ${({ error }) => (error ? 'red' : 'inherit')}
    font-size: 0.9rem;
  }
`;

const CheckboxField = ({
  id,
  name,
  control,
  error,
  errorMessage,
  label,
  value,
  defaultChecked = true,
}: Props) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox {...field} defaultChecked={defaultChecked} value={value} />
        )}
      />
      {label && (
        <Label error={error}>
          <span>{label}</span>
        </Label>
      )}
      {errorMessage && (
        <ErrorMessage error={error}>
          <span>{errorMessage}</span>
        </ErrorMessage>
      )}
    </>
  );
};

export default CheckboxField;
