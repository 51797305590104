import { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import { useRedux } from 'Hook';
import { IMAGE_PATHS } from '../../../constants';
import { ContextButton, UserDetails, UserDisplayName } from './UserMenu.styled';

function UserMenu() {
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event: any) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const {
    store: { member },
  } = useRedux();

  return (
    <>
      <ContextButton onClick={userMenuClick}>
        <UserDetails>
          <UserDisplayName>
            {member?.thisSessionMember?.givenName || ''}
          </UserDisplayName>
        </UserDetails>
        <Avatar alt="user photo" src={IMAGE_PATHS.AVATAR_BACKGROUND} />
      </ContextButton>
      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      />
    </>
  );
}

export default UserMenu;
