import { MouseEvent } from 'react';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

type Order = 'asc' | 'desc';

function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
  expandable,
  connectable,
  schedulable,
}: {
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  expandable: boolean;
  connectable: boolean;
  schedulable: boolean;
}) {
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {expandable && (
          <TableCell align="left" padding="normal" sortDirection={false} />
        )}
        <TableCell
          align="left"
          padding="normal"
          sortDirection={orderBy === 'name' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={createSortHandler('name')}
          >
            Name
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          sortDirection={orderBy === 'description' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'description'}
            direction={orderBy === 'description' ? order : 'asc'}
            onClick={createSortHandler('description')}
          >
            Description
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          sortDirection={orderBy === 'status' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'status'}
            direction={orderBy === 'status' ? order : 'asc'}
            onClick={createSortHandler('status')}
          >
            Status
          </TableSortLabel>
        </TableCell>
        {schedulable && (
          <TableCell
            align="left"
            padding="normal"
            sortDirection={orderBy === 'schedule' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'schedule'}
              direction={orderBy === 'schedule' ? order : 'asc'}
              onClick={createSortHandler('schedule')}
            >
              Schedule
            </TableSortLabel>
          </TableCell>
        )}
        {connectable && (
          <TableCell
            align="left"
            padding="normal"
            sortDirection={orderBy === 'session' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'session'}
              direction={orderBy === 'session' ? order : 'asc'}
              onClick={createSortHandler('session')}
            >
              Session
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell align="left" padding="normal">
          <TableSortLabel>Actions</TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
