import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useRedux } from 'Hook';
import { PATH as PROJECT_PATH } from 'Views/Project';
import { Container } from './Settings.styled';

function TenantSettings() {
  const {
    store: {
      tenant: { tenants, userSelected },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ textDecoration: 'none' }}
              color="inherit"
              to={PROJECT_PATH.DASHBOARD}
            >
              {thisTenant.name}
            </Link>
            <Typography>Admin</Typography>
            <Typography>Settings</Typography>
          </Breadcrumbs>
          <Box>
            <h2>Settings</h2>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TenantSettings;
