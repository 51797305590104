import React, { useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Typography,
  AlertTitle,
  Button,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { States, HandleSaveResourceSchedule } from './types';
import {
  createSchedule,
  createMatrix,
  MassSelectionButtonActions,
} from './common';

import {
  ScheduleUI,
  ScheduleGridUI,
  GridOverlayUI,
  ButtonActions,
  XAxisHourLabelsUI,
  HourLabelUI,
  Legend,
  LegendElement,
  Toolbar,
} from './styles';

const ResourceSchedule = ({
  projectState,
  resourceState,
  resourceId,
  resourceName,
  handleSave,
}: {
  projectState: States;
  resourceState?: States;
  resourceId: string;
  resourceName: string;
  handleSave: HandleSaveResourceSchedule;
}) => {
  /**
   *
   * Clean input that comes from the gql api
   */
  if (projectState.__typename) {
    delete projectState.__typename;
  }

  if (resourceState?.__typename) {
    delete resourceState.__typename;
  }

  const _initialResourceState = resourceState
    ? { ...resourceState }
    : createSchedule(0);

  const [_resourceState, setResourceState] = useState(_initialResourceState);

  /**
   *
   * Dynamically create the info box text
   */

  const [confirmChangesAreUnderstood, setConfirmChangesAreUnderstood] =
    useState(false);
  const [weeklyUptimePerc, setWeeklyUptimePerc] = useState(0);
  const [projectHourlyDiff, setProjectHourlyDiff] = useState(0);
  const [projectHourlyDiffPerc, setProjectHourlyDiffPerc] = useState(0);
  const [resourceMatrixHasChanges, setResourceMatrixHasChanges] =
    useState(false);

  useEffect(() => {
    const totalHours = 168;

    const projectTime = {
      running: 0,
      stopped: 0,
    };

    projectTime.running = Object.values(projectState).reduce((acc, cur) => {
      const hoursRunning = cur.filter((c) => parseInt(c, 10) === 1);
      return parseInt(acc, 10) + parseInt(hoursRunning.length, 10);
    }, 0) as number;

    projectTime.stopped = totalHours - projectTime.running;

    const resourceTime = Object.values(_resourceState).reduce(
      (acc, cur) => {
        const hoursRunning = cur.filter((c) => parseInt(c, 10) === 1);
        const hoursStopped = cur.filter((c) => parseInt(c, 10) === -1);
        return {
          running: acc.running + hoursRunning.length,
          stopped: acc.stopped + hoursStopped.length,
        };
      },
      { running: 0, stopped: 0 }
    );

    let _weeklyRunning = 0;

    if (resourceTime.running === totalHours) {
      _weeklyRunning = 100;
    } else if (resourceTime.stopped === totalHours) {
      _weeklyRunning = 0;
    } else {
      _weeklyRunning = Math.floor(
        ((projectTime.running + (resourceTime.running - resourceTime.stopped)) /
          totalHours) *
          100
      );
    }

    const _projectHourlyDiff = resourceTime.running - resourceTime.stopped;

    const _projectHourlyDiffPerc =
      ((resourceTime.running - resourceTime.stopped) / projectTime.running) *
      100;

    if (resourceTime.running !== 0 || resourceTime.stopped !== 0) {
      setResourceMatrixHasChanges(true);
    }

    setProjectHourlyDiff(_projectHourlyDiff);
    setProjectHourlyDiffPerc(_projectHourlyDiffPerc);
    setWeeklyUptimePerc(_weeklyRunning);
  }, [_resourceState]);

  const handleHourClick = ({ dayName, hourIndex, state }) => {
    const thisBlocksProjectState = projectState[dayName][hourIndex];
    let thisBlocksResourceStateShouldBe = thisBlocksProjectState === 0 ? 1 : -1;

    if (state === thisBlocksResourceStateShouldBe) {
      // toggle back
      thisBlocksResourceStateShouldBe = 0;
    }

    const newResourceState = { ..._resourceState };
    newResourceState[dayName][hourIndex] = thisBlocksResourceStateShouldBe;
    setResourceState(newResourceState);
  };

  /**
   *
   * Create 2 matrix
   */

  const projectScheduleMatrix = createMatrix({
    states: projectState,
    type: 'project',
    handleClick: handleHourClick,
    eventsEnabled: false,
  });

  const resourceScheduleMatrix = createMatrix({
    states: _resourceState,
    type: 'resource',
    handleClick: handleHourClick,
    eventsEnabled: true,
  });

  const xAxisHourLabels = (
    <XAxisHourLabelsUI>
      {new Array(24).fill(0).map((el, i) => {
        return (
          <HourLabelUI key={`x-axis-label-${i}`}>
            <Typography>{i}</Typography>
          </HourLabelUI>
        );
      })}
    </XAxisHourLabelsUI>
  );

  /**
   *
   * Render
   */

  return (
    <ScheduleUI>
      <Toolbar>
        <Legend>
          <LegendElement className="--on">
            <Typography>Running</Typography>
          </LegendElement>
          <LegendElement className="--off">
            <Typography>Stopped</Typography>
          </LegendElement>
        </Legend>
        <ButtonActions>
          <MassSelectionButtonActions
            setAlwaysOn={() => setResourceState(createSchedule(1))}
            setAlwaysOff={() => setResourceState(createSchedule(-1))}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setResourceState(createSchedule(0))}
          >
            <ReplayIcon />
            Undo
          </Button>
        </ButtonActions>
      </Toolbar>
      <GridOverlayUI>
        <ScheduleGridUI>{projectScheduleMatrix}</ScheduleGridUI>
        <ScheduleGridUI>
          {xAxisHourLabels}
          {resourceScheduleMatrix}
        </ScheduleGridUI>
      </GridOverlayUI>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert variant="outlined" severity="info">
          <Typography>
            This computer is running for
            <strong> {`${weeklyUptimePerc}%`}</strong> of the week. This gives
            you a cost saving of
            <strong>{` ${100 - weeklyUptimePerc}% `}</strong> compared with a
            schedule which is always on.
          </Typography>
        </Alert>
        {resourceMatrixHasChanges && (
          <Alert variant="outlined" severity="error">
            <AlertTitle>Overide the project default</AlertTitle>
            Changes made to this schedule will override the default project
            schedule and effect only this computer. You have{' '}
            <strong>
              {projectHourlyDiff < 0 ? 'decreased' : 'increased'}{' '}
            </strong>
            this computers running time by{' '}
            <strong>{Math.abs(projectHourlyDiff)} hours </strong> or
            <strong> {projectHourlyDiffPerc.toFixed(1)}%</strong> of the default
            project schedule.
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmChangesAreUnderstood}
                  onChange={(e) =>
                    setConfirmChangesAreUnderstood(e.target.checked)
                  }
                />
              }
              label="I understand these useage changes will effect the billing total"
            />
          </Alert>
        )}
      </Stack>
      <ButtonActions>
        {confirmChangesAreUnderstood && (
          <Button
            type="submit"
            variant="outlined"
            onClick={() => handleSave({ resourceId, states: _resourceState })}
          >
            <SaveOutlinedIcon />
            {`Save schedule for ${resourceName}`}
          </Button>
        )}
      </ButtonActions>
    </ScheduleUI>
  );
};

export default ResourceSchedule;
