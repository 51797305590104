import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRedux } from 'Hook';
import { iState } from 'Model';
import { useGetProjectQuery } from 'graphql/getProject.generated';
import { PendingDeployment } from 'components/Project/ProjectDeploymentsTables/components/Notifications';
import Table from '../components/Table';

const ProjectWorkstationsTable = () => {
  const [memoPendingDeployments, setMemoPendingDeployments] = useState(0);

  const {
    workstations = [],
    project,
    teradiciExistsInProject,
  } = useSelector((store: iState) => {
    return {
      project: store.project.project,
      teradiciExistsInProject: store.deployment.teradici.length > 0,
      workstations: store.deployment.deployments.filter((d) => {
        return store.deployment.workstation.includes(d.id);
      }),
    };
  });

  const { store } = useRedux();

  const [, reexecuteGetProjectQuery] = useGetProjectQuery({
    variables: { projectId: project.id },
  });

  const {
    deployment: { pendingDeployments },
  } = store;

  useEffect(() => {
    if (pendingDeployments.length !== memoPendingDeployments) {
      setMemoPendingDeployments(pendingDeployments.length);
      reexecuteGetProjectQuery();
    }
  }, [pendingDeployments]);

  return (
    <>
      {pendingDeployments.length > 0 && (
        <PendingDeployment count={pendingDeployments.length} />
      )}
      <Table
        projectId={project.id}
        deployments={workstations}
        expandable
        connectable={teradiciExistsInProject}
        schedulable
      />
    </>
  );
};

export default ProjectWorkstationsTable;
