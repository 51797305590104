import Type from './Project.type';

export const setProjects = ({ projects }: { projects: Array<any> }) => {
  return {
    type: Type.SET_PROJECTS,
    payload: { projects },
  };
};

export const setProject = ({ project }: { project: any }) => {
  return {
    type: Type.SET_PROJECT,
    payload: { project },
  };
};

export const selectProject = ({ project }: { project: any }) => {
  return {
    type: Type.SELECT_PROJECT,
    payload: { project },
  };
};

export const refetchCurrentProject = () => {
  return {
    type: Type.REFETCH,
    payload: {},
  };
};

export const setWorkstationEntitlements = () => {
  return {
    type: Type.SET_WORKSTATION_ENTITLEMENTS,
    payload: {},
  };
};
