import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import TenantSwitch from 'components/Tenant/TenantSwitch';
import TenantUserMenu from 'components/Tenant/TenantUserMenu';
import { PATH as TENANT_PATH } from 'Views/Tenant';
import {
  TenantBarHeader,
  TenantToolBar,
  TenantToolBarLeft,
  TenantToolBarRight,
  Logo,
} from './Header.styled';
import { IMAGE_PATHS } from '../../../constants';

const TenantHeader = () => {
  return (
    <TenantBarHeader id="tenant-header" color="default">
      <TenantToolBar>
        <TenantToolBarLeft>
          <Box mr={2}>
            <Link to={TENANT_PATH.DASHBOARD}>
              <Logo src={IMAGE_PATHS.LOGO_INVESE_SVG} alt="Tyrell Cloud Logo" />
            </Link>
          </Box>
          <TenantSwitch />
        </TenantToolBarLeft>
        <TenantToolBarRight>
          <TenantUserMenu />
        </TenantToolBarRight>
      </TenantToolBar>
    </TenantBarHeader>
  );
};

export default TenantHeader;
