export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddProjectMemberRoleInput = {
  memberId: Scalars['String'];
  projectId: Scalars['String'];
  roleId: Scalars['String'];
};

export type AddTenantMemberRoleInput = {
  memberId?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['String']>;
};

export type BillingAwsCosts = {
  __typename?: 'BillingAwsCosts';
  projects: Array<Maybe<BillingProject>>;
  tenant: BilllingTenant;
  workspace: BillingWorkspace;
};

export type BillingData = {
  __typename?: 'BillingData';
  awsCosts: Money;
  end: Scalars['String'];
  margin: Scalars['String'];
  payable: Money;
  start: Scalars['String'];
  tenantAccountId: Scalars['String'];
};

export type BillingDeployment = {
  __typename?: 'BillingDeployment';
  costsByUseType: CostsByUseType;
  deployedAt: Scalars['String'];
  deployedBy: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  productId: Scalars['Int'];
  productName: Scalars['String'];
  projectName: Scalars['String'];
  removed: Scalars['Boolean'];
  removedAt?: Maybe<Scalars['String']>;
  removedBy?: Maybe<Scalars['String']>;
  runningTotal?: Maybe<Scalars['Int']>;
  serviceName: Scalars['String'];
  stoppedTotal?: Maybe<Scalars['Int']>;
  tenantName: Scalars['String'];
  vendorName: Scalars['String'];
};

export type BillingPeriodInput = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export type BillingProject = {
  __typename?: 'BillingProject';
  costsByUseType: CostsByUseType;
  id: Scalars['Int'];
  name: Scalars['String'];
  services: BillingProjectProjectServices;
  tenantPerc: Scalars['String'];
};

export type BillingProjectProjectServices = {
  __typename?: 'BillingProjectProjectServices';
  capture: BillingService;
  connect: BillingService;
  store: BillingService;
  transfer: BillingService;
  workstation: BillingService;
};

export type BillingService = {
  __typename?: 'BillingService';
  costsByUseType?: Maybe<CostsByUseType>;
  deployments?: Maybe<Array<Maybe<BillingDeployment>>>;
};

export type BillingWorkspace = {
  __typename?: 'BillingWorkspace';
  costsByUseType: CostsByUseType;
  tenantPerc: Scalars['String'];
};

export type BilllingTenant = {
  __typename?: 'BilllingTenant';
  awsAccountId: Scalars['String'];
  costsByUseType: CostsByUseType;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type BlockProductItemConfiguration = ProductItemConfiguration & {
  __typename?: 'BlockProductItemConfiguration';
  availabilityZone?: Maybe<Scalars['String']>;
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  encryption?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  iops?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  throughPut?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  volumeType?: Maybe<BlockVolumeType>;
};

export enum BlockVolumeType {
  Gp2 = 'gp2',
  Gp3 = 'gp3',
  Io1 = 'io1',
  Io2 = 'io2',
  Sc1 = 'sc1',
  St1 = 'st1',
  Standard = 'standard'
}

export type ComputeProductItemConfiguration = ProductItemConfiguration & {
  __typename?: 'ComputeProductItemConfiguration';
  amiId?: Maybe<Scalars['String']>;
  amiName?: Maybe<Scalars['String']>;
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instanceType?: Maybe<Scalars['String']>;
  operatingSystem?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type CostsByUseType = {
  __typename?: 'CostsByUseType';
  directory: Scalars['Float'];
  egress: Scalars['Float'];
  gross: Scalars['Float'];
  ingress: Scalars['Float'];
  loadBalancer: Scalars['Float'];
  miscellaneous: Scalars['Float'];
  net: Scalars['Float'];
  requests: Scalars['Float'];
  servers: Scalars['Float'];
  storage: Scalars['Float'];
  tax: Scalars['Float'];
  volumes: Scalars['Float'];
  vpc: Scalars['Float'];
};

export type CreateProductInput = {
  category: ProductCategory;
  description: Scalars['String'];
  meta?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serviceId: Scalars['String'];
  vendorId: Scalars['String'];
};

export type CreateProductItemInput = {
  category: ItemCategory;
  description: Scalars['String'];
  meta: Scalars['String'];
  name: Scalars['String'];
};

export type CreateServiceInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateTenantInput = {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  isDryRun: Scalars['Boolean'];
  newMember?: InputMaybe<MemberInput>;
  ownerId?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  tenantName: Scalars['String'];
  tenantShortName: Scalars['String'];
};

export type CreateTenantResponse = {
  __typename?: 'CreateTenantResponse';
  awsAccountId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  status: Scalars['String'];
};

export type CreateVendorInput = {
  name?: InputMaybe<Scalars['String']>;
};

export enum DayOfWeek {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

export type DeleteProjectMemberRoleInput = {
  memberId: Scalars['String'];
  projectId: Scalars['String'];
};

export type DeleteTenantMemberRoleInput = {
  memberId?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['String']>;
};

export type DeletedRes = {
  __typename?: 'DeletedRes';
  assignedAt: Scalars['String'];
  member: Member;
  project?: Maybe<Project>;
  role: Role;
  tenant?: Maybe<Tenant>;
};

export type Deployment = {
  __typename?: 'Deployment';
  deployedAt: Scalars['String'];
  deployedBy: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  project: Project;
  removed: Scalars['Boolean'];
  removedAt?: Maybe<Scalars['String']>;
  removedBy?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<Maybe<Resource>>>;
};

export type DocumentIdentifier = {
  __typename?: 'DocumentIdentifier';
  CreatedDate?: Maybe<Scalars['String']>;
  DocumentFormat?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  DocumentVersion?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Owner?: Maybe<Scalars['String']>;
  PlatformTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  SchemaVersion?: Maybe<Scalars['String']>;
  TargetType?: Maybe<Scalars['String']>;
};

export type EditMemberRolesInput = {
  memberRoles?: InputMaybe<Array<InputMaybe<MemberRoleInput>>>;
  targetId: Scalars['String'];
  targetType: TargetType;
};

export type EditMemberRolesResponse = {
  __typename?: 'EditMemberRolesResponse';
  deleted?: Maybe<Array<Maybe<DeletedRes>>>;
  failedToCreateUser?: Maybe<Array<Maybe<FailedToCreateRes>>>;
  updated?: Maybe<Array<Maybe<UpdateRes>>>;
};

export type FailedToCreateRes = {
  __typename?: 'FailedToCreateRes';
  effect: Scalars['String'];
  memberId: Scalars['String'];
  roleId: Scalars['String'];
};

export type GeneralProductItemConfiguration = ProductItemConfiguration & {
  __typename?: 'GeneralProductItemConfiguration';
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  deployable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type GetDeploymentPrice = {
  deploymentId: Scalars['String'];
  period: BillingPeriodInput;
  tenantAccountId: Scalars['String'];
};

export type GetProjectPrice = {
  period: BillingPeriodInput;
  projectId: Scalars['Int'];
  tenantAccountId: Scalars['String'];
};

export type GetResourcePrice = {
  period: BillingPeriodInput;
  resourceId: Scalars['String'];
  tenantAccountId: Scalars['String'];
};

export type GetTenantBillingInput = {
  period: BillingPeriodInput;
  tenantAccountId: Scalars['String'];
};

export type GetTenantPrice = {
  period: BillingPeriodInput;
  tenantAccountId: Scalars['String'];
};

export type InstanceHours = {
  __typename?: 'InstanceHours';
  deploymentId: Scalars['String'];
  instanceId: Scalars['String'];
  name: Scalars['String'];
  runningTotal: Scalars['Int'];
  stoppedTotal: Scalars['Int'];
  uptime: Scalars['String'];
};

export enum InstanceState {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Terminated = 'TERMINATED'
}

export type InvitationRecord = {
  __typename?: 'InvitationRecord';
  edited?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  errorReason?: Maybe<Scalars['String']>;
  expires: Scalars['String'];
  id: Scalars['String'];
  invitedBy: InvitedBy;
  roleId: Scalars['Int'];
  sent: Scalars['String'];
  status: InvitationStatus;
};

export type InvitationRequest = {
  email: Scalars['String'];
  roleId: Scalars['Int'];
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Attempted = 'ATTEMPTED',
  Failed = 'FAILED',
  Resent = 'RESENT',
  Revoked = 'REVOKED',
  Sent = 'SENT'
}

export type InvitedBy = {
  __typename?: 'InvitedBy';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type Item = {
  __typename?: 'Item';
  category: ItemCategory;
  configuration?: Maybe<ProductItemConfiguration>;
  configurationId: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  meta?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum ItemCategory {
  Block = 'BLOCK',
  Compute = 'COMPUTE',
  General = 'GENERAL',
  License = 'LICENSE',
  Network = 'NETWORK',
  Object = 'OBJECT'
}

export type ItemInProduct = {
  __typename?: 'ItemInProduct';
  product: Product;
  productItem: Item;
  quantity: Scalars['Int'];
};

export enum LicenseDuration {
  Daily = 'DAILY',
  Lifetime = 'LIFETIME',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type LicenseProductItemConfiguration = ProductItemConfiguration & {
  __typename?: 'LicenseProductItemConfiguration';
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  duration?: Maybe<LicenseDuration>;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type ListTenantStacks = {
  __typename?: 'ListTenantStacks';
  stacks: Scalars['String'];
};

export type Member = {
  __typename?: 'Member';
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
  projectMemberRoles?: Maybe<Array<Maybe<ProjectMemberRole>>>;
  tenantMemberRoles?: Maybe<Array<Maybe<TenantMemberRole>>>;
  tenantOwnerships?: Maybe<Array<Maybe<Tenant>>>;
};

export type MemberInput = {
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type MemberNotification = {
  __typename?: 'MemberNotification';
  json?: Maybe<Scalars['String']>;
  level: Scalars['String'];
  memberId: Scalars['String'];
  message: Scalars['String'];
  source: Scalars['String'];
  time: Scalars['String'];
  title: Scalars['String'];
};

export type MemberRoleInput = {
  effect: MemberRoleInputEffect;
  member?: InputMaybe<MemberInput>;
  memberId?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
};

export enum MemberRoleInputEffect {
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addProjectMemberRole?: Maybe<Array<Maybe<ProjectMemberRole>>>;
  addTenantMemberRole?: Maybe<Array<Maybe<TenantMemberRole>>>;
  createActiveDirectoryAdmins?: Maybe<Scalars['String']>;
  createDeploymentViaStateMachine?: Maybe<StateMachineDeploymentResponse>;
  createProduct?: Maybe<Product>;
  createProductItem?: Maybe<Item>;
  createProject?: Maybe<Project>;
  createProjectSchedule?: Maybe<Schedule>;
  createResourceSchedule?: Maybe<Schedule>;
  createRole?: Maybe<Role>;
  createService?: Maybe<Service>;
  createTenant?: Maybe<CreateTenantResponse>;
  createVendor?: Maybe<Vendor>;
  deleteMember?: Maybe<Member>;
  deleteProduct?: Maybe<Product>;
  deleteProductItem?: Maybe<Item>;
  deleteProject?: Maybe<Project>;
  deleteProjectMemberRole?: Maybe<Array<Maybe<ProjectMemberRole>>>;
  deleteProjectSchedule?: Maybe<Schedule>;
  deleteResourceSchedule?: Maybe<Schedule>;
  deleteRole?: Maybe<Role>;
  deleteService?: Maybe<Service>;
  deleteTenant?: Maybe<Tenant>;
  deleteTenantMemberRole?: Maybe<Array<Maybe<TenantMemberRole>>>;
  deleteVendor?: Maybe<Vendor>;
  deployDirectoryInfrastructure?: Maybe<Scalars['String']>;
  deployTenantActiveDirectory?: Maybe<Scalars['String']>;
  deployTenantCertificateAuthority?: Maybe<Scalars['String']>;
  deployTenantCertificateStack?: Maybe<Scalars['String']>;
  editMemberRoles?: Maybe<EditMemberRolesResponse>;
  emitMemberNotification?: Maybe<MemberNotification>;
  emitProjectNotification?: Maybe<ProjectNotification>;
  inviteToProject?: Maybe<Array<Maybe<InvitationRecord>>>;
  publishProduct?: Maybe<Product>;
  removeItemInProduct?: Maybe<ItemInProduct>;
  renameDeployment?: Maybe<Deployment>;
  renameResource?: Maybe<Resource>;
  resendInvitation?: Maybe<InvitationRecord>;
  revokeInvitation?: Maybe<InvitationRecord>;
  runAutomation?: Maybe<Scalars['String']>;
  setProjectWorkstationEntitlements?: Maybe<ProjectEntitlementResponse>;
  startResources?: Maybe<Array<Maybe<Schedule>>>;
  stopResources?: Maybe<Array<Maybe<Schedule>>>;
  teardownDeploymentViaStateMachine?: Maybe<StateMachineDeploymentResponse>;
  unpublishProduct?: Maybe<Product>;
  updateCognitoAttributes?: Maybe<UpdatedCognitoAttrStatus>;
  updateMember?: Maybe<Member>;
  updateProductConfigurationBlock?: Maybe<ProductItemConfiguration>;
  updateProductConfigurationCompute?: Maybe<ProductItemConfiguration>;
  updateProductConfigurationGeneral?: Maybe<ProductItemConfiguration>;
  updateProductConfigurationLicense?: Maybe<ProductItemConfiguration>;
  updateProductConfigurationNetwork?: Maybe<ProductItemConfiguration>;
  updateProductConfigurationObject?: Maybe<ProductItemConfiguration>;
  updateProject?: Maybe<Project>;
  updateProjectSchedule?: Maybe<Schedule>;
  updateResourcePhysicalId?: Maybe<Resource>;
  updateResourceSchedule?: Maybe<Schedule>;
  updateRole?: Maybe<Role>;
  updateTenant?: Maybe<Tenant>;
  updateVendor?: Maybe<Vendor>;
  upsertItemInProduct?: Maybe<ItemInProduct>;
  upsertProduct?: Maybe<Product>;
  upsertProductItem?: Maybe<Item>;
  upsertService?: Maybe<Service>;
};


export type MutationAddProjectMemberRoleArgs = {
  projectMemberRoles?: InputMaybe<Array<InputMaybe<AddProjectMemberRoleInput>>>;
};


export type MutationAddTenantMemberRoleArgs = {
  tenantMemberRoles?: InputMaybe<Array<InputMaybe<AddTenantMemberRoleInput>>>;
};


export type MutationCreateActiveDirectoryAdminsArgs = {
  tenantId: Scalars['Int'];
};


export type MutationCreateDeploymentViaStateMachineArgs = {
  deploymentName: Scalars['String'];
  productId: Scalars['Int'];
  projectId: Scalars['Int'];
};


export type MutationCreateProductArgs = {
  product: CreateProductInput;
};


export type MutationCreateProductItemArgs = {
  productItem?: InputMaybe<CreateProductItemInput>;
};


export type MutationCreateProjectArgs = {
  project?: InputMaybe<ProjectInput>;
};


export type MutationCreateProjectScheduleArgs = {
  projectId: Scalars['Int'];
  states: StatesInput;
  timezone?: InputMaybe<Scalars['String']>;
  utcOffset: Scalars['Int'];
};


export type MutationCreateResourceScheduleArgs = {
  resourceId: Scalars['String'];
  states: StatesInput;
};


export type MutationCreateRoleArgs = {
  role: RoleInput;
};


export type MutationCreateServiceArgs = {
  service: CreateServiceInput;
};


export type MutationCreateTenantArgs = {
  tenant: CreateTenantInput;
};


export type MutationCreateVendorArgs = {
  vendor: CreateVendorInput;
};


export type MutationDeleteMemberArgs = {
  memberId: Scalars['String'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['String'];
};


export type MutationDeleteProductItemArgs = {
  productItemId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['Int'];
};


export type MutationDeleteProjectMemberRoleArgs = {
  projectMemberRoles?: InputMaybe<Array<InputMaybe<DeleteProjectMemberRoleInput>>>;
};


export type MutationDeleteProjectScheduleArgs = {
  projectId: Scalars['Int'];
};


export type MutationDeleteResourceScheduleArgs = {
  resourceId: Scalars['String'];
};


export type MutationDeleteRoleArgs = {
  roleId: Scalars['String'];
};


export type MutationDeleteServiceArgs = {
  serviceId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTenantArgs = {
  tenantId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTenantMemberRoleArgs = {
  tenantMemberRoles?: InputMaybe<Array<InputMaybe<DeleteTenantMemberRoleInput>>>;
};


export type MutationDeleteVendorArgs = {
  vendorId: Scalars['String'];
};


export type MutationDeployDirectoryInfrastructureArgs = {
  tenantId: Scalars['Int'];
};


export type MutationDeployTenantActiveDirectoryArgs = {
  tenantId: Scalars['Int'];
};


export type MutationDeployTenantCertificateAuthorityArgs = {
  tenantId: Scalars['Int'];
};


export type MutationDeployTenantCertificateStackArgs = {
  tenantId: Scalars['Int'];
};


export type MutationEditMemberRolesArgs = {
  editMemberRoles: EditMemberRolesInput;
};


export type MutationEmitMemberNotificationArgs = {
  json?: InputMaybe<Scalars['String']>;
  level: Scalars['String'];
  memberId: Scalars['String'];
  message: Scalars['String'];
  source: Scalars['String'];
  time: Scalars['String'];
  title: Scalars['String'];
};


export type MutationEmitProjectNotificationArgs = {
  json?: InputMaybe<Scalars['String']>;
  level: Scalars['String'];
  message: Scalars['String'];
  projectId: Scalars['String'];
  source: Scalars['String'];
  time: Scalars['String'];
  title: Scalars['String'];
};


export type MutationInviteToProjectArgs = {
  invitations?: InputMaybe<Array<InputMaybe<InvitationRequest>>>;
  projectId: Scalars['Int'];
};


export type MutationPublishProductArgs = {
  productId: Scalars['String'];
};


export type MutationRemoveItemInProductArgs = {
  input: RemoveItemInProduct;
};


export type MutationRenameDeploymentArgs = {
  deploymentId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRenameResourceArgs = {
  name: Scalars['String'];
  resourceId: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  inviteId: Scalars['String'];
  projectId: Scalars['Int'];
};


export type MutationRevokeInvitationArgs = {
  inviteId: Scalars['String'];
  projectId: Scalars['Int'];
};


export type MutationRunAutomationArgs = {
  documentName: Scalars['String'];
  resourceIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationSetProjectWorkstationEntitlementsArgs = {
  projectId: Scalars['Int'];
};


export type MutationStartResourcesArgs = {
  hours: Scalars['Int'];
  resourceIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationStopResourcesArgs = {
  hours: Scalars['Int'];
  resourceIds: Array<InputMaybe<Scalars['String']>>;
};


export type MutationTeardownDeploymentViaStateMachineArgs = {
  deploymentId: Scalars['String'];
};


export type MutationUnpublishProductArgs = {
  productId: Scalars['String'];
};


export type MutationUpdateCognitoAttributesArgs = {
  attributes: UpdateCognitoAttrInput;
};


export type MutationUpdateMemberArgs = {
  member?: InputMaybe<MemberInput>;
};


export type MutationUpdateProductConfigurationBlockArgs = {
  id: Scalars['String'];
  productItemConfiguration: UpdateBlockProductItemConfigurationInput;
};


export type MutationUpdateProductConfigurationComputeArgs = {
  id: Scalars['String'];
  productItemConfiguration: UpdateComputeProductItemConfigurationInput;
};


export type MutationUpdateProductConfigurationGeneralArgs = {
  id: Scalars['String'];
  productItemConfiguration: UpdateGeneralProductItemConfigurationInput;
};


export type MutationUpdateProductConfigurationLicenseArgs = {
  id: Scalars['String'];
  productItemConfiguration: UpdateLicenseProductItemConfigurationInput;
};


export type MutationUpdateProductConfigurationNetworkArgs = {
  id: Scalars['String'];
  productItemConfiguration: UpdateNetworkProductItemConfigurationInput;
};


export type MutationUpdateProductConfigurationObjectArgs = {
  id: Scalars['String'];
  productItemConfiguration: UpdateObjectProductItemConfigurationInput;
};


export type MutationUpdateProjectArgs = {
  project?: InputMaybe<ProjectInput>;
};


export type MutationUpdateProjectScheduleArgs = {
  projectId: Scalars['Int'];
  states: StatesInput;
  timezone?: InputMaybe<Scalars['String']>;
  utcOffset: Scalars['Int'];
};


export type MutationUpdateResourcePhysicalIdArgs = {
  physicalId: Scalars['String'];
  resourceId: Scalars['String'];
};


export type MutationUpdateResourceScheduleArgs = {
  resourceId: Scalars['String'];
  states: StatesInput;
};


export type MutationUpdateRoleArgs = {
  role: RoleInput;
};


export type MutationUpdateTenantArgs = {
  tenant?: InputMaybe<TenantInput>;
};


export type MutationUpdateVendorArgs = {
  vendor: VendorInput;
};


export type MutationUpsertItemInProductArgs = {
  input: UpsertItemInProduct;
};


export type MutationUpsertProductArgs = {
  product: ProductInput;
};


export type MutationUpsertProductItemArgs = {
  productItem?: InputMaybe<ProductItemInput>;
};


export type MutationUpsertServiceArgs = {
  service: ServiceInput;
};

export type NetworkProductItemConfiguration = ProductItemConfiguration & {
  __typename?: 'NetworkProductItemConfiguration';
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  loadBalancerType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum NotificationLevel {
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

export type ObjectProductItemConfiguration = ProductItemConfiguration & {
  __typename?: 'ObjectProductItemConfiguration';
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  defaultEncryption?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  objectLock?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  storageClass?: Maybe<ObjectStorageClass>;
  updatedAt?: Maybe<Scalars['String']>;
  versioning?: Maybe<Scalars['Boolean']>;
};

export enum ObjectStorageClass {
  S3Standard = 'S3_STANDARD'
}

export type Product = {
  __typename?: 'Product';
  category: ProductCategory;
  description: Scalars['String'];
  id: Scalars['String'];
  items?: Maybe<Array<Maybe<ProductItem>>>;
  meta?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projects?: Maybe<Array<Maybe<Project>>>;
  publishedAt?: Maybe<Scalars['String']>;
  service: Service;
  vendor: Vendor;
};

export enum ProductCategory {
  ActiveDirectory = 'ACTIVE_DIRECTORY',
  Editshare = 'EDITSHARE',
  Teradici = 'TERADICI',
  Transfer = 'TRANSFER',
  Workstation = 'WORKSTATION'
}

export type ProductInput = {
  category: ProductCategory;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productItems?: InputMaybe<Array<InputMaybe<ProductItemsInput>>>;
  serviceId: Scalars['String'];
  vendorId: Scalars['String'];
};

export type ProductItem = {
  __typename?: 'ProductItem';
  productItem: Item;
  quantity: Scalars['Int'];
};

export type ProductItemConfiguration = {
  category?: Maybe<ItemCategory>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductItemInput = {
  category?: InputMaybe<ItemCategory>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductItemsInput = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Project = {
  __typename?: 'Project';
  createdAt: Scalars['String'];
  deployments?: Maybe<Array<Maybe<Deployment>>>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<ProjectProduct>>>;
  projectMemberRoles?: Maybe<Array<Maybe<ProjectMemberRole>>>;
  schedule?: Maybe<Schedule>;
  shortName: Scalars['String'];
  tenant: Tenant;
};

export type ProjectConnectors = {
  __typename?: 'ProjectConnectors';
  connectors?: Maybe<Array<Maybe<TeradiciConnector>>>;
  loadBalancerUrl: Scalars['String'];
};

export type ProjectEntitlementResponse = {
  __typename?: 'ProjectEntitlementResponse';
  addResponse?: Maybe<Array<Maybe<WorkstationEntitlement>>>;
  removeResponse?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ProjectInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  shortName: Scalars['String'];
  tenantId: Scalars['String'];
};

export type ProjectMemberRole = {
  __typename?: 'ProjectMemberRole';
  assignedAt: Scalars['String'];
  member: Member;
  project?: Maybe<Project>;
  role: Role;
};

export type ProjectNotification = {
  __typename?: 'ProjectNotification';
  json?: Maybe<Scalars['String']>;
  level: Scalars['String'];
  message: Scalars['String'];
  projectId: Scalars['String'];
  source: Scalars['String'];
  time: Scalars['String'];
  title: Scalars['String'];
};

export type ProjectProduct = {
  __typename?: 'ProjectProduct';
  addedBy: Member;
  assignedAt: Scalars['String'];
  product: Product;
  project?: Maybe<Project>;
  quantity: Scalars['Int'];
};

export type ProjectWorkstation = {
  __typename?: 'ProjectWorkstation';
  resource: Resource;
  session?: Maybe<WorkstationSession>;
};

export type Query = {
  __typename?: 'Query';
  getAwsBillingCost?: Maybe<BillingAwsCosts>;
  getDeploymentPrice?: Maybe<BillingData>;
  getMe?: Maybe<Member>;
  getMemberByEmail?: Maybe<Member>;
  getMemberById?: Maybe<Member>;
  getProduct?: Maybe<Product>;
  getProductItem?: Maybe<Item>;
  getProductItemConfiguration?: Maybe<ProductItemConfiguration>;
  getProject?: Maybe<Project>;
  getProjectPrice?: Maybe<BillingData>;
  getProjectTeradiciConnectors?: Maybe<ProjectConnectors>;
  getProjectWorkstations?: Maybe<Array<Maybe<ProjectWorkstation>>>;
  getReportRunningHours?: Maybe<Array<Maybe<InstanceHours>>>;
  getResource?: Maybe<Resource>;
  getResourceByPhysicalId?: Maybe<Resource>;
  getResourcePrice?: Maybe<BillingData>;
  getRole?: Maybe<Role>;
  getService?: Maybe<Service>;
  getTenant?: Maybe<Tenant>;
  getTenantBilling?: Maybe<BillingData>;
  getVendor?: Maybe<Vendor>;
  getWorkstationSessions?: Maybe<Array<Maybe<TeradiciSession>>>;
  listAutomations?: Maybe<Array<Maybe<DocumentIdentifier>>>;
  listInvitations?: Maybe<Array<Maybe<InvitationRecord>>>;
  listMembers?: Maybe<Array<Maybe<Member>>>;
  listProductItems?: Maybe<Array<Maybe<Item>>>;
  listProducts?: Maybe<Array<Maybe<Product>>>;
  listProjectWorkstations?: Maybe<Array<Maybe<Resource>>>;
  listProjects?: Maybe<Array<Maybe<Project>>>;
  listRoles?: Maybe<Array<Maybe<Role>>>;
  listServices?: Maybe<Array<Maybe<Service>>>;
  listTenantStacks?: Maybe<ListTenantStacks>;
  listTenants?: Maybe<Array<Maybe<Tenant>>>;
  listVendors?: Maybe<Array<Maybe<Vendor>>>;
};


export type QueryGetAwsBillingCostArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
  tenantId: Scalars['Int'];
};


export type QueryGetDeploymentPriceArgs = {
  input: GetDeploymentPrice;
};


export type QueryGetMemberByEmailArgs = {
  memberEmail: Scalars['String'];
};


export type QueryGetMemberByIdArgs = {
  memberId: Scalars['String'];
};


export type QueryGetProductArgs = {
  productId: Scalars['String'];
};


export type QueryGetProductItemArgs = {
  productItemId?: InputMaybe<Scalars['String']>;
};


export type QueryGetProductItemConfigurationArgs = {
  productItemConfigurationId: Scalars['String'];
};


export type QueryGetProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectPriceArgs = {
  input: GetProjectPrice;
};


export type QueryGetProjectTeradiciConnectorsArgs = {
  projectId: Scalars['Int'];
};


export type QueryGetProjectWorkstationsArgs = {
  projectId: Scalars['Int'];
};


export type QueryGetReportRunningHoursArgs = {
  month: Scalars['Int'];
  tenantId: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryGetResourceArgs = {
  resourceId: Scalars['String'];
};


export type QueryGetResourceByPhysicalIdArgs = {
  physicalId: Scalars['String'];
};


export type QueryGetResourcePriceArgs = {
  input: GetResourcePrice;
};


export type QueryGetRoleArgs = {
  roleId: Scalars['String'];
};


export type QueryGetServiceArgs = {
  serviceId: Scalars['String'];
};


export type QueryGetTenantArgs = {
  tenantId: Scalars['String'];
};


export type QueryGetTenantBillingArgs = {
  input: GetTenantBillingInput;
};


export type QueryGetVendorArgs = {
  vendorId: Scalars['String'];
};


export type QueryGetWorkstationSessionsArgs = {
  projectId: Scalars['Int'];
};


export type QueryListAutomationsArgs = {
  projectId: Scalars['Int'];
};


export type QueryListInvitationsArgs = {
  projectId: Scalars['Int'];
};


export type QueryListProjectWorkstationsArgs = {
  projectId: Scalars['Int'];
};


export type QueryListProjectsArgs = {
  tenantId?: InputMaybe<Scalars['String']>;
};


export type QueryListTenantStacksArgs = {
  tenantId: Scalars['Int'];
};

export type RemoveItemInProduct = {
  productId: Scalars['String'];
  productItemId: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  deployment: Deployment;
  id: Scalars['String'];
  name: Scalars['String'];
  physicalId?: Maybe<Scalars['String']>;
  productItem?: Maybe<Item>;
  schedule?: Maybe<Schedule>;
  state?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  name: Scalars['String'];
  projectMemberRoles?: Maybe<Array<Maybe<ProjectMemberRole>>>;
  seniority: Scalars['Int'];
  tenantMemberRoles?: Maybe<Array<Maybe<TenantMemberRole>>>;
};

export type RoleInput = {
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  seniority: Scalars['Int'];
};

export type Schedule = {
  __typename?: 'Schedule';
  states: ScheduleStates;
  timezone?: Maybe<Scalars['String']>;
  utcOffset?: Maybe<Scalars['Int']>;
};

export type ScheduleStates = {
  __typename?: 'ScheduleStates';
  friday: Array<Maybe<Scalars['Int']>>;
  monday: Array<Maybe<Scalars['Int']>>;
  saturday: Array<Maybe<Scalars['Int']>>;
  sunday: Array<Maybe<Scalars['Int']>>;
  thursday: Array<Maybe<Scalars['Int']>>;
  tuesday: Array<Maybe<Scalars['Int']>>;
  wednesday: Array<Maybe<Scalars['Int']>>;
};

export type Service = {
  __typename?: 'Service';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<Product>>>;
};

export type ServiceInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type StateMachineDeploymentResponse = {
  __typename?: 'StateMachineDeploymentResponse';
  deploymentId?: Maybe<Scalars['String']>;
};

export type StatesInput = {
  friday: Array<InputMaybe<Scalars['Int']>>;
  monday: Array<InputMaybe<Scalars['Int']>>;
  saturday: Array<InputMaybe<Scalars['Int']>>;
  sunday: Array<InputMaybe<Scalars['Int']>>;
  thursday: Array<InputMaybe<Scalars['Int']>>;
  tuesday: Array<InputMaybe<Scalars['Int']>>;
  wednesday: Array<InputMaybe<Scalars['Int']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onMemberNotifcation?: Maybe<MemberNotification>;
  onProjectNotifcation?: Maybe<ProjectNotification>;
};


export type SubscriptionOnMemberNotifcationArgs = {
  memberId: Scalars['String'];
};


export type SubscriptionOnProjectNotifcationArgs = {
  projectId: Scalars['String'];
};

export enum TargetState {
  Running = 'RUNNING',
  Stopped = 'STOPPED'
}

export enum TargetType {
  Project = 'PROJECT',
  Tenant = 'TENANT'
}

export type Tenant = {
  __typename?: 'Tenant';
  awsAccountId: Scalars['String'];
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  memberRoles?: Maybe<Array<Maybe<TenantMemberRole>>>;
  name: Scalars['String'];
  owner: Member;
  projects?: Maybe<Array<Maybe<Project>>>;
  status: Scalars['String'];
};

export type TenantInput = {
  awsAccountId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  status: Scalars['String'];
};

export type TenantMemberRole = {
  __typename?: 'TenantMemberRole';
  assignedAt: Scalars['String'];
  member: Member;
  role: Role;
  tenant?: Maybe<Tenant>;
};

export type TeradiciConnector = {
  __typename?: 'TeradiciConnector';
  healthStatus: Scalars['String'];
  publicIp: Scalars['String'];
  status: Scalars['String'];
};

export type TeradiciSession = {
  __typename?: 'TeradiciSession';
  clientSessionTime?: Maybe<Scalars['Int']>;
  deploymentId?: Maybe<Scalars['String']>;
  pcoipSessionState?: Maybe<Scalars['String']>;
  physicalId?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  sessionAttemptedOn?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  user?: Maybe<Member>;
};

export type UpdateBlockProductItemConfigurationInput = {
  availabilityZone?: InputMaybe<Scalars['String']>;
  encryption?: InputMaybe<Scalars['Boolean']>;
  iops?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  throughPut?: InputMaybe<Scalars['Int']>;
  volumeType?: InputMaybe<BlockVolumeType>;
};

export type UpdateCognitoAttrInput = {
  email: Scalars['String'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  phone_number: Scalars['String'];
  project: Scalars['String'];
  tenant: Scalars['String'];
};

export type UpdateComputeProductItemConfigurationInput = {
  amiId?: InputMaybe<Scalars['String']>;
  amiName?: InputMaybe<Scalars['String']>;
  instanceType?: InputMaybe<Scalars['String']>;
  operatingSystem?: InputMaybe<Scalars['String']>;
};

export type UpdateGeneralProductItemConfigurationInput = {
  deployable?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateLicenseProductItemConfigurationInput = {
  duration?: InputMaybe<LicenseDuration>;
};

export type UpdateNetworkProductItemConfigurationInput = {
  loadBalancerType?: InputMaybe<Scalars['String']>;
};

export type UpdateObjectProductItemConfigurationInput = {
  defaultEncryption?: InputMaybe<Scalars['Boolean']>;
  objectLock?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  storageClass?: InputMaybe<ObjectStorageClass>;
  versioning?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateProductItemConfigurationResponse = {
  __typename?: 'UpdateProductItemConfigurationResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type UpdateRes = {
  __typename?: 'UpdateRes';
  assignedAt: Scalars['String'];
  member: Member;
  project?: Maybe<Project>;
  role: Role;
  tenant?: Maybe<Tenant>;
};

export type UpdatedCognitoAttrStatus = {
  __typename?: 'UpdatedCognitoAttrStatus';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type UpsertItemInProduct = {
  productId: Scalars['String'];
  productItemId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['String'];
  name: Scalars['String'];
  products?: Maybe<Array<Maybe<Product>>>;
};

export type VendorInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type WorkstationEntitlement = {
  __typename?: 'WorkstationEntitlement';
  machineId?: Maybe<Scalars['String']>;
  userGuid?: Maybe<Scalars['String']>;
};

export type WorkstationSession = {
  __typename?: 'WorkstationSession';
  clientSessionTime?: Maybe<Scalars['Int']>;
  pcoipSessionState?: Maybe<Scalars['String']>;
  sessionAttemptedOn?: Maybe<Scalars['String']>;
  user?: Maybe<Member>;
};
