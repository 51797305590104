export enum ProjectNotificationSourceType {
  DEPLOYMENT_FAILED = 'project.provision.failed',
  UPDATE_RESOURCE_STATE = 'resource.state.update',
}

export enum MemberNotificationSourceType {
  ADDED_TO_PROJECT = 'member.added.project',
  REMOVED_FROM_PROJECT = 'member.removed.project',
  ADDED_TO_TENANT = 'member.added.tenant',
  REMOVED_FROM_TENANT = 'member.removed.tenant',
}

enum NotificationType {
  ADD_PROJECT_NOTIFICATION = '[NOTIFICATION] RECEIVED_PROJECT',
  ADD_MEMBER_NOTIFICATION = '[NOTIFICATION] RECEIVED_MEMBER',
}

export default NotificationType;
