import { Deployment } from 'graphql/types.generated';
import Type from './Deployment.type';

interface PendingDeployment {
  productId: string;
  productCategory: string;
  clientRequestId: string;
}

export interface DeploymentState {
  deployments: Array<Deployment>;
  userSelected: Deployment;
  teradici: Array<string>;
  editShare: Array<string>;
  workstation: Array<string>;
  transfer: Array<string>;
  capture: Array<string>;
  activeDirectory: Array<string>;
  resourceUpdateHistory: Array<{
    resourceId: string;
    state: string;
  }>;
  pendingDeployments: Array<PendingDeployment>;
}

const initalState: DeploymentState = {
  deployments: [],
  userSelected: null,
  teradici: [],
  editShare: [],
  workstation: [],
  transfer: [],
  capture: [],
  activeDirectory: [],
  resourceUpdateHistory: [],
  pendingDeployments: [],
};

type Action = {
  type: Type;
  payload: {
    deployments: Array<Deployment>;
    deploymentId: string;
    teradici: Array<string>;
    editShare: Array<string>;
    workstation: Array<string>;
    transfer: Array<string>;
    capture: Array<string>;
    activeDirectory: Array<string>;
    resourceId: string;
    state: string;
    pendingDeployments: Array<PendingDeployment>;
  };
};

export default function DeploymentReducer(
  state: DeploymentState = initalState,
  action: Action
): DeploymentState {
  const { type = '' } = action;

  switch (type) {
    case Type.SET_ALL_DEPLOYMENTS: {
      return { ...state, ...action.payload };
    }

    case Type.SELECT_DEPLOYMENT: {
      return { ...state, ...action.payload };
    }

    case Type.UPDATE_RESOURCE_STATE: {
      return {
        ...state,
        resourceUpdateHistory: [...state.resourceUpdateHistory, action.payload],
      };
    }

    case Type.ADD_PENDING: {
      return {
        ...state,
        pendingDeployments: action.payload.pendingDeployments,
      };
    }

    case Type.REMOVE_PENDING: {
      return {
        ...state,
        pendingDeployments: action.payload.pendingDeployments,
      };
    }

    default:
      return state;
  }
}
