import Grid from '@material-ui/core/Grid';
import ProjectUsersTable from 'components/Project/ProjectUsersTable';
import { useSelector } from 'react-redux';
import { iState } from 'Model';
import { Container } from '../Settings.styled';

function ProjectTeam() {
  const { project } = useSelector((store: iState) => {
    return store.project;
  });

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Project Team</h2>
      </Grid>
      <ProjectUsersTable />
    </Container>
  );
}

export default ProjectTeam;
