import { useRedux } from 'Hook';
import { InvitationRequest } from 'graphql/types.generated';
import OnboardingInvite from './components/invite';
import OnboardingList from './components/list';
import * as Style from './styles';

const Onboarding = () => {
  const {
    dispatch,
    store: {
      onboarding: { invitations: invitationList },
      project: {
        project: { id: _projectId, name },
      },
    },
    actions: {
      onboardingActions: { invite, list, resend, revoke },
    },
  } = useRedux();

  const projectId = parseInt(_projectId, 10);

  const handleSend = (invitations: Array<InvitationRequest>) =>
    dispatch(invite({ projectId, invitations }));

  const handleResend = (inviteId: string) =>
    dispatch(resend({ projectId, inviteId }));

  const handleRevoke = (inviteId: string) =>
    dispatch(revoke({ projectId, inviteId }));

  const handleRefresh = () => dispatch(list({ projectId }));

  return (
    <Style.OnboardingContainer>
      <p>Invite people to the {`${name}`} project</p>
      <OnboardingInvite handleSend={handleSend} />
      <OnboardingList
        invitations={invitationList}
        handleResend={handleResend}
        handleRevoke={handleRevoke}
        handleRefresh={handleRefresh}
      />
    </Style.OnboardingContainer>
  );
};

export default Onboarding;
