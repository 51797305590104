import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { alpha, styled as styledMaterial } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';

interface Props {
  id?: string;
  name: string;
  control?: any;
  error?: boolean;
  errorMessage?: string;
  label: string;
  options: Array<{ label: string; value: any }>;
  native?: boolean;
  loading?: boolean;
  disabled?: boolean;
  inline?: boolean;
}

const Label = styled<any>('div')`
  margin-bottom: 4px;

  > span {
    color ${({ error }) => (error ? 'red' : 'inherit')}
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const ErrorMessage = styled<any>('div')`
  margin-top: 8px;

  span {
    color ${({ error }) => (error ? 'red' : 'inherit')}
    font-size: 0.9rem;
  }
`;

const StyledInput = styledMaterial(InputBase)(({ theme, error }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: error ? '1px solid red' : '1px solid #bbc2c6',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const SelectField = ({
  name,
  control,
  error,
  errorMessage,
  label,
  options,
  native = true,
  loading,
  disabled = false,
  inline = false,
}: Props) => {
  return (
    <>
      {!inline && label && (
        <Label error={error}>
          <span>{label}</span>
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          native ? (
            <NativeSelect
              {...field}
              id={name}
              input={<StyledInput />}
              fullWidth={!inline}
              disabled={disabled}
            >
              {loading ? (
                <option>Loading...</option>
              ) : (
                <>
                  <option>{options?.length ? 'Select option' : '--'}</option>
                  {options?.map(
                    ({
                      label: optionLabel,
                      value,
                    }: {
                      label: string;
                      value: any;
                    }) => (
                      <option key={value} value={value}>
                        {optionLabel}
                      </option>
                    )
                  )}
                </>
              )}
            </NativeSelect>
          ) : (
            <Select
              {...field}
              id={name}
              input={<StyledInput />}
              fullWidth
              disabled={disabled}
            >
              {loading ? (
                <option>Loading...</option>
              ) : (
                <>
                  <option>{options?.length ? 'Select option' : '--'}</option>
                  {options?.map(
                    ({
                      label: optionLabel,
                      value,
                    }: {
                      label: string;
                      value: any;
                    }) => (
                      <MenuItem key={value} value={value}>
                        {optionLabel}
                      </MenuItem>
                    )
                  )}
                </>
              )}
            </Select>
          )
        }
      />
      {errorMessage && (
        <ErrorMessage error={error}>
          <span>{errorMessage}</span>
        </ErrorMessage>
      )}
    </>
  );
};

export default SelectField;
