import { PATH } from 'Views/Project';

const [domain, ext] = window.location.host.split('.');

const getConfig = (betaFeaturesEnabled: boolean) => [
  {
    id: 'workstations',
    title: 'Workstation',
    url: PATH.WORKSTATION,
    disabled: false,
    preview: false,
  },
  {
    id: 'store',
    title: 'Store',
    url: PATH.STORE,
    disabled: false,
    preview: false,
  },

  {
    id: 'transfer',
    title: 'Transfer',
    url: PATH.TRANSFER,
    // disabled: !betaFeaturesEnabled,
    // preview: !betaFeaturesEnabled,
    disabled: false,
    preview: false,
  },
  {
    id: 'connectstream',
    title: 'Connect Stream',
    url: PATH.CONNECT_STREAM,
    disabled: false,
    preview: false,
    outlink: `https://connectstream.${domain}.cloud`,
  },
  {
    id: 'infrastructure',
    title: 'Infrastructure',
    url: PATH.INFRASTRUCTURE,
    disabled: false,
    preview: false,
  },
];

export default getConfig;
