import { useState, useEffect } from 'react';
import { InvitationRecord } from 'graphql/types.generated';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {
  Table,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Paper,
  Chip,
  Stack,
} from '@mui/material';
import * as Style from '../styles';

type TableData = Array<{
  email: string;
  role: string;
  status: string;
  expires: string;
  id: string;
}>;

export const ROLE_NAME_BY_ID = {
  '2': 'Admin',
  '3': 'Ops',
  '4': 'User',
};

const OnboardingList = ({
  invitations,
  handleResend,
  handleRevoke,
  handleRefresh,
}: {
  invitations: Array<InvitationRecord>;
  handleResend: (inviteId: string) => void;
  handleRevoke: (inviteId: string) => void;
  handleRefresh: () => void;
}) => {
  const [tabledata, setTableData] = useState<TableData>([]);

  useEffect(() => {
    const _tableData = invitations.map((inv) => {
      const { email, roleId, status, expires, id, ...rest } = inv;
      const role = ROLE_NAME_BY_ID[String(roleId)];

      return {
        email,
        role,
        status: String(status),
        expires: new Date(expires).toLocaleString(),
        id,
      };
    });

    setTableData(_tableData);
  }, [invitations]);

  const getTagColor = (status: string) => {
    enum Colors {
      SENT = 'information',
      ATTEMPTED = 'warning',
      ACCEPTED = 'success',
      REVOKED = 'error',
      RESENT = 'information',
    }

    return Colors[status];
  };

  return (
    <Style.ListContainer>
      <h2>Sent</h2>
      <p>
        Invitations will expire within one hour and can be resent. Within 2 days
        any invite not resent or accepted will be deleted.
      </p>
      <Stack mb={2} direction="row" spacing={3}>
        <Button
          variant="outlined"
          onClick={() => handleRefresh()}
          startIcon={<RefreshOutlinedIcon />}
        >
          Refresh
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>Role</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                <strong>Expires</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tabledata.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    color={getTagColor(row.status)}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>{row.expires}</TableCell>
                <TableCell>
                  <>
                    <Tooltip title="Resend">
                      <IconButton disabled={row.status === 'ACCEPTED'}>
                        <SendOutlinedIcon
                          onClick={() => handleResend(row.id)}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Revoke">
                      <IconButton disabled={row.status === 'ACCEPTED'}>
                        <NotInterestedIcon
                          onClick={() => handleRevoke(row.id)}
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Style.ListContainer>
  );
};

export default OnboardingList;
