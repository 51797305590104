import { useSelector } from 'react-redux';
import { iState } from 'Model';
import LockIcon from '@mui/icons-material/Lock';

const ProjectPermissionable = ({
  minSeniority,
  children,
  fallbackComponent = null,
}: {
  minSeniority: number;
  fallbackComponent?: any | null;
  children: any;
}) => {
  const thisMembersProjectRole = useSelector((store: iState) => {
    const { projectMemberRoles } = store.member.thisSessionMember;

    const { project } = store.project;

    return projectMemberRoles.find((pmr) => {
      return pmr.project.id === project.id;
    });
  });

  const canUse = thisMembersProjectRole?.role?.seniority >= minSeniority;

  if (!canUse && !fallbackComponent) {
    return <LockIcon />;
  }

  return children;
};

export default ProjectPermissionable;
