import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export const ContextButton = styled(Button)`
  height: 100%;
`;

export const TenantListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TenantName = styled.span`
  font-weight: 700;
  font-size: 1rem !important;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`;

export const TenantLogoItem = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 8px;
`;
