import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import TenantDashboard from 'Views/Tenant/Dashboard';
import TenantMembers from 'Views/Tenant/Admin/Team';
import TenantInviteUsers from 'Views/Tenant/Admin/Team/Invite';
import TenantCreateProject from 'Views/Tenant/CreateNewProject';
import TenantTenantSettings from 'Views/Tenant/Admin/Settings';
import Account from 'Views/Account';
import Loading from 'components/Common/Loading';
import { useStore } from 'react-redux';
import { productActions } from 'Controller/actions';
import { useListProductsQuery } from 'graphql/listProducts.generated';
import { ProtectedRoute } from 'Views/routes';
import { ROLE_SENIORITIES } from '../../utils/permissions';

export const PATH = {
  DASHBOARD: '/tenant/dashboard',
  ACCOUNT: '/tenant/account',
  INVITE: '/tenant/invite',
  MEMBERS: '/tenant/members',
  SETTINGS: '/tenant/settings',
  NEW_PROJECT: '/tenant/new-project',
};

const TenantRouter = () => {
  const { dispatch } = useStore();

  /**
   *
   * Get all Products
   */

  const [
    {
      data: allProducts,
      fetching: allProductsLoading,
      error: allProductsError,
    },
  ] = useListProductsQuery();

  useEffect(() => {
    if (allProducts && !allProductsLoading && !allProductsError) {
      const products = allProducts.listProducts;
      dispatch(productActions.setAllProducts({ products }));
    }
  }, [{ allProducts, allProductsLoading, allProductsError }]);

  useEffect(() => {}, []);

  if (allProductsLoading) {
    return <Loading message="Fetching Tyrell Cloud product configurations" />;
  }

  return (
    <Switch>
      <ProtectedRoute
        path={PATH.DASHBOARD}
        component={TenantDashboard}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.ALL_USERS,
        }}
      />
      <ProtectedRoute
        path={PATH.ACCOUNT}
        component={Account}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.ADMINS,
        }}
      />
      <ProtectedRoute
        path={PATH.MEMBERS}
        component={TenantMembers}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.OPS,
        }}
      />
      <ProtectedRoute
        path={PATH.SETTINGS}
        component={TenantTenantSettings}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.OPS,
        }}
      />
      <ProtectedRoute
        path={PATH.INVITE}
        component={TenantInviteUsers}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.OPS,
        }}
      />
      <ProtectedRoute
        path={PATH.NEW_PROJECT}
        component={TenantCreateProject}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.ADMINS,
        }}
      />
    </Switch>
  );
};

export default TenantRouter;
