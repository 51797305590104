import { useRedux } from 'Hook';

import { TenantMemberRole } from 'graphql/types.generated';

type Props = {
  tenantId: string;
  minSeniority: number;
  fallbackComponent?: any | null;
  children: any;
};

const TenantPermissionable = ({
  tenantId,
  minSeniority,
  fallbackComponent = null,
  children,
}: Props) => {
  const {
    store: {
      member: { thisSessionMember },
    },
  } = useRedux();

  const tenantMemberRole: TenantMemberRole =
    thisSessionMember?.tenantMemberRoles?.find(
      ({ tenant }) => tenant.id === tenantId
    );

  const canUse = tenantMemberRole?.role?.seniority >= minSeniority;

  if (!canUse) return fallbackComponent;

  return children;
};
export default TenantPermissionable;
