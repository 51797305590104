import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'Views/routes';
import WorkspaceDashboard from 'Views/Workspace/Dashboard';
import { ROLE_SENIORITIES } from '../../utils/permissions';

export const PATH = {
  DASHBOARD: '/',
};

const WorkspaceRouter = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={PATH.DASHBOARD}
        component={WorkspaceDashboard}
        permissions={{
          isProject: false,
          minSeniority: ROLE_SENIORITIES.ALL_USERS,
        }}
      />
    </Switch>
  );
};

export default WorkspaceRouter;
