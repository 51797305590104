import { useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Stack,
  AlertTitle,
  TextField,
} from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TimezoneSelector from './timezone';
import { States, HandleScheduleChange, HandleUtcOffsetChange } from './types';
import {
  createSchedule,
  createMatrix,
  MassSelectionButtonActions,
} from './common';
import {
  ScheduleUI,
  ScheduleGridUI,
  GridOverlayUI,
  ButtonActions,
  XAxisHourLabelsUI,
  HourLabelUI,
  Legend,
  LegendElement,
  Toolbar,
  UtcOffset,
} from './styles';

const ProjectSchedule = ({
  projectState,
  handleScheduleChange,
  handleUtcOffsetChange,
  handleSave,
  showSaveButton,
  utcOffset = 0,
  timezone,
}: {
  projectState?: States;
  handleScheduleChange: HandleScheduleChange;
  handleUtcOffsetChange: HandleUtcOffsetChange;
  handleSave?: () => any;
  showSaveButton: boolean;
  utcOffset?: number;
  timezone?: string;
}) => {
  if (projectState?.__typename) {
    delete projectState.__typename;
  }

  const _initialProjectState = projectState
    ? { ...projectState }
    : createSchedule(0);

  const [confirmChangesAreUnderstood, setConfirmChangesAreUnderstood] =
    useState(false);

  const [_projectState, setProjectState] = useState(_initialProjectState);

  const [weeklyUptimeHours, setWeeklyUptimeHours] = useState(0);

  const [weeklyUptimePerc, setWeeklyUptimePerc] = useState(0);

  const [hourHighlight, setHourHighlight] = useState(0);

  const handleHourClick = ({ dayName, hourIndex, state }) => {
    const nextState = parseInt(state, 10) === 1 ? 0 : 1;
    const newState = { ..._projectState };
    newState[dayName][hourIndex] = nextState;
    setProjectState(newState);
  };

  useEffect(() => {
    const totalHours = 168;

    const running = Object.values(_projectState).reduce((acc, cur) => {
      const hoursRunning = cur.filter((c) => parseInt(c, 10) === 1);
      return acc + hoursRunning.length;
    }, 0);

    setWeeklyUptimeHours(running);
    setWeeklyUptimePerc(Math.floor((running / totalHours) * 100));

    // callback
    handleScheduleChange(_projectState);
  }, [_projectState]);

  const projectScheduleMatrix = createMatrix({
    states: _projectState,
    type: 'project',
    handleClick: handleHourClick,
    eventsEnabled: true,
    hourHighlight,
  });

  const xAxisHourLabels = (
    <XAxisHourLabelsUI>
      {new Array(24).fill(0).map((el, i) => {
        return (
          <HourLabelUI key={`x-axis-label-${i}`}>
            <Typography>{i}</Typography>
          </HourLabelUI>
        );
      })}
    </XAxisHourLabelsUI>
  );

  const handleTimeZoneChange = ({
    label,
    timeNow,
    offset,
  }: {
    label: string;
    timeNow: string;
    offset: number;
  }) => {
    console.log('handleTimeZoneChange', label, timeNow, offset);
    const [hour, minute, second] = timeNow.split(':');
    setHourHighlight(parseInt(hour, 10));
    handleUtcOffsetChange({ utcOffset: offset, timezone: label });
  };

  return (
    <ScheduleUI>
      <TimezoneSelector
        timezone={timezone}
        handleTimeZoneChange={handleTimeZoneChange}
      />
      <Toolbar>
        <Legend>
          <LegendElement className="--on">
            <Typography>Running</Typography>
          </LegendElement>
          <LegendElement className="--off">
            <Typography>Stopped</Typography>
          </LegendElement>
        </Legend>
        <ButtonActions>
          <MassSelectionButtonActions
            setAlwaysOn={() => setProjectState(createSchedule(1))}
            setAlwaysOff={() => setProjectState(createSchedule(0))}
          />
        </ButtonActions>
      </Toolbar>
      <GridOverlayUI>
        <ScheduleGridUI>
          {xAxisHourLabels}
          {projectScheduleMatrix}
        </ScheduleGridUI>
      </GridOverlayUI>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert variant="outlined" severity="info">
          <Typography>
            All resources within this project will run for
            <strong> {`${weeklyUptimeHours}`} hours</strong> or
            <strong> {`${weeklyUptimePerc}%`}</strong> of the week.
          </Typography>
        </Alert>
        <Alert variant="outlined" severity="error">
          <AlertTitle>Setting the default project schedule</AlertTitle>
          Changes made to this schedule will act as the default schedule for all
          resources in this project. You can add schedules to specific resources
          once they are created to override this default one if required.
          {showSaveButton && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmChangesAreUnderstood}
                  onChange={(e) =>
                    setConfirmChangesAreUnderstood(e.target.checked)
                  }
                />
              }
              label="I understand this schedule will have an impact on monthly billing"
            />
          )}
        </Alert>
      </Stack>
      {showSaveButton && handleSave && confirmChangesAreUnderstood && (
        <ButtonActions>
          <Button type="submit" variant="outlined" onClick={() => handleSave()}>
            <SaveOutlinedIcon />
            Save schedule
          </Button>
        </ButtonActions>
      )}
    </ScheduleUI>
  );
};

export default ProjectSchedule;
