import { useState, useEffect } from 'react';
import { useRedux } from 'Hook';
import {
  Button,
  Alert,
  AlertTitle,
  AlertColor,
  CircularProgress,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { LaunchOutlined } from '@mui/icons-material';
import {
  ProvisionDeployedRow,
  DataCell,
  DataColumn,
  InProgressContainer,
  ProvisionProductSelector,
  ProvisionerV2Container,
  SideBar,
  Boxtitle,
  TitleBar,
  OpenTab,
} from './styles';

const Provision = ({ service }: { service: string }) => {
  const {
    dispatch,
    store: {
      deployment: { deployments },
      product: { products },
      provision: { all },
      project: { project: selectedProject },
    },
    actions: { provisionActions },
  } = useRedux();

  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);

  const [selectedProductId, setSelectedProductId] = useState<string>(null);

  const [scopedDeployments, setScopedDeployments] = useState([]);

  const [deploymentName, setDeploymentName] = useState<string>('');

  const deployableProducts = products.filter((p) => {
    const meta = JSON.parse(p.meta || '{}');
    return meta.deployable !== false;
  });

  const [serviceOptions] = useState<Array<string>>(
    Array.from(new Set([...deployableProducts.map((p) => p.service.name)])) // unique list
  );

  const [selectedService, setSelectedService] = useState<string>('');

  const scopedProducts = deployableProducts.filter((p) => {
    return p.service.name.toLowerCase() === selectedService.toLowerCase();
  });

  useEffect(() => {
    const _truthyDeployments = deployments.filter((d) => !!d);
    if (selectedService === '' || !selectedService) {
      setScopedDeployments(_truthyDeployments);
    } else {
      const _scopedDeployments = _truthyDeployments.filter((d) => {
        return (
          d.product.service.name.toLowerCase() === selectedService.toLowerCase()
        );
      });
      setScopedDeployments(_scopedDeployments);
    }
  }, [isSideBarOpen, selectedService]);

  const openCloseClassModifier = isSideBarOpen ? '--open' : '';

  return (
    <ProvisionerV2Container className={`Provisioner ${openCloseClassModifier}`}>
      <OpenTab
        className="Provisioner__OpenTab"
        onClick={() => setIsSideBarOpen(true)}
      >
        <LaunchOutlined />
      </OpenTab>
      <SideBar className="Provisioner__SideBar">
        <TitleBar>
          <h4>Product Launcher</h4>
          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            onClick={() => setIsSideBarOpen(false)}
          >
            Close
          </Button>
        </TitleBar>

        <ProvisionProductSelector>
          <Boxtitle>
            <h4>Select Service</h4>
            <h4 className="count">({`${serviceOptions.length}`})</h4>
          </Boxtitle>
          <Select
            value={selectedService}
            fullWidth
            label="Service"
            onChange={(e) => {
              setSelectedService(e.target.value);
            }}
          >
            {serviceOptions
              ?.sort((a, b) => (a > b ? 1 : -1))
              .map((so) => {
                return (
                  <MenuItem
                    key={so}
                    value={so}
                    selected={so === selectedService}
                  >
                    {`${so}`}
                  </MenuItem>
                );
              })}
          </Select>
        </ProvisionProductSelector>

        <ProvisionProductSelector>
          <Boxtitle>
            <h4>Select Product</h4>
            <h4 className="count">({`${scopedProducts.length}`})</h4>
          </Boxtitle>
          <Select
            value={selectedProductId}
            fullWidth
            label="Age"
            onChange={(e) => {
              setSelectedProductId(e.target.value);
            }}
          >
            {scopedProducts
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((sp) => {
                return (
                  <MenuItem
                    key={sp.id}
                    value={sp.id}
                    selected={sp.id === selectedProductId}
                  >
                    {`${sp.name} - ${sp.description}`}
                  </MenuItem>
                );
              })}
          </Select>
          <TextField
            id="deployment-name"
            label="Deployment Name"
            variant="standard"
            value={deploymentName}
            disabled={!selectedProductId}
            onChange={(e) => setDeploymentName(e.target.value)}
          />
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={!deploymentName}
            onClick={() =>
              dispatch(
                provisionActions.createNew({
                  deploymentName,
                  productId: parseInt(selectedProductId, 10),
                  projectId: parseInt(selectedProject.id, 10),
                })
              )
            }
          >
            Deploy
          </Button>
        </ProvisionProductSelector>

        {all.length > 0 && (
          <InProgressContainer>
            <hr />
            <h4>In progress</h4>
            {all.map((p) => {
              const severity = {
                failed: 'error',
                success: 'success',
                pending: 'warning',
              }[p.outcome];

              return (
                <Alert
                  key={`provision-${p.deploymentId}-${p.mode}`}
                  variant="outlined"
                  severity={severity as AlertColor}
                  iconMapping={{
                    warning: <CircularProgress />,
                  }}
                >
                  <AlertTitle>{p.message}</AlertTitle>
                  {`${p.mode} ${p.deploymentId}`}
                </Alert>
              );
            })}
          </InProgressContainer>
        )}

        {scopedDeployments.length > 0 && (
          <>
            <hr />
            <h4>{`All ${selectedService} Deployed`}</h4>
            {scopedDeployments.map((d) => {
              return (
                <ProvisionDeployedRow key={d.id}>
                  <DataColumn>
                    <DataCell>
                      <p>Id</p>
                      <p>{d.id}</p>
                    </DataCell>
                    <DataCell>
                      <p>Name</p>
                      <p>{d.name}</p>
                    </DataCell>
                    <DataCell>
                      <p>Product</p>
                      <p>{d.product.name}</p>
                    </DataCell>
                    <DataCell>
                      <p>Service</p>
                      <p>{d.product.service.name}</p>
                    </DataCell>
                    <DataCell>
                      <p>Vendor</p>
                      <p>{d.product.vendor.name}</p>
                    </DataCell>
                    <DataCell>
                      <p>Time</p>
                      <p>{new Date(d.deployedAt).toLocaleString()}</p>
                    </DataCell>
                  </DataColumn>
                  <DataColumn>
                    <DataCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        onClick={() =>
                          dispatch(
                            provisionActions.teardown({ deploymentId: d.id })
                          )
                        }
                      >
                        Remove
                      </Button>
                    </DataCell>
                  </DataColumn>
                </ProvisionDeployedRow>
              );
            })}
          </>
        )}
      </SideBar>
    </ProvisionerV2Container>
  );
};

export default Provision;
