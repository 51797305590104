import styled from 'styled-components';

const colorOn = 'rgb(2, 200, 18)';
const colorOff = 'rgba(215, 215, 215)';
const colorBlack = 'rgb(68, 82, 90)';
const cellSize = '1.5rem';

export const DayUI = styled.div`
  display: flex;
  justify-content: 'flex-start';

  > button {
    margin-right: 1rem;
  }
`;

export const HourUI = styled.div`
  width: ${cellSize};
  height: ${cellSize};
  margin: 0.1rem;
  color: transparent;
  cursor: pointer;

  &.--on {
    background-color: ${colorOn};

    &.--resource {
      position: relative;
      &:after {
        position: absolute;
        content: '+';
        display: block;
        /* width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        background: white; */
        color: white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.--off {
    &.--project {
      background-color: ${colorOff};
    }
    &.--resource {
      background-color: transparent;
    }
  }

  &.--force-off {
    background-color: ${colorOff};
    position: relative;
    &:after {
      position: absolute;
      content: '-';
      display: block;
      color: ${colorBlack};
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.--highlight {
    border: 2px solid black;
  }
`;

export const ScheduleXAxis = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  > p {
    text-transform: uppercase;
    text-align: right;
    width: 4rem;
    margin: 0 0.15rem 0 0;
    background: rgb(68, 82, 90);
    color: white;
    padding: 0 0.3rem 0 0;
    box-sizing: border-box;

    &.--resource {
      background: transparent;
      color: transparent;
    }
  }
`;

export const ScheduleUI = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

export const ScheduleGridUI = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const GridOverlayUI = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  margin: 1rem auto;
`;

export const ButtonActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;

  > button {
    margin: 0 0.25rem;
  }

  > svg {
    cursor: pointer;
    margin: 1rem;
  }
`;

export const XAxisHourLabelsUI = styled.div`
  display: flex;
  position: absolute;
  top: -1.8rem;
  left: 4.15rem;
`;

export const HourLabelUI = styled.div`
  display: flex;
  width: ${cellSize};
  height: ${cellSize};
  margin: 0.1rem;
  background: rgb(68, 82, 90);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Legend = styled.div`
  display: flex;
  justify-content: center;
`;

export const LegendElement = styled.div`
  display: flex;
  margin: 0 0.5rem;
  align-items: center;

  &::before {
    margin: 0 0.5rem;
    content: '';
    width: ${cellSize};
    display: block;
    height: ${cellSize};
  }

  &.--on {
    &::before {
      background: ${colorOn};
    }
  }
  &.--off {
    &::before {
      background: ${colorOff};
    }
  }
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 1rem 0;
  opacity: 0.8;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
`;

export const MassSelectionControl = styled.div`
  display: flex;
  margin: 0 0.5rem;
`;

export const MassSelectionButton = styled.div`
  display: flex;
  margin: 0 0.5rem;
  color: ${colorBlack};
  cursor: pointer;
`;

export const IconButton = styled.div`
  display: flex;
  margin: 0 0.5rem;
  align-items: center;
  width: ${cellSize};
  height: ${cellSize};
  color: white;
  cursor: pointer;
  &.--on {
    background: ${colorOn};
  }
  &.--off {
    background: ${colorOff};
  }
`;

export const UtcOffset = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
`;
