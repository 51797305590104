import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllTimezoneMeta, getTimezoneMeta } from 'utils/timezone';

interface TimezoneMetaData {
  label: string;
  timeNow: string;
  offset: number;
}

const TimezoneSelector = ({
  timezone,
  handleTimeZoneChange,
}: {
  timezone: string;
  handleTimeZoneChange: (param: TimezoneMetaData) => void;
}) => {
  /**
   *
   * States
   */
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [selectedTimezoneMeta, setSelectedTimezoneMeta] =
    useState<TimezoneMetaData>(null);

  const [allTimezoneMeta, setAllTimezoneMeta] = useState<
    Array<TimezoneMetaData>
  >([]);

  /**
   *
   * Handlers
   */

  useEffect(() => {
    if (selectedTimezone) {
      const tz = getTimezoneMeta(selectedTimezone);
      setSelectedTimezoneMeta(tz);
      handleTimeZoneChange(tz);
    }
  }, [selectedTimezone]);

  useEffect(() => {
    setSelectedTimezone(timezone);
    const _allTimezoneMeta = getAllTimezoneMeta();
    setAllTimezoneMeta(_allTimezoneMeta);
  }, []);

  /**
   *
   * HTML Element
   */

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={3}
    >
      <Autocomplete
        disablePortal
        id="timezone-selector"
        options={allTimezoneMeta}
        sx={{ width: 300 }}
        defaultValue={selectedTimezone}
        value={selectedTimezone}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Time Zone" />
        )}
        onChange={(el: any) => setSelectedTimezone(el.target.textContent)}
      />
      {selectedTimezoneMeta && (
        <TextField
          value={selectedTimezoneMeta.timeNow}
          variant="standard"
          label="Time now"
          disabled
        />
      )}
      {selectedTimezoneMeta && (
        <TextField
          value={selectedTimezoneMeta.offset}
          variant="standard"
          label="UTC offset"
          disabled
        />
      )}
    </Box>
  );
};

export default TimezoneSelector;
