import styled from 'styled-components';

export const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
`;

export const SendInvitations = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
`;

export const AddInviteeButton = styled.div`
  margin-right: auto;
  width: 20%;
  margin: 1rem 0.5rem;

  > button {
    width: 100%;
  }
`;

const emailFieldPerc = 60;
const roleFieldPerc = 30;
const deleteButtonFieldPerc = 10;

export const InvitationInputElement = styled.div`
  display: flex;
  flex-direction: row;

  &:nth-child(1) {
    width: ${emailFieldPerc}%;
  }
  &:nth-child(2) {
    width: ${roleFieldPerc}%;
  }
  &:nth-child(3) {
    width: ${deleteButtonFieldPerc}%;
  }
`;

export const UploadCsvButton = styled.p`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 1rem 0.5rem;
`;
