import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'Views/routes';
import ProjectDashboard from 'Views/Project/Dashboard';
import ProjectWorkstations from 'Views/Project/Workstations';
import ProjectStore from 'Views/Project/Store';
import ProjectTransfer from 'Views/Project/Transfer';
import ProjectInfrastructure from 'Views/Project/Infrastructure';
import Loading from 'components/Common/Loading';
import ProjectTeam from 'Views/Project/Settings/Team';
import ProjectInvite from 'Views/Project/Settings/Invite';
import ProjectSchedules from 'Views/Project/Settings/Schedules';
import ResourceSchedule from 'Views/Project/ResourceSchedule';
import { useGetProjectQuery } from 'graphql/getProject.generated';
import { projectActions } from 'Controller/actions';
import { useProjectNotifications, useRedux } from 'Hook';
import Provisioner from 'components/v2/Provision';
import { ROLE_SENIORITIES } from '../../utils/permissions';

export const PATH = {
  DASHBOARD: '/project/dashboard',
  WORKSTATION: '/project/workstation',
  STORE: '/project/store',
  TRANSFER: '/project/transfer',
  CAPTURE: '/project/capture',
  CONNECT_STREAM: '/project/connectstream',
  INFRASTRUCTURE: '/project/infrastructure',
  TEAM: '/project/team',
  INVITE: '/project/invite',
  PROJECT_SCHEDULE: '/project/schedule',
  RESOURCE_SCHEDULE: '/project/resource/schedule',
};

const ProjectRouter = () => {
  const {
    dispatch,
    store: {
      project: { selectedProjectId },
    },
  } = useRedux();

  /**
   *
   * Connect to notification channel
   */

  useProjectNotifications(selectedProjectId);

  /**
   *
   * Get Project
   */

  const [
    {
      data: getProjectData,
      fetching: getProjectFetching,
      error: getProjectError,
    },
  ] = useGetProjectQuery({
    variables: { projectId: selectedProjectId },
  });

  useEffect(() => {
    if (getProjectData && !getProjectFetching && !getProjectError) {
      const _project = getProjectData.getProject;
      dispatch(projectActions.setProject({ project: _project }));
    }
  }, [getProjectData, getProjectFetching, getProjectError]);

  useEffect(() => {}, []);

  if (getProjectFetching) {
    return <Loading message="Getting project from our servers" />;
  }

  return (
    <>
      <Provisioner service="capture" />
      <Switch>
        <ProtectedRoute
          path={PATH.WORKSTATION}
          exact
          component={ProjectWorkstations}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.ALL_USERS,
          }}
        />
        <ProtectedRoute
          path={PATH.DASHBOARD}
          exact
          component={ProjectDashboard}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.ALL_USERS,
          }}
        />
        <ProtectedRoute
          path={PATH.STORE}
          exact
          component={ProjectStore}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.OPS,
          }}
        />
        <ProtectedRoute
          path={PATH.TRANSFER}
          exact
          component={ProjectTransfer}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.OPS,
          }}
        />

        <ProtectedRoute
          path={PATH.INFRASTRUCTURE}
          exact
          component={ProjectInfrastructure}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.OPS,
          }}
        />
        <ProtectedRoute
          path={PATH.TEAM}
          exact
          component={ProjectTeam}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.OPS,
          }}
        />
        <ProtectedRoute
          path={PATH.INVITE}
          exact
          component={ProjectInvite}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.ADMINS,
          }}
        />
        <ProtectedRoute
          path={PATH.PROJECT_SCHEDULE}
          exact
          component={ProjectSchedules}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.OPS,
          }}
        />
        <ProtectedRoute
          path={PATH.RESOURCE_SCHEDULE}
          exact
          component={ResourceSchedule}
          permissions={{
            isProject: true,
            minSeniority: ROLE_SENIORITIES.OPS,
          }}
        />
      </Switch>
    </>
  );
};

export default ProjectRouter;
