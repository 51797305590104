import { Fragment, useState } from 'react';
import { useRedux } from 'Hook';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Avatar from 'components/Common/Avatar';
import { Logout } from '@mui/icons-material';
import { tenantNavigationConfig } from './config';
import {
  ContextButton,
  UserDetails,
  UserDisplayName,
} from './TenantUserMenu.styled';

const windowExists = typeof window !== 'undefined';

const getApiDomain = (): {
  domain: string;
  // subDomain: string;
  extension: string;
} => {
  if (windowExists) {
    const url = new URL(String(window.location.href));

    const [domain, extension] = url.host.split('.');

    return {
      domain,
      extension,
    };
  }
  return {
    domain: '',
    extension: '',
  };
};

function TenantUserMenu() {
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event: any) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleLogout = () => {
    const { domain, extension } = getApiDomain();

    const fullDomain = `${domain}.${extension}`;
    document.cookie = `Authorization=null; Domain=.${fullDomain}; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; Path=/;`;
    if (windowExists) {
      window.location.href = `https://auth.${fullDomain}`;
    }
  };

  const {
    store: { member },
  } = useRedux();

  return (
    <>
      <ContextButton onClick={userMenuClick}>
        <UserDetails>
          <UserDisplayName>
            {member?.thisSessionMember?.givenName || ''}{' '}
            {member?.thisSessionMember?.familyName || ''}
          </UserDisplayName>
        </UserDetails>

        <Avatar
          displayName={`${member?.thisSessionMember?.givenName || ''} ${
            member?.thisSessionMember?.familyName || ''
          }`}
          alt="user photo"
        />
      </ContextButton>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <>
          {tenantNavigationConfig.map((navItem) => (
            <Fragment key={navItem.id}>
              <MenuItem
                component={Link}
                to={navItem.url}
                onClick={userMenuClose}
                role="button"
              >
                <ListItemIcon>
                  <Icon>{navItem.icon}</Icon>
                </ListItemIcon>
                <Box>
                  <ListItemText primary={navItem.title} />
                </Box>
              </MenuItem>
            </Fragment>
          ))}
        </>
        <MenuItem onClick={handleLogout} role="button">
          <ListItemIcon>
            <Icon>
              <Logout color="error" />
            </Icon>
          </ListItemIcon>
          <Box>
            <ListItemText primary="Logout" />
          </Box>
        </MenuItem>
      </Popover>
    </>
  );
}

export default TenantUserMenu;
