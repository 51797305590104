import { Deployment } from 'graphql/types.generated';
import Type from 'Controller/Provision/Provision.type';

enum ProvisionOutcome {
  FAILED = 'failed',
  SUCCESS = 'success',
  PENDING = 'pending',
}

enum ProvisionMode {
  CREATE = 'create',
  TEARDOWN = 'teardown',
}

interface iProvision {
  deploymentId: string;
  mode: ProvisionMode;
  outcome: ProvisionOutcome;
  deployment?: Deployment;
  message?: any;
}

export interface ProvisionState {
  all: Array<iProvision>;
}

const initalState: ProvisionState = {
  all: [],
};

type Action = {
  type: Type;
  payload: {
    deploymentId: string;
    deploymentName: string;
    deployment: Deployment;
    message: any;
  };
};

export default function ProvisionReducer(
  state: ProvisionState = initalState,
  action: Action
): ProvisionState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.CREATE_NEW__SUCCESS: {
      const { deploymentId, deploymentName } = payload;
      return {
        ...state,
        all: [
          ...state.all,
          {
            deploymentId,
            message: `Deploying ${deploymentName}`,
            mode: ProvisionMode.CREATE,
            outcome: ProvisionOutcome.PENDING,
          },
        ],
      };
    }

    case Type.TEAR_DOWN: {
      const { deploymentId } = payload;
      return {
        ...state,
        all: [
          ...state.all,
          {
            deploymentId,
            message: `Deleting from project`,
            mode: ProvisionMode.TEARDOWN,
            outcome: ProvisionOutcome.PENDING,
          },
        ],
      };
    }

    case Type.HANDLE_SUCCESS: {
      const { deploymentId, message, deployment } = payload;
      return {
        ...state,
        all: state.all.map((provision) => {
          return provision.deploymentId !== deploymentId
            ? provision
            : {
                ...provision,
                deployment,
                message,
                outcome: ProvisionOutcome.SUCCESS,
              };
        }),
      };
    }

    case Type.HANDLE_FAILURE: {
      const { deploymentId, message } = payload;
      return {
        ...state,
        all: state.all.map((provision) => {
          return provision.deploymentId !== deploymentId
            ? provision
            : {
                ...provision,
                message,
                outcome: ProvisionOutcome.FAILED,
              };
        }),
      };
    }

    default:
      return state;
  }
}
