import Grid from '@material-ui/core/Grid';
import ProjectSchedule from 'components/Project/ProjectSchedule';
import { useSelector } from 'react-redux';
import { iState } from 'Model';
import { Container } from '../Settings.styled';

function ProjectSchedules() {
  const { project } = useSelector((store: iState) => {
    return store.project;
  });

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Project Schedule</h2>
      </Grid>
      <ProjectSchedule projectId={project.id} schedule={project.schedule} />
    </Container>
  );
}

export default ProjectSchedules;
