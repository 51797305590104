import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 1.5rem 1.5rem;
`;

export const DeploymentsContainer = styled.div`
  margin-bottom: 64px;

  > h4 {
    margin-bottom: 8px;
  }
`;
