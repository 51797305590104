import styled from 'styled-components';

export const StyledAvatar = styled.div`
  width: ${({ size }: { size: string | undefined }) =>
    size ? `${size}px` : '32px'};
  height: ${({ size }: { size: string | undefined }) =>
    size ? `${size}px` : '32px'};
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 100%;
  background: #225cf4;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export const NoProfilePicture = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #d70a2c;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Intials = styled.span`
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: -2px;
  position: relative;
  left: -1px;
`;
