import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WorkspaceContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid #ccd1d4ab;
  border-bottom-left-radius: 0;
`;

export const WorkspaceContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Workspace = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
`;

export const WorkspaceDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const WorkspaceLogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 8px;
`;

export const WorkspaceName = styled(Typography)`
  font-size: 16px !important;
  margin-bottom: 4px !important;
  font-weight: 800 !important;
`;

export const WorkspaceURL = styled(Typography)`
  font-size: 14px !important;
  margin: 0;
  opacity: 0.7;
  font-weight: 600;
`;
