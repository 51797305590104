import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RenameResourceMutationVariables = Types.Exact<{
  resourceId: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;


export type RenameResourceMutation = { __typename?: 'Mutation', renameResource?: { __typename?: 'Resource', id: string, name: string, physicalId?: string | null, state?: string | null } | null };


export const RenameResourceDocument = gql`
    mutation renameResource($resourceId: String!, $name: String!) {
  renameResource(resourceId: $resourceId, name: $name) {
    id
    name
    physicalId
    state
  }
}
    `;

export function useRenameResourceMutation() {
  return Urql.useMutation<RenameResourceMutation, RenameResourceMutationVariables>(RenameResourceDocument);
};