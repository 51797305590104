import { useState } from 'react';
import * as yup from 'yup';
import isEmpty from 'lodash.isempty';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import {
  Form,
  TextField,
  PhoneField,
  FormFields,
  FormActions,
  FormSubHeader,
} from 'components/Common/Forms';
import RoleSelect from 'components/Selectors/RoleSelect';
import ProjectSelect from 'components/Selectors/ProjectSelect';
import { useRedux } from 'Hook';
import { useEditMembersRolesMutation } from 'graphql/editMemberRoles.generated';
import { MemberRoleInputEffect, TargetType } from 'graphql/types.generated';
import { PATH as PROJECT_PATH } from 'Views/Project';
import { PATH as TENANT_PATH } from 'Views/Tenant';
import { Container, MainContent } from './Invite.styled';

/**
 * Form Validation Schema
 */
type FormInputs = {
  email: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  role: string;
  project?: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter a email'),
  phoneNumber: yup.string().required('Please enter a phone number.'),
  givenName: yup.string().required('Please enter a first name.'),
  familyName: yup.string().required('Please enter a last name.'),
  role: yup.string().required('Please select a role.'),
  project: yup.string(),
});

const defaultValues = {
  email: '',
  phoneNumber: '',
  givenName: '',
  familyName: '',
  role: '',
  project: '',
};

function InviteUsers() {
  const history = useHistory();

  const {
    store: {
      tenant: { tenants, userSelected },
      project: { selectedProjectId, projects },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [, inviteMember] = useEditMembersRolesMutation();

  const { control, formState, handleSubmit, reset } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (values: FormInputs) => {
    setError(null);

    try {
      setLoading(true);

      if (!userSelected) return;

      const memberRoles = [
        {
          roleId: values.role,
          effect: MemberRoleInputEffect.Update,
          member: {
            email: values.email.toLowerCase().trim(),
            familyName: values.familyName.trim(),
            givenName: values.givenName.trim(),
            phoneNumber: values.phoneNumber,
          },
        },
      ];

      const { data: addToTenantData, error: addToTenantError } =
        await inviteMember({
          editMemberRoles: {
            targetType: TargetType.Tenant,
            targetId: thisTenant.id,
            memberRoles,
          },
        });

      if (addToTenantError) {
        setError('There was an issue inviting this user. Please try again.');
        setLoading(false);
        return;
      }

      if (values.project) {
        const { data: addToProjectData, error: addToProjectMutationError } =
          await inviteMember({
            editMemberRoles: {
              targetType: TargetType.Project,
              targetId: values.project,
              memberRoles,
            },
          });

        if (addToProjectMutationError) {
          setError('There was an issue inviting this user. Please try again.');
          setLoading(false);
          return;
        }
      }

      reset(defaultValues);
      setLoading(false);

      return history.push(TENANT_PATH.MEMBERS);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to={PROJECT_PATH.DASHBOARD}>
              {thisTenant.name}
            </Link>
            <Typography>Admin</Typography>
            <Typography>Manage Team</Typography>
            <Typography>Invite</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Form
        name="inviteUserModalForm"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormActions>
          <h2>Invite</h2>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box mr={2}>
              <Button
                onClick={() => history.push(TENANT_PATH.MEMBERS)}
                variant="outlined"
                color="secondary"
                disabled={loading}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                disabled={isEmpty(dirtyFields) || !isValid || loading}
                variant="outlined"
                color="primary"
                type="submit"
                disableElevation
              >
                {loading ? 'Inviting...' : 'Invite'}
              </Button>
            </Box>
          </Box>
        </FormActions>
        <MainContent>
          <div>
            <h3>
              Users will receive a temporary password to login via email. They
              have up to 7 days to activate their account.
            </h3>
            {error && <span>{error}</span>}
          </div>
          <FormFields>
            <Box pt={2} mb={1}>
              <TextField
                name="email"
                control={control}
                label="Email"
                type="email"
                error={!!errors.email}
                errorMessage={errors?.email?.message}
                required
                fullWidth
              />
            </Box>
            <Box pt={2} mb={1}>
              <PhoneField
                name="phoneNumber"
                label="Phone"
                control={control}
                error={!!errors.phoneNumber}
                errorMessage={errors?.phoneNumber?.message}
                fullWidth
              />
            </Box>
            <Box pt={2} mb={1}>
              <TextField
                name="givenName"
                control={control}
                label="First Name"
                type="text"
                error={!!errors.givenName}
                errorMessage={errors?.givenName?.message}
                required
                fullWidth
              />
            </Box>
            <Box pt={2} mb={1}>
              <TextField
                name="familyName"
                control={control}
                label="Last Name"
                type="text"
                error={!!errors.familyName}
                errorMessage={errors?.familyName?.message}
                required
                fullWidth
              />
            </Box>
            <Box pt={2} mb={1}>
              <RoleSelect
                control={control}
                error={!!errors.role}
                errorMessage={errors?.role?.message}
              />
            </Box>
            <Divider />
            <FormSubHeader>
              Add this user to the following project (optional)
            </FormSubHeader>
            <Box pt={2} mb={1}>
              <ProjectSelect
                tenantId={thisTenant.id}
                control={control}
                error={!!errors.project}
                errorMessage={errors?.project?.message || ''}
              />
            </Box>
          </FormFields>
        </MainContent>
      </Form>
    </Container>
  );
}

export default InviteUsers;
