import { useState, useEffect } from 'react';
import styled from 'styled-components';
import MuiSelect from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { Select } from 'components/Common/Forms';

import { useListRolesQuery } from 'graphql/listRoles.generated';
import { Role } from 'graphql/types.generated';

interface Props {
  control?: any;
  error?: boolean;
  errorMessage?: string;
  inline?: boolean;
  onChange?: (e: any) => void;
  disabled?: boolean;
  value?: any;
}

type SelectOption = {
  label: string;
  value: string | number;
};

const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '8px 14px 10px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const RoleSelect = ({
  control,
  error,
  errorMessage,
  inline = false,
  onChange = null,
  value = null,
  disabled = false,
}: Props) => {
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  const [{ data, error: queryError }] = useListRolesQuery();

  useEffect(() => {
    if (data && data.listRoles) {
      const options = data?.listRoles
        ?.filter((role: Role) => !['tyrell', 'disabled'].includes(role.name))
        .map((role: Role) => ({
          label: role?.name,
          value: role?.id,
        }));

      setRoleOptions(options);
    }
  }, [data]);

  // Select can be controlled with react-hook-form or registered with an onChange event handler
  return (
    <>
      {onChange ? (
        <MuiSelect
          name="role"
          onChange={onChange}
          value={value}
          input={<StyledInput />}
          autoWidth
          disabled={disabled}
          native
        >
          <>
            {roleOptions.map(
              ({
                label: optionLabel,
                value: optionValue,
              }: {
                label: string;
                value: any;
              }) => (
                <option key={optionValue} value={optionValue}>
                  {optionLabel}
                </option>
              )
            )}
          </>
        </MuiSelect>
      ) : (
        <Select
          options={roleOptions}
          name="role"
          control={control}
          error={error || !queryError}
          errorMessage={errorMessage}
          label="Role"
          disabled={disabled}
          inline={inline}
          native
        />
      )}
    </>
  );
};

export default RoleSelect;
