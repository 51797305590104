import { Route } from 'react-router-dom';
import AppLayout from 'components/Layouts/AppLayout';
import { checkCookie } from 'utils/credentials';
import Loading from 'components/Common/Loading';

const ProtectedRoute = ({
  permissions: routePermissions,
  component: Component,
  ...rest
}: any) => {
  const cookie = checkCookie();

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (!cookie) {
          const [domain] = window.location.host.split('.');
          window.location.href = `https://auth.${domain}.cloud`;

          return <Loading message="Authenticating" />;
        }

        return (
          <AppLayout>
            <Component {...props} routePermissions={routePermissions} />
          </AppLayout>
        );
      }}
    />
  );
};

export { ProtectedRoute };
