import { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ProjectsList from 'components/Project/ProjectList';
import Loading from 'components/Common/Loading';
import { PATH as TENANT_PATH } from 'Views/Tenant';
import TenantPermissionable from 'components/Common/Permissions/TenantPermissionable';
import { useSelector } from 'react-redux';
import { iState } from 'Model';
import { useRedux } from 'Hook';
import { useListProjectsQuery } from 'graphql/listProjects.generated';
import PERMISSIONS from '../../../utils/permissions';
import { Container } from './Dashboard.styled';

function Dashboard() {
  const {
    dispatch,
    actions: { projectActions },
  } = useRedux();

  const selectedTenant = useSelector((store: iState) => {
    return store.tenant.tenants.find((t) => {
      return String(store.tenant.userSelected) === String(t.id);
    });
  });

  const history = useHistory();

  const [{ data, fetching, error }] = useListProjectsQuery({
    variables: { tenantId: selectedTenant.id },
  });

  useEffect(() => {
    if (data && !fetching && !error) {
      dispatch(projectActions.setProjects({ projects: data.listProjects }));
    }
  }, [data, fetching, error]);

  if (error) {
    return <span>Error loading workspace</span>;
  }

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              style={{ textDecoration: 'none' }}
              color="inherit"
              to={TENANT_PATH.DASHBOARD}
            >
              {selectedTenant.name}
            </Link>
            <Typography>Dashboard</Typography>
          </Breadcrumbs>
          <Box>
            <h2>Projects</h2>
          </Box>
        </Grid>
        <Grid item xs>
          <TenantPermissionable
            tenantId={selectedTenant.id}
            minSeniority={PERMISSIONS.tenant.project.create}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="CREATE PROJECT"
                disabled={false}
                type="submit"
                onClick={() => history.push(TENANT_PATH.NEW_PROJECT)}
              >
                Create Project
              </Button>
            </Box>
          </TenantPermissionable>
        </Grid>
      </Grid>
      {fetching ? (
        <Loading message={`Loading ${selectedTenant.name} projects`} />
      ) : (
        <div>
          <ProjectsList projects={data?.listProjects} />
        </div>
      )}
    </Container>
  );
}

export default Dashboard;
