import flatten from 'lodash.flatten';
import styled from 'styled-components';

import {
  ItemCategory,
  Deployment,
  TeradiciSession,
} from 'graphql/types.generated';

export type Order = 'asc' | 'desc';

export type DeploymentSession = TeradiciSession & Deployment;

export interface DeploymentRow {
  id: string;
  name: string;
  description: string;
  status: string;
  deployment: any;
}

export interface DeploymentData {
  deployment: Deployment;
  cost: string;
}

// based on ec2 instance state - pending, running, stopping, stopped, terminated
export const STATUS_COLOUR = {
  RUNNING: 'success',
  PENDING: 'warning',
  STOPPING: 'error',
  STOPPED: 'error',
  TERMINATED: 'secondary',
};

export const getItemCategoryFromDeployments = (
  deployments: any,
  itemCategory: ItemCategory
) =>
  flatten(
    deployments.map(({ deployment: { resources } }: any) => resources)
  ).filter(({ productItem: { category } }: any) => category === itemCategory);

export const getStatusFromDeploymentResources = (deployment: any): string => {
  const statusFromResources = deployment.resources.reduce(
    (status, resource) => {
      const computeResource =
        resource.productItem.category === ItemCategory.Compute;

      if (!computeResource) return status;

      return resource.state;
    },
    ''
  );

  return statusFromResources.toUpperCase();
};

export const createDeploymentRows = (activeDeployments): any =>
  activeDeployments.map((deployment: any) => {
    const status = getStatusFromDeploymentResources(deployment);

    return {
      id: deployment?.id,
      name: deployment?.name,
      description: deployment?.product?.name || '--',
      status: status === '' ? 'pending' : status,
      deployment,
    };
  });

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const EmptyState = styled.div`
  padding: 16px;
  text-align: center;

  > span {
    font-size: 14px;
    opacity: 0.8;
  }
`;
