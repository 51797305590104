import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';

import { useEditMembersRolesMutation } from 'graphql/editMemberRoles.generated';

import {
  TargetType,
  EditMemberRolesInput,
  MemberRoleInputEffect,
} from 'graphql/types.generated';

interface Props {
  targetId: string;
  targetType: TargetType;
  memberId: string;
  roleId: string;
  disabled?: boolean;
  onDelete?: () => void;
}

const RemoveUser = ({
  targetId,
  targetType,
  memberId,
  roleId,
  disabled,
  onDelete,
}: Props) => {
  const [, editMemberRoles] = useEditMembersRolesMutation();

  const onRemoveProjectMember = async () => {
    const input: EditMemberRolesInput = {
      targetId,
      targetType,
      memberRoles: [
        {
          memberId,
          roleId,
          effect: MemberRoleInputEffect.Delete,
        },
      ],
    };

    toast.loading('Removing member role', {
      duration: 4000,
      position: 'top-center',
    });

    const { error } = await editMemberRoles({
      editMemberRoles: input,
    });

    if (error) {
      return toast.error('Could not remove member', {
        duration: 4000,
        position: 'top-center',
      });
    }

    toast.success('Member removed', {
      duration: 4000,
      position: 'top-center',
    });

    onDelete();
  };

  return (
    <>
      <LoadingButton
        loading={false}
        loadingPosition="start"
        variant="outlined"
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={onRemoveProjectMember}
        disabled={disabled}
      >
        Remove
      </LoadingButton>
    </>
  );
};

export default RemoveUser;
