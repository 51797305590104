import { useRedux } from 'Hook';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Button, Tooltip } from '@mui/material';
import ProjectWorkstationsTable from 'components/Project/ProjectDeploymentsTables/WorkstationsTable';
import { Container } from './Workstations.styled';

function ProjectWorkstations() {
  const {
    dispatch,
    actions: {
      projectActions: { setWorkstationEntitlements },
    },
    store: {
      project: {
        project: { name },
      },
    },
  } = useRedux();

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Workstation</h2>

        <Tooltip
          title={`Give all members in the ${name} project PCoIP access to all workstations listed below`}
        >
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            onClick={() => dispatch(setWorkstationEntitlements())}
          >
            Refresh entitlements
          </Button>
        </Tooltip>
      </Grid>

      <ProjectWorkstationsTable />
    </Container>
  );
}

export default ProjectWorkstations;
