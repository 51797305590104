import { Grid } from '@material-ui/core';
import TransferTable from 'components/Project/ProjectDeploymentsTables/TransferTable';
import { Container } from './Transfer.styled';

function Transfer() {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Transfer</h2>
      </Grid>
      <TransferTable />
    </Container>
  );
}

export default Transfer;
