import { ProductCategory, Deployment } from 'graphql/types.generated';
import { deploymentActions } from 'Controller/actions';
import Type from 'Controller/Deployment/Deployment.type';
import { ReduxMiddlewareFunction } from 'Controller/middleware';

type Middleware = Record<Type, ReduxMiddlewareFunction>;

const middleware: Middleware = {
  [Type.REMOVE_PENDING]: (store, next, action) => {
    const {
      deployment: { pendingDeployments },
    } = store.getState();

    action.payload = {
      pendingDeployments: pendingDeployments.filter(
        (pd) => pd.clientRequestId !== action.payload.clientRequestId
      ),
    };

    next(action);
  },

  [Type.ADD_PENDING]: (store, next, action) => {
    const {
      deployment: { pendingDeployments },
    } = store.getState();

    action.payload = {
      pendingDeployments: [...pendingDeployments, action.payload],
    };

    next(action);
  },

  [Type.SELECT_DEPLOYMENT]: (store, next, action) => {
    const {
      deployment: { deployments },
    } = store.getState();

    const { deploymentId } = action.payload;

    const userSelected = deployments.find((d) => d.id === deploymentId);

    action.payload = {
      userSelected,
    };

    next(action);
  },

  [Type.SET_ALL_DEPLOYMENTS]: (store, next, action) => {
    if (action.payload.deployments === null) {
      action.payload.deployments = [];
    }

    const _deployments = action.payload.deployments.filter(
      (d) => !!d
    ) as Array<Deployment>;

    const teradici = _deployments
      .filter(
        (d) =>
          d.product.service.name.toLowerCase() === 'connect' ||
          d.product.service.name.toLowerCase() === 'connectremote'
      )
      .map((d) => d.id);

    const editShare = _deployments
      .filter((d) => d.product.service.name.toLowerCase() === 'store')
      .map((d) => d.id);

    const workstation = _deployments
      .filter((d) => d.product.service.name.toLowerCase() === 'workstation')
      .map((d) => d.id);

    const transfer = _deployments
      .filter((d) => d.product.service.name.toLowerCase() === 'transfer')
      .map((d) => d.id);

    const activeDirectory = _deployments
      .filter((d) => d.product.service.name.toLowerCase() === 'directory')
      .map((d) => d.id);

    const capture = _deployments
      .filter((d) => d.product.service.name.toLowerCase() === 'capture')
      .map((d) => d.id);

    action.payload = {
      ...action.payload,
      teradici,
      editShare,
      workstation,
      transfer,
      activeDirectory,
      capture,
    };

    next(action);
  },

  [Type.UPDATE_RESOURCE_STATE]: (store, next, action) => {
    const { dispatch } = store;
    const state = store.getState();
    const { resourceId, state: resourceState } = action.payload;

    const updatedDeployments = state.deployment.deployments.map((d) => {
      return {
        ...d,
        resources: d.resources.map((r) => {
          return r.id === resourceId
            ? {
                ...r,
                state: resourceState, // updated state
              }
            : r;
        }),
      };
    });

    dispatch(
      deploymentActions.setAllDeployments({
        deployments: updatedDeployments,
      })
    );

    next(action);
  },
};

export default middleware;
