import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditMembersRolesMutationVariables = Types.Exact<{
  editMemberRoles: Types.EditMemberRolesInput;
}>;


export type EditMembersRolesMutation = { __typename?: 'Mutation', editMemberRoles?: { __typename?: 'EditMemberRolesResponse', updated?: Array<{ __typename?: 'UpdateRes', assignedAt: string, tenant?: { __typename?: 'Tenant', id: string } | null, project?: { __typename?: 'Project', id: string } | null, role: { __typename?: 'Role', id: string } } | null> | null, deleted?: Array<{ __typename?: 'DeletedRes', assignedAt: string, tenant?: { __typename?: 'Tenant', id: string } | null, project?: { __typename?: 'Project', id: string } | null, role: { __typename?: 'Role', id: string } } | null> | null, failedToCreateUser?: Array<{ __typename?: 'FailedToCreateRes', effect: string, memberId: string } | null> | null } | null };


export const EditMembersRolesDocument = gql`
    mutation EditMembersRoles($editMemberRoles: EditMemberRolesInput!) {
  editMemberRoles(editMemberRoles: $editMemberRoles) {
    updated {
      assignedAt
      tenant {
        id
      }
      project {
        id
      }
      role {
        id
      }
    }
    deleted {
      assignedAt
      tenant {
        id
      }
      project {
        id
      }
      role {
        id
      }
    }
    failedToCreateUser {
      effect
      memberId
    }
  }
}
    `;

export function useEditMembersRolesMutation() {
  return Urql.useMutation<EditMembersRolesMutation, EditMembersRolesMutationVariables>(EditMembersRolesDocument);
};