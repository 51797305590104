import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { alpha, styled as styledMaterial } from '@mui/material/styles';
import MuiPhoneNumber from 'material-ui-phone-number';

interface Props {
  name: string;
  control: any;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const Label = styled<any>('div')`
  margin-bottom: 4px;

  > span {
    color ${({ error }) => (error ? '#db2341' : 'inherit')}
    font-size: 0.9rem;
    font-weight: 500;
  }
`;

const ErrorMessage = styled<any>('div')`
  margin-top: 8px;

  > span {
    color: ${({ error }) => (error ? '#db2341' : 'inherit')};
    font-size: 0.9rem;
  }
`;

const PhoneField = ({
  name,
  control,
  error,
  errorMessage,
  label,
  required = true,
  fullWidth = true,
  disabled = false,
  placeholder,
}: Props) => {
  return (
    <>
      {label && (
        <Label error={error}>
          <span>{label}</span>
        </Label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiPhoneNumber
            // @ts-ignore
            {...field}
            id={name}
            error={error}
            required={required}
            fullWidth={fullWidth}
            disabled={disabled}
            placeholder={placeholder}
            defaultCountry="gb"
            // @ts-ignore
            variant="outlined"
          />
        )}
      />
      {errorMessage && (
        <ErrorMessage error={error}>
          <span>{errorMessage}</span>
        </ErrorMessage>
      )}
    </>
  );
};

export default PhoneField;
