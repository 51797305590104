import { loader } from 'graphql.macro';

/**
 *
 * Cache bust: 82975458
 */

export const listInvitations = loader('./List.gql');
export const sendInvitations = loader('./Invite.gql');
export const resendInvitation = loader('./Resend.gql');
export const revokeInvitation = loader('./Revoke.gql');
