import styled from 'styled-components';
import Form from './Form';
import TextField from './TextField';
import Select from './Select';
import Checkbox from './Checkbox';
import DateTimePicker from './DateTimePicker';
import PhoneField from './PhoneField';

const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
`;

const FormSubHeader = styled.h4`
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  Form,
  FormFields,
  FormActions,
  FormSubHeader,
  TextField,
  Select,
  Checkbox,
  DateTimePicker,
  PhoneField,
};
