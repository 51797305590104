import Types from './Tenant.type';

export const setAllTenants = ({ tenants }: { tenants: Array<any> }) => {
  return {
    type: Types.SET_ALL_TENANTS,
    payload: { tenants },
  };
};

export const selectTenant = ({ tenantId }: { tenantId: string }) => {
  return {
    type: Types.SELECT_TENANT,
    payload: { tenantId },
  };
};
