import { Project } from 'graphql/types.generated';
import Type from 'Controller/Project/Project.type';

export interface ProjectState {
  projects: Array<Project>;
  project: Project;
  selectedProjectId: string;
}

const initalState: ProjectState = {
  projects: [],
  project: null,
  selectedProjectId: null,
};

type Action = {
  type: Type;
  payload: {
    projects: Array<Project>;
    project: Project;
  };
};

export default function ProjectReducer(
  state: ProjectState = initalState,
  action: Action
): ProjectState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_PROJECTS: {
      const { projects } = payload;
      return { ...state, projects };
    }

    case Type.SET_PROJECT: {
      const { project } = payload;
      return { ...state, project };
    }

    case Type.SELECT_PROJECT: {
      const { project } = payload;
      return { ...state, project, selectedProjectId: project.id };
    }

    default:
      return state;
  }
}
