import { PATH } from 'Views/Project';

export type NavItem = {
  id: string;
  title: string;
  icon: string;
  url: string;
};

const projectSettingsNavigationConfig: NavItem[] = [
  {
    id: 'schedule',
    title: 'Schedule',
    icon: 'access_time',
    url: PATH.PROJECT_SCHEDULE,
  },
  {
    id: 'team',
    title: 'Team',
    icon: 'supervisor_account',
    url: PATH.TEAM,
  },
  {
    id: 'invite',
    title: 'Invite',
    icon: 'schedule_send',
    url: PATH.INVITE,
  },
];

export { projectSettingsNavigationConfig };
