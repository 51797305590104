import { Provider as UrqlProvider } from 'urql';
import {
  ThemeProvider,
  createGenerateClassName,
  jssPreset,
  StylesProvider,
} from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@mui/material/CssBaseline';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import DateFnsUtils from '@date-io/date-fns';
import { Toaster } from 'react-hot-toast';
import { client } from './graphql/client';
import theme from './styles/theme';
import Pages from './Views';
import 'styles/global.css';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  // @ts-ignore
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

const history = createBrowserHistory();

function App() {
  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <UrqlProvider value={client}>
        <Router history={history}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={theme}>
              <StyledComponentsThemeProvider theme={theme}>
                <CssBaseline />
                <Pages />
                <Toaster position="bottom-right" gutter={16} />
              </StyledComponentsThemeProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Router>
      </UrqlProvider>
    </StylesProvider>
  );
}

export default App;
