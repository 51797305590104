import { useState } from 'react';
import { Input, Button, Select, MenuItem, Stack } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { InvitationRequest } from 'graphql/types.generated';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CsvToJson from 'csvtojson';
import * as Style from '../styles';

const BLANK_INVITATION_SCHEMA = {
  email: '',
  roleId: 4,
};

export const ROLES = {
  ADMIN: 2,
  OPS: 3,
  USER: 4,
};

const OnboardingIvite = ({
  handleSend,
}: {
  handleSend: (invitations: Array<InvitationRequest>) => any;
}) => {
  const [invitations, setInvitations] = useState<Array<InvitationRequest>>([
    { ...BLANK_INVITATION_SCHEMA },
  ]);

  /**
   *
   * Invitation form handlers
   */

  const addInvitationElement = () => {
    setInvitations([...invitations, { ...BLANK_INVITATION_SCHEMA }]);
  };

  const removeInvitationElement = (indexToRemove: number) => {
    const updated = [
      ...invitations.slice(0, indexToRemove),
      ...invitations.slice(indexToRemove + 1),
    ];

    setInvitations(updated);
  };

  const updateInvitationEmail = ({
    email,
    index,
  }: {
    email: string;
    index: number;
  }) => {
    const updated = [...invitations];
    updated[index].email = email;
    setInvitations(updated);
  };

  const updateInvitationRole = ({
    roleId,
    index,
  }: {
    roleId: number;
    index: number;
  }) => {
    setInvitations((existing) => {
      const copy = [...existing];
      copy[index].roleId = roleId;
      return copy;
    });
  };

  const handleCsvUpload = ({ target }) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const csvText = e.target?.result?.toString() || '';
      const csvArray = await CsvToJson().fromString(csvText);

      const invitationsWithRoleAsId = csvArray.map((invite) => {
        const { email, role } = invite;
        return {
          email,
          roleId: ROLES[role.toUpperCase()],
        };
      });

      setInvitations(invitationsWithRoleAsId);
    };

    reader.readAsText(target.files[0]);
    return true;
  };

  return (
    <Style.SendInvitations>
      <h2>Invite</h2>
      <Stack direction="row" spacing={3} mt={2} mb={2}>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          startIcon={<PersonAddAlt1OutlinedIcon />}
          onClick={addInvitationElement}
          sx={{ width: 1 / 4 }}
        >
          Add Invitee
        </Button>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="csv-upload"
          onChange={handleCsvUpload}
          type="file"
        />
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          startIcon={<UploadFileIcon />}
          sx={{ width: 1 / 4 }}
        >
          <label htmlFor="csv-upload">Upload CSV</label>
        </Button>
      </Stack>

      {invitations.map((inv, index) => {
        const { email, roleId } = inv;
        return (
          <Stack
            direction="row"
            spacing={3}
            m={1}
            key={`invitation-input-${roleId}`}
          >
            <Input
              onChange={(e) =>
                updateInvitationEmail({ email: e.target.value, index })
              }
              defaultValue={email}
              placeholder="Email"
              value={email}
              type="email"
              sx={{ width: 0.6 }}
            />
            <Select
              variant="standard"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={roleId}
              onChange={(event) => {
                updateInvitationRole({
                  roleId: parseInt(`${event.target.value}`, 10),
                  index,
                });
              }}
              label="Age"
              sx={{ width: 0.2 }}
            >
              {Object.entries(ROLES).map((r, idx) => {
                const [key, value] = r;
                return (
                  <MenuItem key={`role-${idx}-${key}`} value={value}>
                    {key}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              color="secondary"
              variant="text"
              endIcon={<DeleteOutlineIcon />}
              onClick={() => removeInvitationElement(index)}
              sx={{ width: 0.2 }}
            >
              Delete
            </Button>
          </Stack>
        );
      })}

      <Stack direction="row" spacing={3} mt={2} mb={2}>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          startIcon={<SendOutlinedIcon />}
          disabled={
            invitations.length === 0 || invitations.some((i) => !i.email)
          }
          onClick={() => handleSend(invitations)}
          sx={{ width: 1 / 3 }}
        >
          Send invitations
        </Button>
      </Stack>
    </Style.SendInvitations>
  );
};

export default OnboardingIvite;
