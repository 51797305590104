import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';

export const ProjectBarHeader = styled<any>('header')`
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 20;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: #44525a !important;
  color: #fff !important;
  height: 64px;
  position: fixed;
  top: 64px;
`;

export const ProjectToolBar = styled<any>(Toolbar)`
  padding: 0;
`;

export const ProjectToolBarLeft = styled.div`
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  padding-right: 1.6rem;
`;

export const ProjectToolBarRight = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ProjectLogo = styled.img`
  height: 48px;
  width: 34px;
  background: #77858d;
  border: 1px solid #66757e;
  border-radius: 2px;
`;

export const ProjectSubNavigationHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  top: 124px;
  background: #fff;
  height: 56px;
  z-index: 24;
  border-bottom: 1px solid #dde0e2;
`;

export const ProjectSubNavigationNav = styled.ul`
  list-style: none;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0 0 0 0rem;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
`;

export const ProjectSubNavigationNavItem = styled.li`
  cursor: ${({ disabled }: { active: boolean; disabled?: boolean }) =>
    disabled ? 'not-allowed' : 'cursor'};

  opacity: ${({ disabled }: { active: boolean; disabled?: boolean }) =>
    disabled ? '0.5' : '1'};

  font-weight: ${({ active }: { active: boolean; disabled?: boolean }) =>
    active ? '1px solid black' : 'transparent'};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  &:after {
    transform: ${({ active }: { active: boolean; disabled?: boolean }) =>
      active
        ? 'translateX(-50%) rotateY(0deg)'
        : 'translateX(-50%) rotateY(90deg)'};
    content: '';
    width: 100px;
    height: 2px;
    background: #2b3338;
    display: block;
    position: absolute;
    bottom: -1px;
    left: 50%;
    transition: transform 0.15s ease;
  }

  &:hover {
    &:after {
      transition: transform 0.25s ease;
      transform: translateX(-50%) rotateY(0deg);
    }
  }

  a {
    text-decoration: none;
    padding: 6px 8px 6px 8px;
    background: 'transparent';
    display: flex;
  }

  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
`;
