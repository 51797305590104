import { useState } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import { Controller } from 'react-hook-form';

import styled from 'styled-components';

interface Props {
  id?: string;
  name: string;
  control: any;
  error?: boolean;
  errorMessage?: string;
  label: string;
  minDateTime?: Date;
  disabled?: boolean;
  fullWidth?: boolean;
}

const ErrorMessage = styled<any>('div')`
  margin-top: 8px;

  > span {
    color: ${({ error }) => (error ? '#db2341' : 'inherit')};
    font-size: 0.9rem;
  }
`;

export default function BasicDateTimePicker({
  id,
  name,
  control,
  error,
  errorMessage,
  label,
  minDateTime = new Date(),
  disabled = false,
  fullWidth = true,
}: Props) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }: any) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              id={id}
              renderInput={(props: any) => <TextField {...props} />}
              label={label}
              minDateTime={minDateTime}
              disabled={disabled}
              fullWidth={fullWidth}
              {...field}
            />
          </LocalizationProvider>
        )}
      />
      {errorMessage && (
        <ErrorMessage error={error}>
          <span>{errorMessage}</span>
        </ErrorMessage>
      )}
    </>
  );
}
