import { Box, Alert, AlertTitle } from '@mui/material';

export const PendingDeployment = ({ count }: { count: number }) => {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Alert
        sx={{ width: 'calc(100vw - 3rem)', margin: '1rem 0' }}
        variant="outlined"
        severity="success"
      >
        <AlertTitle>This may take a few minutes</AlertTitle>
        {`Please wait whilst we work to deploy ${count} resource(s) to your project. Once
        complete they will show here.`}
      </Alert>
    </Box>
  );
};
