import { useState } from 'react';
import toast from 'react-hot-toast';
import Box from '@material-ui/core/Box';
import { Schedule } from 'graphql/types.generated';
import { useUpdateProjectScheduleMutation } from 'graphql/updateProjectSchedule.generated';
import ProjectScheduleMatrix from 'components/v2/Schedule/project';
import { States } from 'components/v2/Schedule/types';
import Loading from 'components/Common/Loading';

const ProjectSchedule = ({
  projectId,
  schedule,
}: {
  projectId: string;
  schedule?: Schedule;
}) => {
  const [projectScheduleMatrix, setProjectScheduleMatrix] = useState<States>(
    schedule?.states
  );

  const [projectUtcOffset, setProjectUtcOffset] = useState<{
    utcOffset: number;
    timezone: string;
  }>({ utcOffset: schedule.utcOffset || 0, timezone: schedule.timezone });

  const [isNetworkActive, setIsNetworkActive] = useState(false);

  const [{ fetching }, updateProjectSchedule] =
    useUpdateProjectScheduleMutation();

  const handleSave = async () => {
    setIsNetworkActive(true);
    const { data, error } = await updateProjectSchedule({
      projectId: Number(projectId),
      states: projectScheduleMatrix,
      utcOffset: projectUtcOffset.utcOffset,
      timezone: projectUtcOffset.timezone,
    });

    if (error) {
      setIsNetworkActive(false);
      return toast.error('Could not save changes. Please try again', {
        duration: 4000,
        position: 'top-center',
      });
    }

    if (data?.updateProjectSchedule) {
      toast.success('Schedule saved', {
        duration: 4000,
        position: 'top-center',
      });
      setProjectScheduleMatrix(data.updateProjectSchedule.states);
      setIsNetworkActive(false);
    }
  };

  return (
    <Box mt={2}>
      {isNetworkActive && <Loading message="Saving the project schedule" />}
      <ProjectScheduleMatrix
        handleScheduleChange={setProjectScheduleMatrix}
        handleSave={handleSave}
        handleUtcOffsetChange={setProjectUtcOffset}
        utcOffset={projectUtcOffset.utcOffset}
        projectState={projectScheduleMatrix}
        showSaveButton
        timezone={schedule.timezone}
      />
    </Box>
  );
};

export default ProjectSchedule;
