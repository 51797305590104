import styled from 'styled-components';

export const ProvisionerV2Container = styled.div`
  display: flex;
  align-items: flex-start;
  position: fixed;
  background: transparent;
  border-left: 1px solid #b4b4b49e;
  right: 0;
  z-index: 11;
  width: 100vw;
  top: 0;
  height: 100vh;
  transform: translateX(100%);

  h4 {
    margin: 0;
    padding: 0;
  }

  hr {
    margin: 2rem 0;
  }

  &.--open {
    transform: translateX(0%);
    background: #0000006f;
    /* transition: background 0.1s ease-in; */

    > div.Provisioner__SideBar {
      transform: translateX(0);

      > * {
        opacity: 1;
      }
    }

    > div.Provisioner__OpenTab {
      display: none;
    }
  }
`;

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  width: 762px;
  background: white;
  padding: 2rem;
  right: 0;
  max-width: 50%;
  top: 0;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
  overflow-y: scroll;

  > * {
    transition: opacity 0.25s ease-in 0.25s;
    opacity: 0;
    width: 100%;
  }
`;

export const OpenTab = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  left: -50px;
  bottom: 120px;
  background: #2b3338;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;

  > svg {
    fill: white;
  }
`;

export const InProgressContainer = styled.div`
  padding: 2rem 0;

  > div {
    margin: 0.5rem 0;
  }
`;

export const TitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 1rem 0;
`;

export const ProvisionDeployedRow = styled.div`
  display: flex;
  justify-content: 'space-between';
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid lightgray;
  padding: 1rem 0;

  > div {
    &:first-child {
      width: 85%;
    }
    &:last-child {
      width: 15%;
    }
  }
`;

export const DataCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  /* 
  small {
    position: relative;
    top: 3px;
    opacity: 0.6;
  } */

  p {
    &:first-of-type {
      width: 15%;
      opacity: 0.6;
    }
    line-height: 1.4;
    padding: 0;
    margin: 0;
  }
`;

export const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
`;

export const ProvisionProductSelector = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0 0 0;

  > div {
    &:nth-of-type(2) {
      margin: 0.5rem 0 0;
      background: white;
      border-radius: 0;
    }
    &:nth-of-type(3) {
      margin: 1rem 0 0.5rem;
      background: white;
    }
  }

  button {
    margin: 0.5rem 0;
    max-width: 100px;
  }
`;

export const Boxtitle = styled.div`
  display: flex;

  h4 {
    margin: 0;
    padding: 0;
  }

  h4.count {
    margin-left: 1rem;
    opacity: 0.6;
  }
`;
