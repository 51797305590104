import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from '@mui/material';
import ProjectPermissionable from 'components/Common/Permissions/ProjectPermissionable';
import TenantPermissionable from 'components/Common/Permissions/TenantPermissionable';
import { TargetType } from 'graphql/types.generated';
import { useSelector } from 'react-redux';
import { iState } from 'Model';
import Role from './components/Role';
import RemoveUser from './components/RemoveUser';
import PERMISSIONS from '../../../utils/permissions';

interface Props {
  users: any;
  targetType: TargetType;
  targetId: string;
  removable: boolean;
  handleRefreshData?: () => void;
}

function UserManagementTable({
  users,
  targetType,
  targetId,
  removable = true,
  handleRefreshData,
}: Props) {
  const thisSessionMemberId = useSelector((store: iState) => {
    return store.member.thisSessionMember.id;
  });

  if (!users) {
    return <span>No users have been added.</span>;
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {users?.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="user-management-table">
            <TableHead sx={{ borderBottom: '1px solid rgba(224,224,224,1)' }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                {removable && PERMISSIONS.project.members.remove && (
                  <TableCell>Remove</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: any) => {
                const heyThisIsMe = thisSessionMemberId === user.member.id;

                return (
                  <TableRow
                    key={user?.member?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {user?.member?.givenName} {user?.member?.familyName}
                    </TableCell>
                    <TableCell>{user?.member?.email}</TableCell>
                    <TableCell>
                      {targetType === TargetType.Tenant && user?.role && (
                        <TenantPermissionable
                          tenantId={targetId}
                          minSeniority={PERMISSIONS.tenant.members.update}
                          fallbackComponent={
                            <Typography>{user?.role.name}</Typography>
                          }
                        >
                          <Role
                            disabled={heyThisIsMe}
                            role={user.role}
                            targetType={targetType}
                            targetId={targetId}
                            memberId={user?.member?.id}
                          />
                        </TenantPermissionable>
                      )}

                      {targetType === TargetType.Project && user?.role && (
                        <ProjectPermissionable
                          minSeniority={PERMISSIONS.project.members.update}
                          fallbackComponent={
                            <Typography>{user?.role.name}</Typography>
                          }
                        >
                          <Role
                            disabled={heyThisIsMe}
                            role={user.role}
                            targetType={targetType}
                            targetId={targetId}
                            memberId={user?.member?.id}
                          />
                        </ProjectPermissionable>
                      )}
                    </TableCell>
                    <TableCell>
                      {removable &&
                        !heyThisIsMe &&
                        targetType === TargetType.Tenant &&
                        user?.role && (
                          <TenantPermissionable
                            tenantId={targetId}
                            minSeniority={PERMISSIONS.tenant.members.remove}
                          >
                            <RemoveUser
                              targetType={targetType}
                              targetId={targetId}
                              memberId={user?.member?.id}
                              roleId={user?.role?.id}
                              onDelete={handleRefreshData}
                            />
                          </TenantPermissionable>
                        )}

                      {removable &&
                        !heyThisIsMe &&
                        targetType === TargetType.Project &&
                        user?.role && (
                          <ProjectPermissionable
                            minSeniority={PERMISSIONS.project.members.remove}
                          >
                            <RemoveUser
                              targetType={targetType}
                              targetId={targetId}
                              memberId={user?.member?.id}
                              roleId={user?.role?.id}
                              onDelete={handleRefreshData}
                            />
                          </ProjectPermissionable>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>
          <span>No users added yet</span>
        </div>
      )}
    </Box>
  );
}

export default UserManagementTable;
