import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';

export const TenantBarHeader = styled<any>('header')`
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 20;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: #2b3338 !important;
  color: #fff !important;
  height: 64px;
  position: sticky;
  top: 0;
`;

export const TenantToolBar = styled<any>(Toolbar)`
  padding: 0;
`;

export const TenantToolBarLeft = styled.div`
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  padding-right: 1.6rem;
`;

export const TenantToolBarRight = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  overflow-x: auto;
  height: 100%;
`;

export const Logo = styled.img`
  height: 40px;
`;

export const TenantLogo = styled.img`
  height: 40px;
  width: 40px;
  background: #77858d;
  border-radius: 2px;
`;
