import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 1.5rem 1.5rem;
`;

export const MainContent = styled.div`
  min-width: 500px;
  margin: 0 auto;
  margin-bottom: 164px;
`;
