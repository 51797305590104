import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ListRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListRolesQuery = { __typename?: 'Query', listRoles?: Array<{ __typename?: 'Role', id: string, name: string, seniority: number } | null> | null };


export const ListRolesDocument = gql`
    query ListRoles {
  listRoles {
    id
    name
    seniority
  }
}
    `;

export function useListRolesQuery(options?: Omit<Urql.UseQueryArgs<ListRolesQueryVariables>, 'query'>) {
  return Urql.useQuery<ListRolesQuery, ListRolesQueryVariables>({ query: ListRolesDocument, ...options });
};