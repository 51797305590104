import { useRedux } from 'Hook';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'react-redux';
import { useMeQueryQuery } from 'graphql/getMe.generated';
import Loading from 'components/Common/Loading';
import { memberActions } from 'Controller/actions';
import { PATH as WORKSPACE_PATH } from 'Views/Workspace';
import { PATH as PROJECT_PATH } from 'Views/Project';
import TenantHeader from 'components/Tenant/TenantHeader';
import ProjectHeader from 'components/Project/ProjectHeader';
import UserMenu from 'components/User/UserMenu';
import styled from 'styled-components';
import {
  Wrapper,
  Container,
  ContainerTenant,
  MainTenant,
  Main,
  Content,
} from './Layout.shared';


export const HeaderWrapper = styled<any>('header')`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <UserMenu />
    </HeaderWrapper>
  );
};

const AppLayout = ({ children }: { children: React.ReactChild }) => {
  const { dispatch } = useStore();
  const location = useLocation();

  const {
    store: {
      member: { thisSessionMember },
    },
  } = useRedux();

  const [{ data, fetching, error }] = useMeQueryQuery();

  useEffect(() => {
    if (data?.getMe && !thisSessionMember) {
      const member = data?.getMe;
      dispatch(memberActions.setThisSessionMember({ member }));
    }
  }, [data, error]);

  if (error) {
    return (
      <Wrapper>
        <Container>
          <span>
            The app failed to load. Please refresh or contact support.
          </span>
        </Container>
      </Wrapper>
    );
  }

  if (fetching) {
    return (
      <Wrapper>
        <Container>
          <Loading message="Initialising Tyrell Cloud" />
        </Container>
      </Wrapper>
    );
  }

  const isTenantRoute = location.pathname === WORKSPACE_PATH.DASHBOARD;

  const isValidProjectRoute = Object.values(PROJECT_PATH).includes(
    location.pathname
  );

  if (isTenantRoute) {
    return (
      <Wrapper>
        <Container>
          <Header />
          <Main>{children}</Main>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ContainerTenant>
        <MainTenant>
          <TenantHeader />
          {isValidProjectRoute && <ProjectHeader />}
          <Content isValidProjectRoute={isValidProjectRoute}>
            {children}
          </Content>
        </MainTenant>
      </ContainerTenant>
    </Wrapper>
  );
};

export default AppLayout;
