import { useSelector } from 'react-redux';
import { iState } from 'Model';
import { Typography } from '@mui/material';
import Table from '../components/Table';

const TransferTable = () => {
  const { transferDeployments, project } = useSelector((store: iState) => {
    return {
      project: store.project.project,
      transferDeployments: store.deployment.deployments.filter((d) => {
        return store.deployment.transfer.includes(d.id);
      }),
    };
  });

  return transferDeployments ? (
    <Table
      projectId={project.id}
      deployments={transferDeployments}
      connectable={false}
      expandable
      schedulable
    />
  ) : (
    <Typography>No FileCatalyst Direct products exist</Typography>
  );
};

export default TransferTable;
