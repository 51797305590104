import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { iState } from 'Model';
import Onboarding from 'components/v2/Onboarding';
import { Container } from '../Settings.styled';

function ProjectInvite() {
  const { project } = useSelector((store: iState) => {
    return store.project;
  });

  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Project Invitations</h2>
      </Grid>
      <Onboarding />
    </Container>
  );
}

export default ProjectInvite;
