import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  flex-direction: column;
`;

export const MainContent = styled.div`
  margin-right: 196px;
  margin-left: 196px;
  margin-bottom: 164px;
`;
