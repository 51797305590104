import { TargetType } from 'graphql/types.generated';
import { useRedux } from 'Hook';
import UserManagementTable from 'components/User/UserManagementTable';

function ProjectUsersTable() {
  const {
    dispatch,
    actions: {
      projectActions: { refetchCurrentProject },
    },
    store: {
      project: {
        project: { id, projectMemberRoles },
      },
    },
  } = useRedux();

  return (
    <>
      {projectMemberRoles && (
        <UserManagementTable
          targetType={TargetType.Project}
          targetId={id}
          users={projectMemberRoles}
          removable
          handleRefreshData={() => dispatch(refetchCurrentProject())}
        />
      )}
    </>
  );
}

export default ProjectUsersTable;
