import Types, { InvitationRequest } from './Onboarding.type';

export const invite = ({
  projectId,
  invitations,
}: {
  projectId: number;
  invitations: Array<InvitationRequest>;
}) => {
  return {
    type: Types.INVITE,
    payload: { projectId, invitations },
  };
};

export const list = ({ projectId }: { projectId: number }) => {
  return {
    type: Types.LIST,
    payload: { projectId },
  };
};

export const resend = ({
  projectId,
  inviteId,
}: {
  projectId: number;
  inviteId: string;
}) => {
  return {
    type: Types.RESEND,
    payload: { projectId, inviteId },
  };
};

export const revoke = ({
  projectId,
  inviteId,
}: {
  projectId: number;
  inviteId: string;
}) => {
  return {
    type: Types.REVOKE,
    payload: { inviteId, projectId },
  };
};

export const setSendStatus = ({
  sendStatus,
}: {
  sendStatus: boolean | null;
}) => {
  return {
    type: Types.SET_SEND_STATUS,
    payload: { sendStatus },
  };
};
