import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateResourceScheduleMutationVariables = Types.Exact<{
  resourceId: Types.Scalars['String'];
  states: Types.StatesInput;
}>;


export type UpdateResourceScheduleMutation = { __typename?: 'Mutation', updateResourceSchedule?: { __typename?: 'Schedule', states: { __typename?: 'ScheduleStates', monday: Array<number | null>, tuesday: Array<number | null>, wednesday: Array<number | null>, thursday: Array<number | null>, friday: Array<number | null>, saturday: Array<number | null>, sunday: Array<number | null> } } | null };


export const UpdateResourceScheduleDocument = gql`
    mutation UpdateResourceSchedule($resourceId: String!, $states: StatesInput!) {
  updateResourceSchedule(resourceId: $resourceId, states: $states) {
    states {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
}
    `;

export function useUpdateResourceScheduleMutation() {
  return Urql.useMutation<UpdateResourceScheduleMutation, UpdateResourceScheduleMutationVariables>(UpdateResourceScheduleDocument);
};