import styled from 'styled-components';

export const ConnectorStatusAndHealth = styled.div``;

export const HelpLinks = styled.div`
  cursor: pointer;

  > p {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    &:hover {
      color: blue;
    }
  }

  svg {
    margin-right: 1rem;
  }
`;

export const DataPoint = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0;

  > p:first-of-type {
    opacity: 0.7;
    letter-spacing: 0.8px;
    font-size: 0.6rem;
    text-transform: uppercase;
  }

  > p:last-of-type {
  }
`;
