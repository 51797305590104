import {
  createStore,
  compose,
  combineReducers,
  applyMiddleware,
  Dispatch,
} from 'redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';

// reducers
import DeploymentReducer, {
  DeploymentState,
} from 'Controller/Deployment/Deployment.reducer';
import ProjectReducer, {
  ProjectState,
} from 'Controller/Project/Project.reducer';
import MemberReducer, { MemberState } from 'Controller/Member/Member.reducer';
import TenantReducer, { TenantState } from 'Controller/Tenant/Tenant.reducer';
import ProductReducer, {
  ProductState,
} from 'Controller/Product/Product.reducer';
import NotificationReducer, {
  NotificationState,
} from 'Controller/Notification/Notification.reducer';
import ProvisionReducer, {
  ProvisionState,
} from 'Controller/Provision/Provision.reducer';
import OnboardingReducer, {
  OnboardingState,
} from 'Controller/Onboarding/Onboarding.reducer';

// middleware
import ControllerMiddleware from 'Controller/middleware';

export interface iState {
  deployment: DeploymentState;
  project: ProjectState;
  member: MemberState;
  tenant: TenantState;
  product: ProductState;
  provision: ProvisionState;
  notification: NotificationState;
  onboarding: OnboardingState;
  dispatch: Dispatch;
}

export const history = createBrowserHistory();

const createRootReducer = () =>
  combineReducers({
    deployment: DeploymentReducer,
    project: ProjectReducer,
    member: MemberReducer,
    tenant: TenantReducer,
    product: ProductReducer,
    provision: ProvisionReducer,
    notification: NotificationReducer,
    onboarding: OnboardingReducer,
  });

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
  collapsed: true,
});

const allMiddleware =
  process.env.REACT_APP_TYRELL_ENV !== 'prod'
    ? [ControllerMiddleware, logger]
    : [ControllerMiddleware];

const store = createStore(
  createRootReducer(),
  composeEnhancer(applyMiddleware(...allMiddleware))
);

export default store;
