import { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import WorkspaceRouter, { PATH as WORKSPACE_PATHS } from 'Views/Workspace';
import ProjectRouter from 'Views/Project';
import TenantRouter from 'Views/Tenant';

const handleFrontDoorRedirect = () => {
  const exclusionUrls = [...Object.values(WORKSPACE_PATHS)].map((url) =>
    String(url)
  );

  const basePath = '/';
  const { origin, pathname } = window.location;
  if (pathname !== basePath && !exclusionUrls.includes(pathname)) {
    window.location.replace(`${origin}${basePath}`);
  }
};

const Pages = () => {
  useEffect(() => {
    handleFrontDoorRedirect();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/tenant" component={TenantRouter} />
        <Route path="/project" component={ProjectRouter} />
        <Route exact path="/" component={WorkspaceRouter} />
      </Switch>
    </BrowserRouter>
  );
};

export default Pages;
