import * as Types from './types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ListProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListProductsQuery = { __typename?: 'Query', listProducts?: Array<{ __typename?: 'Product', category: Types.ProductCategory, description: string, id: string, meta?: string | null, name: string, publishedAt?: string | null, items?: Array<{ __typename?: 'ProductItem', quantity: number, productItem: { __typename?: 'Item', category: Types.ItemCategory, configurationId: string, description: string, id: string, meta?: string | null, name: string } } | null> | null, vendor: { __typename?: 'Vendor', id: string, name: string }, service: { __typename?: 'Service', description: string, id: string, name: string } } | null> | null };


export const ListProductsDocument = gql`
    query ListProducts {
  listProducts {
    category
    description
    id
    meta
    name
    publishedAt
    items {
      quantity
      productItem {
        category
        configurationId
        description
        id
        meta
        name
      }
    }
    vendor {
      id
      name
    }
    service {
      description
      id
      name
    }
  }
}
    `;

export function useListProductsQuery(options?: Omit<Urql.UseQueryArgs<ListProductsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListProductsQuery, ListProductsQueryVariables>({ query: ListProductsDocument, ...options });
};