import { Deployment } from 'graphql/types.generated';
import DeploymentTypes from './Deployment.type';

export const setAllDeployments = ({
  deployments,
}: {
  deployments: Array<Deployment>;
}) => {
  return {
    type: DeploymentTypes.SET_ALL_DEPLOYMENTS,
    payload: { deployments },
  };
};

export const selectDeployment = ({
  deploymentId,
}: {
  deploymentId: string;
}) => {
  return {
    type: DeploymentTypes.SELECT_DEPLOYMENT,
    payload: { deploymentId },
  };
};

export const updateResourceState = ({
  resourceId,
  state,
}: {
  resourceId: string;
  state: string;
}) => {
  return {
    type: DeploymentTypes.UPDATE_RESOURCE_STATE,
    payload: { resourceId, state },
  };
};

export const addPendingDeployment = ({
  productId,
  productCategory,
  clientRequestId,
}: {
  productId: string;
  productCategory: string;
  clientRequestId: string;
}) => {
  return {
    type: DeploymentTypes.ADD_PENDING,
    payload: {
      productId,
      productCategory,
      clientRequestId,
    },
  };
};

export const removePendingDeployment = ({
  clientRequestId,
}: {
  clientRequestId: string;
}) => {
  return {
    type: DeploymentTypes.REMOVE_PENDING,
    payload: {
      clientRequestId,
    },
  };
};
