import { useState, useEffect } from 'react';

import { getImageOrFallback } from 'utils/helpers';

type Props = {
  imageUrl: string;
  fallback: string;
  styles: any;
};

const Image = ({ imageUrl, fallback, styles }: Props) => {
  const [renderableImageUrl, setRenderableImageUrl] = useState(fallback);

  useEffect(() => {
    if (imageUrl) {
      const checkImage = async () => {
        const image = await getImageOrFallback(imageUrl, fallback);

        if (image) {
          setRenderableImageUrl(image);
        }
      };

      checkImage();
    }
  }, [imageUrl]);

  return renderableImageUrl ? (
    <img src={renderableImageUrl} alt="logo" style={styles} />
  ) : (
    <div
      style={{
        background: '#eee',
        ...styles,
      }}
    />
  );
};

export default Image;
