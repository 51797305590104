import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
`;

export const ContainerTenant = styled.main`
  display: flex;
  flex: 1 1 auto;
  min-width: 0px;
`;

export const MainTenant = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100vh;
  position: relative;
  min-width: 0px;
  z-index: 10;
`;

export const Content = styled<any>('div')`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0px;
  position: relative;
  z-index: 10;
  margin-top: ${(props) => (props.isValidProjectRoute ? '96px' : 'none')};
`;
