import { Member } from 'graphql/types.generated';
import Type from 'Controller/Member/Member.type';

export interface MemberState {
  thisSessionMember: Member | null;
}

const initalState: MemberState = {
  thisSessionMember: null,
};

type Action = {
  type: Type;
  payload: {
    member: Member;
  };
};

export default function MemberReducer(
  state: MemberState = initalState,
  action: Action
): MemberState {
  const { type = '', payload } = action;

  switch (type) {
    case Type.SET_THIS_SESSION_MEMBER: {
      const { member } = payload;
      return { ...state, thisSessionMember: member };
    }

    default:
      return state;
  }
}
