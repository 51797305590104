import React, { Fragment } from 'react';
import { useRedux } from 'Hook';
import { useLocation, useParams, Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ProjectSwitch from 'components/Project/ProjectSwitch';
import ProjectPermissionable from 'components/Common/Permissions/ProjectPermissionable';
import ProjectSettingsUserMenu from 'components/Project/ProjectSettingsUserMenu';
import PERMISSIONS, { ROLE_SENIORITIES } from 'utils/permissions';
import {
  ProjectBarHeader,
  ProjectToolBar,
  ProjectToolBarLeft,
  ProjectToolBarRight,
  ProjectSubNavigationHeader,
  ProjectSubNavigationNav,
  ProjectSubNavigationNavItem,
} from './ProjectHeader.styled';
import getConfig from './config';

const BETA_TESTER_ACCOUNT_IDS = [
  '387987855682',
  '266663356365',
  '651213602540',
];

const ProjectHeader = () => {
  const location = useLocation();
  const params = useParams();

  const { projectId } = params;

  const {
    store: {
      tenant: { userSelected, tenants },
    },
  } = useRedux();

  const thisTenant = tenants.find((t) => String(t.id) === String(userSelected));

  const betaFeaturesEnabled = BETA_TESTER_ACCOUNT_IDS.includes(
    thisTenant.awsAccountId
  );

  const projectNavigationConfig = getConfig(betaFeaturesEnabled);

  return (
    <>
      <ProjectBarHeader id="Project-header" color="default">
        <ProjectToolBar>
          <ProjectToolBarLeft>
            <ProjectSwitch tenantId={thisTenant.id} />
          </ProjectToolBarLeft>

          <ProjectToolBarRight>
            <ProjectPermissionable
              minSeniority={PERMISSIONS.project.settings.update}
            >
              <ProjectSettingsUserMenu projectId={projectId} />
            </ProjectPermissionable>
          </ProjectToolBarRight>
        </ProjectToolBar>
      </ProjectBarHeader>
      {projectNavigationConfig && (
        <ProjectSubNavigationHeader>
          <ProjectSubNavigationNav>
            {projectNavigationConfig.map(
              ({ id, title, url, disabled, preview, outlink }) => {
                return (
                  <ProjectPermissionable
                    key={id}
                    minSeniority={ROLE_SENIORITIES.ALL_USERS}
                  >
                    <ProjectSubNavigationNavItem
                      active={location.pathname.includes(url)}
                      disabled={disabled}
                    >
                      {outlink ? (
                        <>
                          <a href={outlink} target="_blank" rel="noreferrer">
                            {title}
                          </a>
                          <OpenInNewIcon
                            fontSize="small"
                            sx={{ marginLeft: '0.3rem' }}
                          />
                        </>
                      ) : (
                        <Link to={disabled ? '#' : url}>{title}</Link>
                      )}
                      {preview && (
                        <Chip
                          label="Coming Soon"
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </ProjectSubNavigationNavItem>
                  </ProjectPermissionable>
                );
              }
            )}
          </ProjectSubNavigationNav>
        </ProjectSubNavigationHeader>
      )}
    </>
  );
};

export default ProjectHeader;
