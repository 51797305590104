import {
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { Deployment, ItemCategory } from 'graphql/types.generated';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Chip,
  Collapse,
  IconButton,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditableLabel from 'react-inline-editing';
import stableSort from 'utils/stable-sort';
import { useRenameResourceMutation } from 'graphql/renameResource.generated';
import StartResourceModal from 'components/Common/Modals/StartResource';
import StopResourceModal from 'components/Common/Modals/StopResource';
import WorkstationConnectModal from 'components/Common/Modals/WorkstationConnect';
import EnhancedTableHead from '../TableHead';
import ContextMenu from '../ContextMenu';
import ScheduleLink from '../Schedule';
import {
  Order,
  DeploymentSession,
  DeploymentRow,
  STATUS_COLOUR,
  createDeploymentRows,
  getItemCategoryFromDeployments,
  getComparator,
  EmptyState,
} from '../../helpers';

const Row = ({
  projectId,
  labelId,
  handleClick,
  handleFocusOut,
  row,
  expandable,
  schedulable,
  connectable,
  stopModalOpen,
  startModalOpen,
  connectModalOpen,
}: {
  projectId: string;
  labelId: string;
  handleClick: (deployment: any) => void;
  handleFocusOut: (rowId: string, text: string) => void;
  row: any;
  expandable: boolean;
  schedulable: boolean;
  connectable: boolean;
  stopModalOpen: Dispatch<SetStateAction<boolean>>;
  startModalOpen: Dispatch<SetStateAction<boolean>>;
  connectModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);

  const computeResources = row.deployment.resources.filter(
    (r) => r.productItem.category.toLowerCase() === 'compute'
  );

  const firstWorkstation = computeResources[0];
  const workstationName = firstWorkstation.name || row.name;
  const resourceId = firstWorkstation.id;

  /**
   * Session attempts
   */

  const hasSessionAttempt = !!row?.deployment?.session?.sessionAttemptedOn;
  let attemptTime = '';
  let attemptee = '';

  if (hasSessionAttempt) {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    const lastSessionAttempted = new Date(
      row?.deployment?.session?.sessionAttemptedOn
    );
    const lastSessionAttemptDay = days[lastSessionAttempted.getDay()];
    const lastSessionAttemptHour = lastSessionAttempted.getHours();
    const lastSessionAttemptMinute = lastSessionAttempted.getMinutes();

    attemptTime = `${lastSessionAttemptDay.toUpperCase()} ${lastSessionAttemptHour}:${lastSessionAttemptMinute}`;
    attemptee = `${row?.deployment?.session?.user?.givenName.charAt(0)} ${
      row?.deployment?.session?.user?.familyName
    }`;
  }

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        {expandable && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell align="left">
          <EditableLabel
            text={workstationName}
            inputWidth="100px"
            inputHeight="32px"
            inputMaxLength={100}
            labelFontWeight="regular"
            inputFontWeight="regular"
            onFocusOut={(text) => handleFocusOut(resourceId, text)}
          />
        </TableCell>
        <TableCell align="left">{row.description}</TableCell>
        <TableCell align="left">
          <Chip
            label={row.status}
            color={STATUS_COLOUR[row.status] || 'error'}
            size="small"
          />
        </TableCell>
        {schedulable && (
          <TableCell align="left">
            <ScheduleLink projectId={projectId} deployment={row.deployment} />
          </TableCell>
        )}
        {connectable &&
          (hasSessionAttempt ? (
            <TableCell align="left">
              {`${attemptee} - ${attemptTime}`}
            </TableCell>
          ) : (
            <TableCell align="left">-</TableCell>
          ))}
        <TableCell align="left">
          <ContextMenu
            projectId={projectId}
            deployment={row.deployment}
            schedulable={schedulable}
            connectable={connectable}
            handleSelected={(deployment) => handleClick(deployment)}
            stopModalOpen={stopModalOpen}
            startModalOpen={startModalOpen}
            connectModalOpen={connectModalOpen}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label={row.deployment?.id}>
                <TableHead>
                  <TableRow>
                    <TableCell>Resource Name</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>State</TableCell>
                  </TableRow>
                </TableHead>
                {row.deployment.resources.map((resource) => (
                  <TableBody key={resource?.id}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {resource?.productItem?.name}
                      </TableCell>
                      <TableCell>{resource?.productItem?.category}</TableCell>
                      <TableCell>{resource?.state}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const WorkstationsTable = ({
  projectId,
  deployments,
  expandable,
  connectable,
  schedulable,
}: {
  projectId: string;
  deployments: Deployment[];
  expandable: boolean;
  connectable: boolean;
  schedulable: boolean;
}) => {
  const [, renameResource] = useRenameResourceMutation();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('status');
  const [selected, setSelected] = useState(null);
  const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [rows, setRows] = useState<DeploymentRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<DeploymentRow[]>([]);
  const [startModalOpen, setStartModalOpen] = useState<boolean>(false);
  const [stopModalOpen, setStopModalOpen] = useState<boolean>(false);
  const [selectedComputeResourceId, setSelectedComputeResourceId] =
    useState(null);

  useEffect(() => {
    if (deployments) {
      setRows(createDeploymentRows(deployments));
    }

    if (selected) {
      const selectedComputeResource = selected?.resources.find(
        (r) => r?.productItem?.category.toUpperCase() === ItemCategory.Compute
      );
      setSelectedComputeResourceId(selectedComputeResource?.id);
    }
  }, [deployments, selected]);

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFocusOut = async (resourceId: string, name: string) => {
    await renameResource({
      resourceId,
      name,
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const renderableRows = filteredRows.length ? filteredRows : rows;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="projectWorkstations"
              size="medium"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                expandable={expandable}
                schedulable={schedulable}
                connectable={connectable}
              />
              <TableBody>
                {stableSort(renderableRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const labelId = `workstations-table-checkbox-${row.id}`;

                    return (
                      <Row
                        key={row.id}
                        projectId={projectId}
                        labelId={labelId}
                        handleClick={setSelected}
                        handleFocusOut={handleFocusOut}
                        row={row}
                        expandable={expandable}
                        schedulable={schedulable}
                        connectable={connectable}
                        stopModalOpen={setStopModalOpen}
                        startModalOpen={setStartModalOpen}
                        connectModalOpen={setConnectModalOpen}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[8, 16, 24]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {rows.length === 0 && (
            <EmptyState>No deployments have been added yet</EmptyState>
          )}
        </>
      </Paper>

      <StartResourceModal
        open={startModalOpen}
        setOpen={setStartModalOpen}
        resourceId={selectedComputeResourceId}
      />

      <StopResourceModal
        open={stopModalOpen}
        setOpen={setStopModalOpen}
        resourceId={selectedComputeResourceId}
      />

      <WorkstationConnectModal
        open={connectModalOpen}
        setOpen={setConnectModalOpen}
      />
    </Box>
  );
};

export default WorkstationsTable;
