import styled from 'styled-components';
import Button from '@mui/material/Button';

export const ContextButton = styled.div`
  height: 100%;
  border: none;
  color: white;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  overflow: hidden; /* Hide scrollbars */
  transform: rotate(0deg);
  transition: transform 0.1s ease-out;

  &:hover {
    transition: transform 0.25s ease-in;
    transform: rotate(90deg);
  }
`;
