import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export const ContextButton = styled(Button)`
  height: 100%;
  border: 1px solid white;

  &:hover: {
    background: white !important;
    border: 1px solid white !important;
  }
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.4rem;
  color: inherit;
`;

export const UserDisplayName = styled(Typography)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem !important;
`;
